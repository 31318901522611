<template>
  <front-page-card title="Local Beer Festivals"
                   more-text="More festivals…"
                   :more-link="moreLink"
                   card="local-festivals"
                   :larger="larger"
  >
    <div
      v-if="loading"
      class="loading"
    >
      <div>
        <b-skeleton />
      </div>
      <div>
        <b-skeleton />
      </div>
    </div>
    <template v-else-if="festivals.length">
      <p
        class="is-hidden-mobile is-block-tablet has-text-centered"
        :class="{ 'has-others': otherFestivals }"
        data-testid="local-festivals-info"
      >
        <safe-link
          class="festival-name"
          :to="firstFestival.url"
        >
          {{ firstFestival.name }}
        </safe-link>
        <br>
        <date-time-range
          :starts-at="firstFestival.startsAt"
          :ends-at="firstFestival.endsAt"
          class="festival-dates"
        />
        <template v-if="otherFestivals">
          <br>
          <span class="others">…and {{ otherFestivals }} {{ pluralize('other', otherFestivals) }} in the next two months.</span>
        </template>
      </p>
      <p class="is-block-mobile is-hidden-tablet">
        Next: {{ firstFestival.name }}, <date-time-range :starts-at="firstFestival.startsAt" :ends-at="firstFestival.endsAt" />
        <template v-if="otherFestivals">
          <br>
          <span class="others">…and {{ otherFestivals }} {{ pluralize('other', otherFestivals) }} in the next two months.</span>
        </template>
      </p>
    </template>
    <p
      v-else
      data-testid="local-festivals-info"
    >
      No upcoming festivals.
    </p>
  </front-page-card>
</template>

<script>
  import pluralize from 'pluralize'

  import FrontPageCard from '@/components/front-page/front-page-card'
  import SafeLink from '@/components/helpers/safe-link'
  import DateTimeRange from '@/components/helpers/date-time-range'

  export default {
    name: 'local-festivals-card',

    components: {
      FrontPageCard,
      SafeLink,
      DateTimeRange,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      festivals: {
        type: Array,
        default: () => [],
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      moreLink () {
        return this.festivals.length ? '/beer-festivals' : null
      },

      firstFestival () {
        return this.festivals[0] ?? {}
      },

      otherFestivals () {
        return Math.max(0, this.festivals.length - 1)
      },
    },

    methods: {
      pluralize,
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .loading {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include not-on-phone {
      align-items: center;
    }

    > div {
      &:first-child {
        width: 60%;
      }

      &:nth-child(2) {
        width: 40%;
      }
    }
  }

  .festival-name {
    color: $body-color;
    font-weight: $weight-bold;
  }

  a {
    &.festival-name {
      &:hover {
        text-decoration: underline;
        color: $hover-color;
      }
    }
  }

  .has-others {
    // fix optical balance on larger devices when "others" line is displayed
    margin-top: 0.75rem;
  }

  .others {
    color: #687373;

    @include on-phone {
      font-size: 0.8rem;
    }

    @include not-on-phone {
      font-size: 0.7rem;
    }
  }
</style>
