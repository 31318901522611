<template>
  <loading-indicator :loading="loading">
    <template v-if="readOnly && !userCanSeePage">
      <not-found />
    </template>

    <template v-else>
      <!-- eslint-disable vue/no-v-html -->
      <section id="page-title">
        <div
          v-if="readOnly"
          class="title-left"
        >
          <b-tag
            v-if="userLoggedIn && page.published"
            size="is-small"
            type="is-success"
            data-testid="page-title-published-indicator"
          >
            Published
          </b-tag>
          <b-tag
            v-if="userLoggedIn && !page.published"
            size="is-small"
            type="is-light"
            data-testid="page-title-published-indicator"
          >
            Unpublished
          </b-tag>
        </div>
        <h2>{{ pageHeading }}</h2>
        <div
          v-if="readOnly"
          class="title-right"
        >
          <b-button
            v-if="userCanSeeList"
            size="is-small"
            outlined
            data-testid="page-title-all-pages-button"
            @click="allPages"
          >
            All Pages
          </b-button>
          <b-button
            v-if="userCanEditPage"
            type="is-primary"
            size="is-small"
            outlined
            data-testid="page-title-edit-button"
            @click="edit(page.id)"
          >
            Edit
          </b-button>
        </div>
      </section>

      <section class="content-body">
        <access-denied v-if="!accessPermitted" />

        <template v-else-if="pageLoaded && readOnly">
          <div v-html="page.content" />

          <div
            v-if="ticketSalesLive"
            class="buy-button"
          >
            <buy-tickets-button
              :url="page.ticketSalesUrl"
              data-testid="buy-tickets"
            />
          </div>
        </template>

        <template v-else-if="pageLoaded && !readOnly">
          <form
            accept-charset="UTF-8"
            data-testid="page-form"
            @keydown.prevent.enter=""
          >
            <input name="utf8" type="hidden" value="✓">

            <b-field
              label="Title"
              :type="fieldTitleType"
              :message="fieldTitleMessages"
            >
              <b-input
                v-model.trim="$v.page.title.$model"
                data-testid="page-form-title"
                @keyup.native.enter.once="saveForm"
              />
            </b-field>

            <b-field
              label="Slug"
              :type="fieldSlugType"
              :message="fieldSlugMessages"
            >
              <b-input
                v-model.trim="$v.page.slug.$model"
                data-testid="page-form-slug"
                @keyup.native.enter.once="saveForm"
              />
            </b-field>
            <p class="help">
              Leave this blank to automatically generate a value (usually the best option).<br>
              <strong>Warning:</strong> once a page has been published, changing its slug can cause broken links!
            </p>

            <b-field
              label="Content"
              :type="fieldContentType"
              :message="fieldContentMessages"
            >
              <div>
                <b-input
                  v-model.trim="$v.page.content.$model"
                  type="textarea"
                  data-testid="page-form-content"
                  @keyup.native.enter.prevent="typeEnterInTextarea($v.page.content, $event)"
                />
              </div>
            </b-field>

            <div class="actions columns">
              <div class="column is-narrow-tablet">
                <b-switch
                  v-model="page.published"
                  type="is-success"
                  data-testid="page-form-published"
                >
                  Published
                </b-switch>
              </div>

              <div class="action-buttons column">
                <template v-if="readOnly">
                  <b-button
                    outlined
                    @click="allPages"
                  >
                    All Pages
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    outlined
                    @click="confirmDataLoss"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    type="is-primary"
                    :disabled="saving || $v.$invalid"
                    :loading="saving"
                    @click="saveForm"
                  >
                    {{ saveButtonText }}
                  </b-button>
                </template>
              </div>
            </div>
          </form>
        </template>

        <template v-else>
          <section class="section no-page">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large" />
              </p>
              <p>This page could not be loaded.</p>
            </div>
          </section>
        </template>
      </section>
    </template>
  </loading-indicator>
</template>

<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap' // eslint-disable-line no-unused-vars
  import {
    Heading,
    Bold,
    Italic,
    Strike,
    Link,
    Blockquote,
    OrderedList,
    BulletList,
    ListItem,
    HardBreak,
    HorizontalRule,
    History,
  } from 'tiptap-extensions'
  import { Subscript } from 'tiptap-extension-subscript'

  import { isEqual } from 'lodash'
  import { required, helpers } from 'vuelidate/lib/validators'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { setTitle } from '@/router'
  import { parseDateTimes, parseTicketSalesDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast, successToast } from '@/helpers/notification-helper'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'
  import NotFound from '@/views/not-found'
  import BuyTicketsButton from '@/components/helpers/buy-tickets-button.vue'

  const alphaNumHyphen = helpers.regex('alphaNum', /^[-0-9a-z]+$/)
  const disallowedSlugs = (value) => !([
    'new',
    'edit',
    'index',
    'delete',
    'session',
    'login',
    'logout',
    'users',
    'admin',
    'stylesheets',
    'assets',
    'javascripts',
    'images',
  ].includes(value))

  export default {
    name: 'page',

    components: {
      // EditorMenuBar,
      // EditorContent,
      LoadingIndicator,
      AccessDenied,
      NotFound,
      BuyTicketsButton,
    },

    async beforeRouteUpdate (_to, _from, next) {
      this.loading = true
      this.page = {}

      await this.loadPage(this.id)
      next()
    },

    props: {
      id: {
        type: String,
        default: undefined,
      },

      mode: {
        type: String,
        default: 'show',
        validator (value) {
          return ['create', 'edit', 'show'].indexOf(value) !== -1
        },
      },
    },

    validations: {
      page: {
        title: {
          required,
        },

        slug: {
          disallowedSlugs,
          alphaNumHyphen,
          async slugTaken (slug) {
            let taken

            if (slug === '') return true
            if (slug === this.originalPage.slug) return true
            if (!this.$v?.page?.slug) return true
            if (!this.$v.page.slug.alphaNumHyphen || !this.$v.page.slug.disallowedSlugs) return true
            try {
              ({ taken } = await ApiService.get(
                this.page.id
                  ? `pages/slug_taken/${ slug }/${ this.page.id }`
                  : `pages/slug_taken/${ slug }`))
            } catch (error) {
              console.error(error)
              taken = true
            }

            // validations return true for a pass, false for a fail
            return !taken
          },
        },

        content: {
          required,
          // nonBlankHTML (_html) {
          //   return this.editor.state.doc.textContent !== ''
          // },
        },
      },
    },

    data () {
      return {
        loading: true,
        saving: false,
        accessPermitted: true,
        page: {},
        originalPage: {},
        pageHeading: '',
        editor: new Editor(this.editorOptions),

        /* eslint-disable vue/no-unused-properties */
        editorOptions: {
          content: '',
          extensions: [
            new Heading({
              levels: [3, 4, 5],
            }),
            new Bold(),
            new Italic(),
            new Strike(),
            new Subscript(),
            new Link({ openOnClick: false }),
            new Blockquote(),
            new OrderedList(),
            new BulletList(),
            new ListItem(),
            new HardBreak(),
            new HorizontalRule(),
            new History(),
          ],

          onUpdate: ({ getHTML }) => {
            this.page.content = getHTML()
            this.$v.page.content.$touch()
            if (this.$v.page.content.$invalid) {
              this.$refs.contentEditor.classList.add('is-danger')
            } else {
              this.$refs.contentEditor.classList.remove('is-danger')
            }
          },
        },
        /* eslint-enable vue/no-unused-properties */
      }
    },

    computed: {
      pageLoaded () {
        return this.page && (this.mode === 'create' || this.page.id)
      },

      ticketSalesLive () {
        const now = new Date()

        return this.page.ticketSalesUrl && this.page.ticketSalesStartsAt <= now && this.page.ticketSalesEndsAt >= now
      },

      userLoggedIn () {
        return !this.$store.getters['user/isAnonymous']
      },

      userCanSeePage () {
        return this.page.published || this.userLoggedIn
      },

      userCanSeeList () {
        return this.accessPermitted && this.$store.getters['user/canView']
      },

      userCanEditPage () {
        return this.$store.getters['user/canContribute']
      },

      readOnly () {
        return this.mode === 'show'
      },

      saveButtonText () {
        return this.mode === 'create' ? 'Create' : 'Save'
      },

      pageChanged () {
        return !isEqual(this.page, this.originalPage)
      },

      fieldTitleType () {
        if (this.readOnly) return ''

        return this.$v.page.title.$error ? 'is-danger' : ''
      },

      fieldTitleMessages () {
        if (this.readOnly || !this.$v.page.title.$error) return ''

        return this.$v.page.title.required ? '' : 'The title is required.'
      },

      fieldSlugType () {
        if (this.readOnly) return ''

        return this.$v.page.slug.$error ? 'is-danger' : ''
      },

      fieldSlugTaken () {
        return !this.$v.page.slug.$pending && this.$v.page.slug.slugTaken
      },

      fieldSlugMessages () {
        if (this.readOnly || !this.$v.page.slug.$error) return ''

        const messages = []

        if (!this.$v.page.slug.disallowedSlugs) {
          messages.push('This word cannot be used as a slug.')
        }
        if (!this.$v.page.slug.alphaNumHyphen) {
          messages.push('The slug can only contain lower-case letters, numbers, and hyphens.')
        }
        if (!this.fieldSlugTaken) {
          messages.push('This slug is being used for another page. Please choose another.')
        }

        return messages
      },

      fieldContentType () {
        if (this.readOnly || !this.$v.page.content.$error) return ''

        return this.$v.page.content.$error ? 'is-danger' : ''
      },

      fieldContentMessages () {
        if (this.readOnly || !this.$v.page.content.$error) return ''

        return this.$v.page.content.required ? '' : 'The content is required.'
        // return this.$v.page.content.nonBlankHTML ? '' : 'The content is required.'
      },
    },

    async mounted () {
      await this.loadPage(this.id)

      EventBus.$on('userDidChange', () => {
        (async () => { await this.loadPage(this.id) })()
      })
    },

    beforeDestroy () {
      this.editor.destroy()
    },

    methods: {
      async loadPage (id) {
        this.loading = true
        this.page = {}
        this.accessPermitted = true

        if (this.mode === 'create') {
          this.page = await this.loadBlankPage()
          this.pageHeading = 'New Page'
        } else {
          if (this.mode === 'edit' && !this.$store.getters['user/can']('edit', 'pages')) {
            this.accessPermitted = false
          } else {
            const prefix = this.mode === 'edit' ? 'Edit Page: ' : ''

            this.page = await this.loadExistingPage(id)
            this.pageHeading = this.pageLoaded ? `${ prefix }${ this.page.title }` : `${ prefix }Page`
          }
        }
        this.originalPage = { ...this.page }

        if (!this.accessPermitted) {
          this.pageHeading = ''
        }
        setTitle(this.pageHeading)

        this.editor.destroy()
        this.editor = new Editor(this.editorOptions)
        this.editor.setContent(this.page.content)
        this.editor.setOptions({
          editable: !this.readOnly,
        })

        this.loading = false
      },

      async loadBlankPage () {
        try {
          const { page } = await ApiService.get('pages/new')

          return page
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      async loadExistingPage (id) {
        try {
          const { page } = await ApiService.get(`pages/${ id }`)

          return parseTicketSalesDateTimes(parseDateTimes(page))
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else if (error?.response?.status === 422) {
            this.page = {}
          } else if (error?.response?.status !== 404) {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      confirmDataLoss () {
        if (this.pageChanged) {
          this.$buefy.dialog.confirm({
            title: 'Cancel Editing',
            message: 'Are you sure you want to cancel? Your changes will be lost.',
            cancelText: 'No',
            confirmText: 'Yes',
            type: 'is-danger',
            hasIcon: true,
            icon: 'alert',
            onConfirm: () => this.allPages(),
          })
        } else {
          this.allPages()
        }
      },

      edit (id) {
        this.$router.push(`/pages/${ id }/edit`)
      },

      allPages () {
        this.$router.push('/pages')
      },

      typeEnterInTextarea (validator, event) {
        const field = event.target
        const newCaretPosition = field.selectionStart + 1
        const contentBefore = field.value.substring(0, field.selectionStart)
        const contentAfter = field.value.substring(field.selectionEnd)

        validator.$model = `${ contentBefore }\n${ contentAfter }`
        this.$nextTick()
          .then(() => {
            field.focus()
            field.setSelectionRange(newCaretPosition, newCaretPosition)
          })
      },

      async saveForm () {
        this.$v.$touch()
        if (this.mode === 'show' || this.saving || this.$v.$invalid) return

        try {
          const page = { page: this.page }

          this.saving = true
          switch (this.mode) {
            case 'create':
              await ApiService.post('pages', page)
              break
            case 'edit':
              await ApiService.put(`pages/${ this.id }`, page)
              break
            default:
              console.error(`saveForm called with unsupported mode ${ this.mode }`)

              return
          }

          successToast('Page saved')

          await this.$router.push('/pages')
        } catch (error) {
          let formattedErrors

          switch (error?.response?.status) {
            case 422:
              formattedErrors = error?.response?.data?.errors?.join('</li><li>')

              this.$buefy.dialog.alert({
                title: 'Could not save page',
                message: `The server reported the following errors: <ul class="rails-errors"><li>${ formattedErrors }</li></ul>`,
                type: 'is-danger',
                hasIcon: true,
                icon: 'emoticon-sad',
              })
              break
            case 401:
              failureToast('You do not have permission to do this')
              break
            default:
              console.error(error)
              failureToast('There was a problem communicating with the server; please try later')
              break
          }
        } finally {
          this.saving = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  form {
    margin: 0 auto;
    max-width: $form-width;
  }

  .html-editor {
    label {
      margin-bottom: 0.5rem;
    }

    .menubar {
      line-height: 2;

      .spacer {
        display: inline-block;
        width: 0.5rem;
      }

      button {
        margin-right: 0.1rem;
        border: 1px solid $grey;
        border-radius: $radius;

        &.is-active {
          background-color: $grey-light;
        }
      }
    }

    ::v-deep {
      .ProseMirror {
        border: 1px solid $light;
        border-radius: $radius;
        box-shadow: inset 0 0.0625rem 0.125rem rgba(10, 10, 10, 0.05);
        background-color: #fff;
        padding: 0.5rem 0.75rem;
        color: $dark;

        &:focus {
          outline: none;
          border-color: $primary;
          box-shadow: 0 0 0 0.125rem change-color($primary, $alpha: 0.25);
        }

        &[contenteditable='false'] {
          border-color: #f5f5f5;
          box-shadow: none;
          background-color: #f5f5f5;
          cursor: not-allowed;
          color: #7a7a7a;
        }

        ul {
          list-style-type: circle;
        }
      }
    }

    &.is-danger {
      ::v-deep {
        .ProseMirror {
          border-color: $danger;
        }
      }
    }
  }

  #page-title {
    .title-left,
    .title-right {
      @include not-on-phone {
        position: absolute;
        top: 0.5rem;
      }
    }

    .title-left {
      left: 0;
    }

    .title-right {
      right: 0;

      button {
        margin-left: 0.5rem;
        height: 1.8rem;

        @include on-print {
          display: none;
        }
      }
    }
  }

  .help {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .actions {
    align-items: center;

    .action-buttons {
      text-align: right;

      .button {
        @include not-on-phone {
          &:not(:first-child) {
            margin-left: 0.75rem;
          }
        }

        @include on-phone {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .content-body {
    .buy-button {
      display: flex;
      justify-content: center;
      margin-top: 1.25rem;
    }
  }
</style>

<style lang="scss">
  // Buefy dialogs can't be styled from the calling component, as they are created on the <body>, so this rule must be global
  // Can be replaced by a ::v-global block after moving to Vue 3
  .dialog {
    ul {
      &.rails-errors {
        list-style-type: circle;

        li {
          padding-left: 0;
        }
      }
    }
  }
</style>
