<template>
  <b-tag
    type="is-dark"
    ellipsis
    :style="{ color: foregroundColour, backgroundColor: colour }"
  >
    <slot />
  </b-tag>
</template>

<script>
  import { color } from '@snigos/color'

  export default {
    name: 'event-label',

    props: {
      colour: {
        type: String,
        default: '#913668',
      },
    },

    computed: {
      foregroundColour () {
        const colour = color(this.colour)

        return colour.mode ? '#fff' : '#000'
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .tag {
    font-weight: bold;
  }
</style>
