<template>
  <loading-indicator :loading="loading">
    <not-found />
  </loading-indicator>
</template>

<script>
  import ApiService from '@/services/api-service'

  import LoadingIndicator from '@/components/helpers/loading-indicator'

  import NotFound from '@/views/not-found'

  export default {
    name: 'dynamic-redirect',

    components: {
      LoadingIndicator,
      NotFound,
    },

    props: {
      from: {
        type: String,
        required: true,
      },
    },

    data () {
      return {
        loading: true,
        paths: {},
      }
    },

    async mounted () {
      await this.loadPageUrls()

      switch (this.from) {
        case '/about-the-branch':
        case '/contact-us':
          this.redirectIfSet('contactPath')
          break
        case '/locale':
          this.redirectIfSet('localePath')
          break
        case '/trading-standards':
          this.redirectIfSet('linksPath')
          break
        case '/yapton-beerex':
          this.redirectIfSet('yaptonBeerexPath')
          break
      } // no default clause; fallthrough is to display this view's "not found" component
    },

    methods: {
      async loadPageUrls () {
        let pageUrls

        this.loading = true

        try {
          ({ pageUrls = {} } = await ApiService.get('site/page_urls'))
        } catch (error) {
          pageUrls = {}
          console.error(error)
        } finally {
          this.paths = pageUrls
          this.loading = false
        }
      },

      redirectIfSet (setting) {
        if (this.paths[setting]) {
          this.$router.push(this.paths[setting])
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
