<template>
  <div class="is-divider" :data-content="label" />
</template>

<script>
  export default {
    name: 'divider-with-text',

    props: {
      label: {
        type: String,
        default: undefined,
      },
    },
  }
</script>

<style lang="scss" scoped>
  // Taken from https://github.com/Wikiki/bulma-divider without the support for vertical dividers
  @import '@/assets/bulma-variables.scss';
  $divider-line-colour: $grey-lighter;
  $divider-thickness: 1px; // stylelint-disable-line unit-allowed-list -- this is a border width, for which px is fine
  $divider-font-size: $size-7;
  $divider-colour: $grey-dark;

  .is-divider {
    display: block;
    position: relative;
    border-top: $divider-thickness solid $divider-line-colour;
    height: $divider-thickness;
    margin: 2rem 0;
    text-align: center;

    &[data-content] {
      &::after {
        display: inline-block;
        transform: translateY(-1rem);
        background-color: $body-background-color;
        padding: 0.4rem 0.8rem;
        text-align: center;
        color: $divider-colour;
        font-size: $divider-font-size;
        content: attr(data-content);
      }
    }
  }
</style>
