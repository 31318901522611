<template>
  <div>
    <section id="page-title">
      <h2>{{ publication.name }}</h2>
    </section>
    <section class="content-body">
      <loading-indicator :loading="loading">
        <template v-if="userCanSeeList">
          <div v-if="totalItems > 0" class="issues">
            <div class="columns is-multiline">
              <div
                v-for="issue in issues"
                :key="issue.id"
                class="column is-one-third-desktop is-half-tablet"
              >
                <div class="card">
                  <div class="card-content">
                    <header v-snip="1">
                      {{ issue.name }}
                    </header>
                    <safe-link
                      :to="issue.pdfUrl"
                      @visited="trackDownload(issue)"
                    >
                      <figure class="image is-issue-thumbnail">
                        <img :src="issue.imgSrc" :srcset="issue.imgSrcset" :alt="issue.name">
                      </figure>
                    </safe-link>
                    <p v-snip="4" class="card-text">
                      {{ issue.description }}
                    </p>
                  </div>
                  <footer v-if="userCanDeleteIssue || userCanEditIssue" class="card-footer">
                    <span
                      v-if="userCanDeleteIssue"
                      class="card-footer-item action-destroy"
                      role="button"
                      @click="destroy(issue)"
                    >
                      Delete
                    </span>
                    <span
                      v-if="userCanEditIssue"
                      class="card-footer-item action-edit"
                      role="button"
                      @click="edit(issue)"
                    >
                      Edit
                    </span>
                  </footer>
                </div>
              </div>
            </div>
            <div class="columns">
              <div
                v-if="userCanCreateIssue"
                class="column is-narrow"
              >
                <b-button
                  type="is-primary"
                  outlined
                  data-testid="create-button"
                  @click="create"
                >
                  New Issue
                </b-button>
              </div>
              <div class="column">
                <b-pagination
                  :current="paginatorPage"
                  :total="totalItems"
                  :per-page="paginatorPerPage"
                  range-before="2"
                  range-after="2"
                  order="is-centered"
                  aria-next-label="Older issues"
                  aria-previous-label="Newer issues"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  data-testid="issues-paginator"
                  @change="paginatorPageDidChange"
                />
              </div>
            </div>
          </div>
          <section v-else class="section no-issues">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large" />
              </p>
              <p>No issues.</p>
            </div>
            <div v-if="userCanCreateIssue">
              <b-button
                type="is-primary"
                outlined
                data-testid="create-button"
                @click="create"
              >
                New Issue
              </b-button>
            </div>
          </section>
        </template>
        <access-denied v-else />
      </loading-indicator>
    </section>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import { failureToast, successToast } from '@/helpers/notification-helper'

  import AccessDenied from '@/components/helpers/access-denied'
  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import { parseISO } from 'date-fns'
  import SafeLink from '@/components/helpers/safe-link'
  import { setTitle } from '@/router'

  export default {
    name: 'issues',

    components: {
      SafeLink,
      AccessDenied,
      LoadingIndicator,
    },

    props: {
      publicationId: {
        type: String,
        default: undefined,
      },
    },

    data () {
      return {
        publication: {},
        issues: [],
        loading: true,
        paginatorPage: 1,
        paginatorPerPage: 6,
        totalItems: 0,
      }
    },

    computed: {
      userCanSeeList () {
        return this.$store.getters['user/can']('list', 'issues')
      },

      userCanEditIssue () {
        return this.$store.getters['user/can']('edit', 'issues')
      },

      userCanDeleteIssue () {
        return this.$store.getters['user/can']('delete', 'issues')
      },

      userCanCreateIssue () {
        return this.$store.getters['user/can']('create', 'issues')
      },

      lastPage () {
        return Math.ceil((this.totalItems) / this.paginatorPerPage)
      },
    },

    async mounted () {
      this.loading = true
      if (this.$route.query?.page === undefined) {
        this.paginatorPage = this.$store.getters['pagination/issuesPage'] || 1
      } else {
        const parsedPage = Number.parseInt(this.$route.query.page)

        if (parsedPage < 1  || Number.isNaN(parsedPage)) {
          this.paginatorPage = 1
          await this.$router.replace({ name: 'issues', params: this.$route.params, query: { ...this.$route.query, page: this.paginatorPage } })
        } else {
          this.paginatorPage = parsedPage
        }
      }
      await this.$store.dispatch('pagination/clearIssuesPage')
      await this.loadPublication()
      await this.loadIssues()
      this.loading = false

      // All users can see issues; there's no need to reload on login/logout
    },

    methods: {
      async loadPublication () {
        let publication

        this.accessPermitted = true

        try {
          ({ publication } = await ApiService.get(`publications/${ this.publicationId }`))
        } catch (error) {
          console.error(error)
          publication = {
            name: 'Unknown Publication',
          }

          if (error?.response?.status === 401) {
            this.accessPermitted = false
            failureToast('You do not have permission to do this')
          }
        } finally {
          publication.createdAt = publication.createdAt ? parseISO(publication.createdAt) : null
          publication.updatedAt = publication.updatedAt ? parseISO(publication.updatedAt) : null
          this.publication = publication
          setTitle(`${ publication.name } Issues`)
        }
      },

      async loadIssues () {
        let issues, itemCount

        this.accessPermitted = true
        try {
          for (;;) {
            // sorting isn't supported, so don't pass sort parameters
            ({ issues, itemCount } =
              await ApiService.get(`publications/${ this.publicationId }/issues?p=${ this.paginatorPage }&n=${ this.paginatorPerPage }`))

            this.totalItems = itemCount
            if (this.totalItems === 0 || this.paginatorPage === 1 || this.paginatorPage <= this.lastPage) break

            // page is past the end of the dataset
            this.paginatorPage = this.lastPage
            await this.$router.replace({ name: 'issues', params: this.$route.params, query: { ...this.$route.query, page: this.paginatorPage } })
          }
        } catch (error) {
          issues = []
          this.totalItems = 0

          if (error?.response?.status === 401) {
            this.accessPermitted = false
            failureToast('You do not have permission to do this')
          }
        } finally {
          const missingThumbnail = '/thumbnails/missing'

          this.issues = issues
            .map((row) => {
              const createdAt = row.createdAt ? parseISO(row.createdAt) : null
              const updatedAt = row.updatedAt ? parseISO(row.updatedAt) : null
              const img1x = ApiService.url(`${ row.thumbnails?.large?.x1 ?? missingThumbnail }`)
              const img2x = ApiService.url(`${ row.thumbnails?.large?.x2 ?? missingThumbnail }`)
              const img3x = ApiService.url(`${ row.thumbnails?.large?.x3 ?? missingThumbnail }`)

              return {
                ...row,
                imgSrc: img1x,
                imgSrcset: `${ img2x } 2x, ${ img3x } 3x`,
                createdAt,
                updatedAt,
              }
            })
        }
      },

      async paginatorPageDidChange (page) {
        this.paginatorPage = page
        this.loading = true
        await this.$router.replace({ name: 'issues', params: this.$route.params, query: { ...this.$route.query, page } })
        await this.loadIssues()
        this.loading = false
      },

      destroy (issue) {
        this.$buefy.dialog.confirm({
          title: 'Delete Issue',
          message: `Are you sure you want to delete issue #${ issue.issueNumber }: ${ issue.name }? This cannot be undone.`,
          cancelText: 'Keep',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          icon: 'alert',
          onConfirm: async () => {
            try {
              await ApiService.delete(`publications/${ this.publication.slug }/issues`, issue.id)
              successToast('Issue deleted')
              this.totalItems -= 1
              if (this.paginatorPage > 1 && this.paginatorPage > this.lastPage) {
                await this.paginatorPageDidChange(this.lastPage)
              } else {
                await this.loadIssues()
              }
            } catch (error) {
              const message = error?.response?.status === 401
                ? 'You do not have permission to delete this issue'
                : 'Could not delete issue'

              failureToast(message)
            }
          },
        })
      },

      trackDownload (issue) {
        this.$matomo && this.$matomo.trackEvent('Publication', 'Downloaded', issue.slug)
        this.$store.dispatch('pagination/setIssuesPage', { page: this.paginatorPage })
      },

      edit (issue) {
        this.$store.dispatch('pagination/setIssuesPage', { page: this.paginatorPage })
        this.$router.push(`/publications/${ this.publication.slug }/issues/${ issue.id }/edit`)
      },

      create () {
        this.$store.dispatch('pagination/setIssuesPage', { page: this.paginatorPage })
        this.$router.push(`/publications/${ this.publication.slug }/issues/new`)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  header {
    color: $body-color;
    font-size: $size-5;
    font-weight: $weight-bold;
  }

  .card-content {
    padding: 1rem;
    font-size: 0.9rem;
  }

  .card-text {
    min-height: 5.3rem;
  }

  .card-footer-item {
    cursor: pointer;

    &:hover {
      background-color: $white-ter;
    }

    &.action-destroy {
      color: $danger;
    }

    &.action-edit {
      color: $primary;
    }
  }

  .is-issue-thumbnail {
    display: block;
    margin: 0.75rem auto;
    width: 132px;
    height: 186px;

    img {
      border: 1px solid $grey-dark;
      max-height: 186px;
    }
  }
</style>
