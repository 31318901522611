<template>
  <time :title="title" :datetime="dateOrTime">
    <slot />
  </time>
</template>

<script>
  import { dateFormats } from '@/objects/date-formats'
  import { format as formatDate } from 'date-fns'

  export default {
    name: 'date-time',

    props: {
      datetime: {
        type: Date,
        required: true,
      },

      showTime: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      title () {
        const formatString = this.showTime ? dateFormats.longDateWithTime : dateFormats.longDate

        return formatDate(this.datetime, formatString)
      },

      dateOrTime () {
        // these formats are as per the requirements for the `datetime` attribute of the `<time>` tag, so are not in dateFormats
        const formatString = this.showTime ? 'yyyy-MM-dd\'T\'HH:mm:ss.000xxx' : 'yyyy-MM-dd'

        return formatDate(this.datetime, formatString)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
