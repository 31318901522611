<template>
  <div
    :id="cardID"
    class="card"
    :class="{ larger: larger }"
  >
    <div class="card-content is-flex">
      <div class="columns is-mobile">
        <div class="column">
          <h2>
            <safe-link
              v-if="moreLink"
              :to="moreLink"
            >
              {{ title }}
            </safe-link>
            <span v-else>{{ title }}</span>
          </h2>
        </div>
        <div class="column is-hidden-tablet is-narrow">
          <aside>
            <safe-link
              v-if="moreLink"
              :to="moreLink"
              :aria-label="moreTextScreenreader"
            >
              More <i class="fas fa-caret-right" aria-hidden="true" />
            </safe-link>
          </aside>
        </div>
      </div>
      <div class="card-centre is-flex">
        <slot />
      </div>
      <aside class="is-hidden-mobile is-block-tablet">
        <safe-link
          v-if="moreLink"
          :to="moreLink"
          :aria-label="moreTextScreenreader"
          :data-testid="moreLinkTestID"
        >
          {{ moreText }}
        </safe-link>
      </aside>
    </div>
  </div>
</template>

<script>
  import { kebabCase } from 'lodash'
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'front-page-card',

    components: {
      SafeLink,
    },

    props: {
      card: {
        type: String,
        required: true,
      },

      larger: {
        type: Boolean,
        default: false,
      },

      title: {
        type: String,
        default: '',
      },

      moreText: {
        type: String,
        default: 'More information…',
      },

      moreTextScreenreader: {
        type: String,
        default: null,
      },

      moreLink: {
        type: String,
        default: '',
      },
    },

    computed: {
      cardID () {
        return kebabCase(`card-${ this.card }`)
      },

      moreLinkTestID () {
        return kebabCase(`${ this.card }-more-link`)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  a {
    color: $body-color;

    &:hover {
      text-decoration: underline;
      color: $hover-color;
    }
  }

  .card {
    color: $body-color;

    @include not-on-phone {
      height: 155px;

      &.larger {
        height: 253px;
      }
    }

    @include on-phone {
      border: none;
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      height: auto;
      min-height: 5rem;
      line-height: 1.25;
      font-size: 1.1rem;
    }
  }

  .card-content {
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    height: 100%;

    > .columns {
      margin-bottom: 0;
    }

    @include on-phone {
      padding: 0.5rem;
    }
  }

  h2 {
    font-size: $size-5;

    @include on-phone {
      font-size: 1.1rem;
    }
  }

  .card-centre {
    background: transparent;
    width: 100%;
    line-height: 1.3;

    &:not(.larger) {
      font-size: smaller;
    }

    @include not-on-phone {
      position: absolute;
      top: 2.5rem;
      left: 0;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      height: calc(100% - 5rem);
    }

    @include on-phone {
      align-items: flex-end;
      justify-content: flex-start;
      padding-bottom: 0.5rem;
    }
  }

  aside {
    &.is-block-tablet {
      align-items: flex-end;
      align-self: flex-end;
      font-size: smaller;
    }

    &:not(.is-block-tablet) {
      font-size: 0.9rem;
    }

    a { // stylelint-disable-line no-descending-specificity -- this will flag no matter what order you put the rules in
      color: $body-color;
      font-weight: $weight-bold;
    }
  }
</style>
