<template>
  <v-calendar
    is-expanded
    title-transition="fade"
    weeks-transition="fade"
    :attributes="calendarAttributes"
    data-testid="events-calendar-for-month"
  >
    <template #day-popover="{ dayTitle, attributes }">
      <div class="vc-day-popover-header">
        {{ dayTitle }}
      </div>
      <table class="popover-events">
        <tbody>
          <tr
            v-for="{ key, customData } in attributes"
            :key="key"
          >
            <td>
              <event-label
                :colour="customData.colour"
                class="popover-label"
              >
                {{ customData.label }}
              </event-label>
            </td>
            <td class="popover-times">
              {{ customData.times }}
            </td>
            <td class="popover-summary">
              {{ customData.summary }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </v-calendar>
</template>

<script>
  import { isSameDay, format as formatDate } from 'date-fns'
  import { truncate } from 'lodash'
  import { dateFormats } from '@/objects/date-formats'

  import EventLabel from '@/components/helpers/event-label'

  export default {
    name: 'events-calendar',

    components: {
      EventLabel,
    },

    props: {
      events: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      calendarAttributes () {
        const attributes = []
        const truncateOptions = {
          length: 230,
          separator: ' ',
          omission: '…',
        }

        this.events.forEach((event) => {
          let times

          if (event.allDay) {
            times = 'All day'
          } else if (isSameDay(event.startsAt, event.endsAt)) {
            times = `${ formatDate(event.startsAt, dateFormats.time) } – ${ formatDate(event.endsAt, dateFormats.time) }`
          } else {
            times = `${ formatDate(event.startsAt, dateFormats.popover) } – ${ formatDate(event.endsAt, dateFormats.popover) }`
          }

          attributes.push({
            bar: {
              style: { backgroundColor: event.colour },
            },
            dates: {
              start: event.startsAt,
              end: event.endsAt,
            },
            popover: {
              label: true, // required when using a custom slot
              placement: 'right',
              visibility: 'hover',
            },
            customData: {
              summary: truncate(event.summary, truncateOptions),
              colour: event.colour,
              label: event.label,
              times,
            },
          })
        })

        return attributes
      },
    },
  }
</script>

<style lang="scss" scoped>
  @use 'sass:color';

  @import '@/assets/bulma-variables.scss';

  .vc-day-popover-header {
    font-size: 110%;
    font-weight: $weight-bold;
    margin-bottom: 0.25rem;
  }

  .popover-events {
    td {
      padding-bottom: 0.25rem;

      &:not(:last-child) {
        padding-right: 0.5rem;
      }
    }

    .popover-label {
      padding: 0 0.25rem;
      height: 1.5em;
    }

    .popover-times {
      font-weight: $weight-bold;
    }
  }

  // Hide days outside this month as Buefy won't show event indicators for them; this may
  // mislead people into thinking there is nothing on that day.
  ::v-deep {
    .vc-day {
      .vc-opacity-0 {
        opacity: 0.4;
      }

      &.is-today {
        border: 1px solid color.scale($primary, $lightness: 50%, $saturation: -10%); // stylelint-disable-line unit-allowed-list -- % is OK for manipulations
      }
    }

    // v-calendar embeds it's own uncustomisable version of Tailwind. Cheers for that, it's much
    // more efficient than letting me specify `contentStyle: { color: '#fe6c00' }`…
    .vc-bg-orange-600 {
      background-color: $primary;
    }

    .vc-fg-orange-600 {
      color: $primary;
    }

    &.vc-rounded-lg {
      border-radius: 4px;
    }
  }
</style>
