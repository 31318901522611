<template>
  <div>
    <div v-if="coordinatesMissing" class="no-map">
      no map available
    </div>
    <template v-else>
      <div class="map">
        <l-map ref="map" :zoom="zoom" :center="centre">
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-marker :lat-lng="marker" />
        </l-map>
      </div>
      <div class="map-buttons">
        <safe-link :to="openStreetMapLink" target="_blank">
          <b-button
            outlined
            expanded
            icon-left="map"
          >
            OpenStreetMap
          </b-button>
        </safe-link>
        <safe-link :to="googleMapsLink" target="_blank">
          <b-button
            outlined
            expanded
            icon-left="google-maps"
          >
            Google Maps
          </b-button>
        </safe-link>
        <safe-link :to="appleMapsLink" target="_blank">
          <b-button
            outlined
            expanded
            icon-left="apple"
          >
            Apple Maps
          </b-button>
        </safe-link>
        <safe-link :to="wazeLink" target="_blank">
          <b-button
            outlined
            expanded
            icon-left="waze"
          >
            Waze
          </b-button>
        </safe-link>
      </div>
    </template>
  </div>
</template>

<script>
  import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
  import { Icon, latLng } from 'leaflet'
  import 'leaflet/dist/leaflet.css'

  import SafeLink from '@/components/helpers/safe-link'

  // fix Webpack losing the marker icons
  delete Icon.Default.prototype._getIconUrl
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  })

  export default {
    name: 'draggable-map',

    components: {
      SafeLink,
      LMap,
      LMarker,
      LTileLayer,
    },

    props: {
      latitude: {
        type: Number,
        required: false,
        default: undefined,
        validator (value) {
          return value >= -180 && value <= 180
        },
      },

      longitude: {
        type: Number,
        required: false,
        default: undefined,
        validator (value) {
          return value >= -180 && value <= 180
        },
      },

      address: {
        type: String,
        required: false,
        default: '',
      },
    },

    data () {
      return {
        zoom: 16,
        url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }
    },

    computed: {
      coordinatesMissing () {
        // eslint-disable-next-line eqeqeq
        return (this.latitude == null || this.longitude == null)
      },

      centre () {
        if (this.coordinatesMissing) {
          return null
        }

        return latLng(this.latitude, this.longitude)
      },

      marker () {
        if (this.coordinatesMissing) {
          return null
        }

        return latLng(this.latitude, this.longitude)
      },

      openStreetMapLink () {
        return `https://www.openstreetmap.org/?mlat=${ this.latitude }&mlon=${ this.longitude }#map=17/${ this.latitude }/${ this.longitude }`
      },

      googleMapsLink () {
        return `https://maps.google.com/maps?q=${ encodeURIComponent(this.address) }&z=14`
      },

      appleMapsLink () {
        return `https://maps.apple.com/?sll=${ this.latitude },${ this.longitude }&ll=${ this.latitude },${ this.longitude }&z=14`
      },

      wazeLink () {
        return `https://waze.com/ul?ll=${ this.latitude },${ this.longitude }&z=8`
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .map {
    border: 1px solid $grey-lighter;
    width: 100%;
    height: 400px;
  }

  .no-map {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-lighter;
    padding-top: 50%;
    padding-bottom: 50%;
    width: 100%;
    height: 0;
    color: $grey;

    @include on-phone {
      display: none;
    }
  }

  .map-buttons {
    padding-top: 0.5rem;

    .button {
      margin-top: 0.5rem;
    }

    @include on-print {
      display: none;
    }
  }
</style>
