<template>
  <div>
    <template v-if="loggedIn">
      <b-button
        type="is-danger"
        outlined
        expanded
        icon-left="account-off"
        data-testid="logout-button"
        @click="logout"
      >
        Log out
      </b-button>
      <span data-testid="currently-logged-in-user">Logged in as {{ username }}</span>
    </template>
    <template v-else>
      <b-button
        type="is-primary"
        outlined
        expanded
        icon-left="account"
        data-testid="login-button"
        @click="showModal"
      >
        Admin Login
      </b-button>
    </template>

    <b-modal
      :active.sync="loginModalActive"
      has-modal-card
      can-cancel
      :on-cancel="dismissModal"
      custom-class="login-modal"
      role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <template v-if="showLoginBox">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Log in
            </p>
          </header>
          <section class="modal-card-body">
            <form
              accept-charset="UTF-8"
              @keydown.prevent.enter=""
            >
              <b-field label="Username">
                <b-input
                  ref="username"
                  v-model="formUsername"
                  type="text"
                  placeholder="Username or email"
                  required
                  autocomplete="username"
                  data-testid="login-modal-username"
                  @keyup.native.enter.once="login"
                />
              </b-field>

              <b-field label="Password">
                <b-input
                  v-model="formPassword"
                  type="password"
                  password-reveal
                  required
                  autocomplete="current-password"
                  data-testid="login-modal-password"
                  @keyup.native.enter.once="login"
                />
              </b-field>
            </form>
            <ul class="reset-password">
              <li>
                <a
                  href="#"
                  data-testid="login-modal-reset-password"
                  @click="action = 'resetPassword'"
                >
                  Forgot your password?
                </a>
              </li>
              <li>
                <a
                  href="#"
                  data-testid="login-modal-resend-confirmation"
                  @click="action = 'resendConfirmation'"
                >
                  Resend confirmation
                </a>
              </li>
              <li>
                <a
                  href="#"
                  data-testid="login-modal-resend-unlock"
                  @click="action = 'resendUnlock'"
                >
                  Resend unlock instructions
                </a>
              </li>
            </ul>
          </section>
          <footer class="modal-card-foot">
            <b-button
              type="is-outlined"
              data-testid="login-modal-cancel-button"
              @click="dismissModal"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              :disabled="!(formUsername.length && formPassword.length)"
              :class="{ animate__animated: loginIncorrect, animate__headShake: loginIncorrect }"
              data-testid="login-modal-login-button"
              @click="login"
            >
              Log in
            </b-button>
          </footer>
        </template>
        <template v-else>
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ actionTitle }}
            </p>
          </header>
          <section class="modal-card-body">
            <form
              accept-charset="UTF-8"
              @keydown.prevent.enter=""
            >
              <b-field
                label="Email"
                :type="fieldEmailType"
                :message="fieldEmailMessages"
              >
                <b-input
                  v-model="$v.formEmail.$model"
                  data-testid="account-admin-modal-email"
                  autocomplete="email"
                  inputmode="email"
                  @keyup.native.enter="adminAction"
                />
              </b-field>
            </form>
          </section>
          <footer class="modal-card-foot">
            <b-button
              type="is-outlined"
              data-testid="account-admin-modal-cancel-button"
              @click="dismissModal"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              :disabled="processing || $v.$invalid || !formEmail.length"
              :loading="processing"
              data-testid="account-admin-modal-action-button"
              @click="adminAction"
            >
              {{ actionTitle }}
            </b-button>
          </footer>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { successToast, failureToast, plainToast } from '@/helpers/notification-helper'
  import { email } from 'vuelidate/lib/validators'

  import AuthService from '@/services/auth-service'
  import ApiService from '@/services/api-service'

  export default {
    name: 'login-button',

    validations: {
      formEmail: {
        email,
      },
    },

    data () {
      return {
        loginModalActive: false,
        action: 'login',
        loginIncorrect: false,
        processing: false,
        formUsername: '',
        formPassword: '',
        formEmail: '',
      }
    },

    computed: {
      username () {
        return this.$store.state.user.username
      },

      loggedIn () {
        return !!this.$store.state.user.id
      },

      fieldEmailType () {
        return this.$v.formEmail.$error ? 'is-danger' : ''
      },

      fieldEmailMessages () {
        // return the help message when the form is valid to prevent the dialog resizing as data is entered
        return this.$v.formEmail.email
          ? 'We can only send instructions to the email address on your account.'
          : 'Please enter a valid email address.'
      },

      showLoginBox () {
        return this.action !== 'resetPassword' && this.action !== 'resendConfirmation' && this.action !== 'resendUnlock'
      },

      actionTitle () {
        switch (this.action) {
          case 'resetPassword':
            return 'Reset Password'
          case 'resendConfirmation':
            return 'Resend Confirmation'
          case 'resendUnlock':
            return 'Resend Unlock Instructions'
          default:
            console.error(`Unsupported action ${ this.action }`)

            return 'Unknown Action'
        }
      },
    },

    methods: {
      login () {
        if (!(this.formUsername.length && this.formPassword.length)) {
          return false
        }

        AuthService.login(
          {
            login: this.formUsername,
            password: this.formPassword,
          },
          this.$store
        ).then(({ user, token }) => {
          if (token) {
            successToast(`Logged in as ${ user?.username }`)
            this.dismissModal()
            // clear entered fields after logging in; someone logging out and leaving the machine will not expect
            // the next person to be able to log in just by clicking 'Log in' having the form prefilled!
            this.formUsername = ''
            this.formPassword = ''
          } else {
            failureToast('Login incorrect')
            this.loginIncorrect = true
            // set it back after the button has finished animating, so future failures will also animate
            setTimeout(() => {
              this.loginIncorrect = false
            }, 700)
          }
        }).catch((error) => {
          console.error(error)
          failureToast('There was a problem logging in. Please try later.')
        })
      },

      logout () {
        const loggedOutUser = this.username

        this.$buefy.dialog.confirm({
          title: 'Log out',
          message: 'Are you sure you want to log out?',
          cancelText: 'Cancel',
          confirmText: 'Log Out',
          type: 'is-danger',
          hasIcon: true,
          icon: 'alert',
          onConfirm: () => {
            AuthService.logout(this.$store)
            plainToast(`Logged out ${ loggedOutUser }`)
          },
        })
      },

      async adminAction () {
        switch (this.action) {
          case 'resetPassword':
            await this.resetPassword()
            break
          case 'resendConfirmation':
            await this.resendConfirmation()
            break
          case 'resendUnlock':
            await this.resendUnlock()
            break
          default:
            console.error(`Unsupported action ${ this.action }`)
            this.action = 'login'
        }
      },

      async resetPassword () {
        if (this.processing || this.$v.$invalid) return

        this.processing = true
        await ApiService.post('users/password', { user: { email: this.formEmail } })
        this.dismissModal()
        this.$buefy.dialog.alert({
          title: 'Resetting Password',
          message: 'We’ve sent an email to the address you gave us. To reset your password, follow the instructions in the email.',
          type: 'is-info',
          hasIcon: true,
          icon: 'email',
        })
        this.processing = false
        this.formEmail = ''
      },

      async resendConfirmation () {
        if (this.processing || this.$v.$invalid) return

        this.processing = true
        await ApiService.post('users/confirmation', { user: { email: this.formEmail } })
        this.dismissModal()
        this.$buefy.dialog.alert({
          title: 'Resending Confirmation',
          message: 'We’ve sent an email to the address you gave us. To confirm your email address, follow the instructions in the email.',
          type: 'is-info',
          hasIcon: true,
          icon: 'email',
        })
        this.processing = false
        this.formEmail = ''
      },

      async resendUnlock () {
        if (this.processing || this.$v.$invalid) return

        this.processing = true
        await ApiService.post('users/unlock', { user: { email: this.formEmail } })
        this.dismissModal()
        this.$buefy.dialog.alert({
          title: 'Resending Unlock Instructions',
          message: 'We’ve sent an email to the address you gave us. To unlock your account, follow the instructions in the email.',
          type: 'is-info',
          hasIcon: true,
          icon: 'email',
        })
        this.processing = false
        this.formEmail = ''
      },

      showModal () {
        this.loginModalActive = true
        this.$nextTick()
          .then(() => {
            this.$refs.username.focus()
          })
      },

      dismissModal () {
        this.loginModalActive = false
        this.action = 'login'
      },
    },
  }
</script>

<style lang="scss" scoped>
  @use 'sass:color';

  @import '@/assets/bulma-variables.scss';

  button {
    &.is-fullwidth {
      margin-bottom: 0.5rem;
      min-width: 10rem;
    }
  }

  .modal-card {
    width: auto;
  }

  .modal-card-body {
    padding-bottom: 2rem;
    width: 30rem;

    @include on-phone {
      width: 100%;
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: flex-end;
  }

  .reset-password {
    margin-top: 1rem;
    margin-left: 0;

    li {
      display: inline-block;

      &:first-child {
        padding-left: 0;
      }

      &:not(:last-child) {
        &::after {
          content: '・';
        }
      }
    }
  }
</style>
