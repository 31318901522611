<template>
  <front-page-card title="Pub of the Year"
                   :more-link="linkDestination"
                   more-text-screenreader="More information about our Pub of the Year…"
                   card="poty"
                   :larger="larger"
  >
    <div
      v-if="loading"
      class="loading"
    >
      <div>
        <b-skeleton />
      </div>
    </div>
    <template v-else-if="name">
      <p
        class="is-hidden-mobile is-block-tablet has-text-centered"
        data-testid="poty-info"
      >
        {{ name }}
        <template v-if="location">
          <br>
          {{ location }}
        </template>
      </p>
      <p class="is-block-mobile is-hidden-tablet">
        {{ nameAndLocation }}
      </p>
    </template>
    <p
      v-else
      data-testid="poty-info"
    >
      No information available.
    </p>
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'

  export default {
    name: 'poty-card',

    components: {
      FrontPageCard,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      name: {
        type: String,
        default: undefined,
      },

      location: {
        type: String,
        default: undefined,
      },

      page: {
        type: String,
        default: undefined,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      nameAndLocation () {
        return this.location ? `${ this.name }, ${ this.location }` : this.name
      },

      linkDestination () {
        return this.name ? this.page : null
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .loading {
    display: flex;
    width: 100%;

    @include not-on-phone {
      justify-content: center;
    }

    > div {
      width: 60%;
    }
  }
</style>
