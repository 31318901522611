<template>
  <footer>
    <div class="columns">
      <div class="column legal">
        <p>
          © 2017 – {{ thisYear }} the
          Campaign For Real Ale. The views expressed on this website are not
          necessarily those of the Campaign for Real Ale Ltd. Whilst every effort is made to ensure the information
          on this site is accurate, we can accept no liability for errors and omissions. Links to external sites do
          not imply any official CAMRA endorsement of the opinions or ideas expressed therein, or guarantee the
          validity of the information provided. Links to commercial sites are in no way an endorsement of any
          vendor's products or services.
        </p>
        <p class="privacy">
          <safe-link to="/privacy">
            Privacy Policy
          </safe-link>
        </p>
      </div>
      <div class="column login is-narrow">
        <login-button />
      </div>
    </div>
  </footer>
</template>

<script>
  import LoginButton from '@/components/helpers/login-button'
  import SafeLink from '@/components/helpers/safe-link'
  import { getYear } from 'date-fns'

  export default {
    name: 'site-footer',

    components: {
      SafeLink,
      LoginButton,
    },

    computed: {
      thisYear () {
        return getYear(new Date())
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  footer {
    margin-top: 1.5rem;
    color: $grey-darker;
    font-size: 75%;
  }

  .columns {
    margin-right: 0;
    margin-left: 0;
  }

  .column {
    @include on-phone {
      padding-right: 0;
    }
  }

  .legal {
    padding-left: 0;

    @include on-phone {
      padding-bottom: 0;
    }
  }

  .privacy {
    margin-top: 0.25rem;
    font-weight: bold;

    @include on-print {
      display: none;
    }
  }

  .login {
    padding-right: 0;
    padding-left: 0;

    .button {
      margin-bottom: 0.5rem;
      width: 7rem;
    }

    @include on-print {
      display: none;
    }
  }
</style>
