<template>
  <div>
    <section id="page-title">
      <h2>Beer Festivals</h2>
    </section>
    <section class="content-body">
      <loading-indicator :loading="loading">
        <div v-if="userCanSeeList" class="beer-festivals">
          <b-table
            :data="beerFestivals"
            :loading="tableLoading"
            striped
            mobile-cards
            :show-header="beerFestivals.length > 0 || filtersApplied"
            backend-filtering
            :debounce-search="500"
            backend-sorting
            :default-sort="[sortField, sortOrder]"
            :default-sort-direction="defaultSortOrder"
            paginated
            backend-pagination
            :current-page="paginatorPage"
            :total="totalItems"
            :per-page="paginatorPerPage"
            @sort="paginatorSortDidChange"
            @page-change="paginatorPageDidChange"
            @filters-change="tableFilterDidChange"
          >
            <b-table-column
              field="name"
              label="Name"
              searchable
              sortable
            >
              <template #default="props">
                {{ props.row.name }}
              </template>

              <template #searchable="props">
                <b-input
                  v-model="props.filters.name"
                  placeholder="Search…"
                  icon="magnify"
                  size="is-small"
                />
              </template>
            </b-table-column>

            <b-table-column
              field="startsAt"
              label="Starts On"
              sortable
            >
              <template #default="props">
                {{ props.row.readableStartsAt }}
              </template>
            </b-table-column>

            <b-table-column
              field="endsAt"
              label="Ends On"
              sortable
            >
              <template #default="props">
                {{ props.row.readableEndsAt }}
              </template>
            </b-table-column>

            <b-table-column
              v-slot="props"
              custom-key="buttons"
              width="15.5rem"
            >
              <div class="buttons">
                <buy-tickets-button
                  v-if="props.row.ticketSalesUrl && props.row.ticketSalesStartsAt <= now && props.row.ticketSalesEndsAt >= now"
                  small
                  short-text
                  :url="props.row.ticketSalesUrl"
                />

                <b-button
                  v-if="userCanDeleteFestival"
                  type="is-danger"
                  size="is-small"
                  outlined
                  @click="destroy(props.row)"
                >
                  Delete
                </b-button>

                <b-button
                  v-if="userCanViewFestival"
                  type="is-dark"
                  size="is-small"
                  outlined
                  @click="view(props.row)"
                >
                  View
                </b-button>

                <b-button
                  v-if="userCanEditFestival"
                  type="is-primary"
                  size="is-small"
                  outlined
                  @click="edit(props.row)"
                >
                  Edit
                </b-button>
              </div>
            </b-table-column>

            <template #bottom-left>
              <b-button
                v-if="userCanCreateFestival"
                type="is-primary"
                outlined
                data-testid="create-button"
                @click="create"
              >
                New Festival
              </b-button>
            </template>

            <template #empty>
              <section class="section no-beer-festivals">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>No beer festivals.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <access-denied v-else />
      </loading-indicator>
    </section>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { dateFormats } from '@/objects/date-formats'
  import { failureToast, successToast } from '@/helpers/notification-helper'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'
  import BuyTicketsButton from '@/components/helpers/buy-tickets-button.vue'

  import { format as formatDate, parse as parseDate, parseISO } from 'date-fns'

  export default {
    name: 'beer-festivals',

    components: {
      LoadingIndicator,
      AccessDenied,
      BuyTicketsButton,
    },

    data () {
      return {
        beerFestivals: [],
        loading: true,
        tableLoading: false,
        accessPermitted: true,
        paginatorPage: 1,
        paginatorPerPage: 8,
        tableFilter: '',
        totalItems: 0,
        sortField: 'name',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
      }
    },

    computed: {
      now () {
        return new Date()
      },

      userCanSeeList () {
        return this.accessPermitted && this.$store.getters['user/can']('list', 'beerFestivals')
      },

      userCanEditFestival () {
        return this.$store.getters['user/can']('edit', 'beerFestivals')
      },

      userCanViewFestival () {
        return this.$store.getters['user/can']('view', 'beerFestivals')
      },

      userCanDeleteFestival () {
        return this.$store.getters['user/can']('delete', 'beerFestivals')
      },

      userCanCreateFestival () {
        return this.$store.getters['user/can']('create', 'beerFestivals')
      },

      filtersApplied () {
        return this.tableFilter !== ''
      },

      lastPage () {
        return Math.ceil((this.totalItems) / this.paginatorPerPage)
      },
    },

    async mounted () {
      await this.loadBeerFestivals()
      this.loading = false

      EventBus.$on('userDidChange', () => {
        this.loading = true
        ;(async () => { await this.loadBeerFestivals() })()
        this.loading = false
      })
    },

    methods: {
      async loadBeerFestivals () {
        let returnedBeerFestivals, itemCount

        if (this.$route.query?.page === undefined) {
          this.paginatorPage = this.$store.getters['pagination/beerFestivalsPage'] || 1
        } else {
          let parsedPage = Number.parseInt(this.$route.query.page)

          if (parsedPage < 1 || Number.isNaN(parsedPage)) {
            parsedPage = 1
            await this.$router.replace({ name: 'beer-festivals', params: this.$route.params, query: { ...this.$route.query, page: parsedPage } })
          }

          this.paginatorPage = parsedPage
        }
        await this.$store.dispatch('pagination/clearBeerFestivalsPage')

        this.tableLoading = true
        this.accessPermitted = true

        try {
          for (;;) {
            const filters = new URLSearchParams({
              p: this.paginatorPage,
              s: this.sortField,
              o: this.sortOrder,
              n: this.paginatorPerPage,
              fn: this.tableFilter,
            }).toString()

            ;({ beerFestivals: returnedBeerFestivals, itemCount } = await ApiService.get(`beer_festivals?${ filters }`))

            this.totalItems = itemCount
            if (this.totalItems === 0 || this.paginatorPage === 1 || this.paginatorPage <= this.lastPage) break

            // page is past the end of the dataset
            this.paginatorPage = this.lastPage
            await this.$router.replace({ name: 'beer-festivals', params: this.$route.params, query: { ...this.$route.query, page: this.paginatorPage } })
          }
        } catch (error) {
          returnedBeerFestivals = []
          this.totalItems = 0

          if (error?.response?.status === 401) {
            this.accessPermitted = false
            failureToast('You do not have permission to do this')
          }
        }

        this.beerFestivals = returnedBeerFestivals
          .map((row) => {
            const createdAt = row.createdAt ? parseISO(row.createdAt) : null
            const updatedAt = row.updatedAt ? parseISO(row.updatedAt) : null
            // startsAt/endsAt are date-only; explicitly set timezone to UTC to avoid being off by one when browser and date differ on DST
            const startsAt = row.startsAt ? parseDate(`${ row.startsAt } Z`, 'yyyy-MM-dd X', new Date()) : null
            const endsAt = row.endsAt ? parseDate(`${ row.endsAt } Z`, 'yyyy-MM-dd X', new Date()) : null
            const ticketSalesStartsAt = row.ticketSalesStartsAt ? parseISO(row.ticketSalesStartsAt) : null
            const ticketSalesEndsAt = row.ticketSalesEndsAt ? parseISO(row.ticketSalesEndsAt) : null

            return {
              ...row,
              createdAt,
              updatedAt,
              startsAt,
              endsAt,
              ticketSalesStartsAt,
              ticketSalesEndsAt,
              readableStartsAt: startsAt ? formatDate(startsAt, dateFormats.longDate) : 'never',
              readableEndsAt: endsAt ? formatDate(endsAt, dateFormats.longDate) : 'never',
            }
          })
        this.tableLoading = false
      },

      paginatorSortDidChange (field, order) {
        this.sortField = field
        this.sortOrder = order
        this.loadBeerFestivals()
      },

      paginatorPageDidChange (page) {
        this.paginatorPage = page
        this.$router.replace({ name: 'beer-festivals', params: this.$route.params, query: { ...this.$route.query, page } })
        this.loadBeerFestivals()
      },

      tableFilterDidChange (filter) {
        this.tableFilter = filter.name
        this.loadBeerFestivals()
      },

      destroy (festival) {
        this.$buefy.dialog.confirm({
          title: 'Delete Beer Festival',
          message: `Are you sure you want to delete the beer festival “${ festival.name }”? This cannot be undone.`,
          cancelText: 'Keep',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          icon: 'alert',
          onConfirm: async () => {
            try {
              await ApiService.delete('beer_festivals', festival.id)
              successToast('Beer festival deleted')
              this.totalItems -= 1
              if (this.paginatorPage > 1 && this.paginatorPage > this.lastPage) {
                await this.paginatorPageDidChange(this.lastPage)
              } else {
                await this.loadBeerFestivals()
              }
            } catch (error) {
              const message = error?.response?.status === 401
                ? 'You do not have permission to delete this beer festival'
                : 'Could not delete beer festival'

              failureToast(message)
            }
          },
        })
      },

      view (festival) {
        this.$store.dispatch('pagination/setBeerFestivalsPage', { page: this.paginatorPage })
        // Use the festival slug as end-users will see this URL
        this.$router.push(`/beer-festivals/${ festival.slug }`)
      },

      edit (festival) {
        this.$store.dispatch('pagination/setBeerFestivalsPage', { page: this.paginatorPage })
        // Use the festival ID as only admins will see this URL, and the ID is consistent no matter what edits are made
        this.$router.push(`/beer-festivals/${ festival.id }/edit`)
      },

      create () {
        this.$store.dispatch('pagination/setBeerFestivalsPage', { page: this.paginatorPage })
        this.$router.push('/beer-festivals/new')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .beer-festivals {
    margin-bottom: 2rem;

    @include on-phone {
      margin-top: 0.75rem;
    }
  }

  ::v-deep {
    table {
      tr {
        th,
        td {
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  .buttons {
    justify-content: flex-end;

    ::v-deep {
      .button {
        @include not-on-phone {
          margin-top: -3px;
          margin-bottom: 0;
        }
      }
    }
  }
</style>
