import { parse as parseDate, parseISO } from 'date-fns'
import { dateFormats } from '@/objects/date-formats'

export const parseDateTimes = (item) => {
  if (item?.startsAt) {
    item.startsAt = parseISO(item.startsAt)
  }
  if (item?.endsAt) {
    item.endsAt = parseISO(item.endsAt)
  }

  // standard Rails fields
  if (item?.createdAt) {
    item.createdAt = parseISO(item.createdAt)
  }
  if (item?.updatedAt) {
    item.updatedAt = parseISO(item.updatedAt)
  }

  // this is only used in the events for the front page
  if (item?.dateOfEvent) {
    item.dateOfEvent = parseDate(item.dateOfEvent, dateFormats.inputDateOnly, new Date())
  }

  return item
}

export const parseDates = (item) => {
  if (item?.startsAt) {
    item.startsAt = parseDate(item.startsAt, dateFormats.inputDateOnly, new Date())
  }
  if (item?.endsAt) {
    item.endsAt = parseDate(item.endsAt, dateFormats.inputDateOnly, new Date())
  }

  return item
}

export const parseTicketSalesDateTimes = (item) => {
  if (item?.ticketSalesStartsAt) {
    item.ticketSalesStartsAt = parseISO(item.ticketSalesStartsAt)
  }
  if (item?.ticketSalesEndsAt) {
    item.ticketSalesEndsAt = parseISO(item.ticketSalesEndsAt)
  }

  return item
}
