<template>
  <div>
    <section id="page-title">
      <h2>Calendar Feeds</h2>
    </section>
    <section class="content-body">
      <loading-indicator :loading="loading">
        <div v-if="userCanSeeList" class="calendar-feeds">
          <b-table
            :data="calendarFeeds"
            :loading="tableLoading"
            striped
            mobile-cards
            :show-header="calendarFeeds.length > 0 || filtersApplied"
            backend-filtering
            :debounce-search="500"
            backend-sorting
            :default-sort="[sortField, sortOrder]"
            :default-sort-direction="defaultSortOrder"
            paginated
            backend-pagination
            :current-page="paginatorPage"
            :total="totalItems"
            :per-page="paginatorPerPage"
            @sort="paginatorSortDidChange"
            @page-change="paginatorPageDidChange"
            @filters-change="tableFilterDidChange"
          >
            <b-table-column
              field="name"
              label="Name"
              searchable
              sortable
            >
              <template #default="props">
                {{ props.row.name }}
              </template>

              <template #searchable="props">
                <b-input
                  v-model="props.filters.name"
                  placeholder="Search…"
                  icon="magnify"
                  size="is-small"
                />
              </template>
            </b-table-column>

            <b-table-column
              field="label"
              label="Label"
              searchable
              sortable
            >
              <template #default="props">
                <event-label :colour="props.row.colour">
                  {{ props.row.label }}
                </event-label>
              </template>

              <template #searchable="props">
                <b-input
                  v-model="props.filters.label"
                  placeholder="Search…"
                  icon="magnify"
                  size="is-small"
                />
              </template>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="syncedAt"
              label="Last Synced"
              sortable
            >
              {{ props.row.readableSyncedAt }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="enabled"
              label="Status"
              width="5rem"
              sortable
            >
              <b-tag
                v-if="props.row.enabled"
                type="is-success"
              >
                Enabled
              </b-tag>
              <b-tag v-else>
                Disabled
              </b-tag>
            </b-table-column>

            <b-table-column
              v-slot="props"
              custom-key="buttons"
              width="16.5rem"
            >
              <div class="buttons">
                <b-button
                  v-if="userCanSyncFeed && props.row.enabled"
                  size="is-small"
                  outlined
                  :loading="props.row.syncQueued"
                  @click="sync(props.row)"
                >
                  Sync
                </b-button>

                <b-button
                  v-if="userCanDeleteFeed"
                  type="is-danger"
                  size="is-small"
                  outlined
                  @click="destroy(props.row)"
                >
                  Delete
                </b-button>

                <b-button
                  v-if="userCanViewFeed"
                  type="is-dark"
                  size="is-small"
                  outlined
                  @click="view(props.row)"
                >
                  View
                </b-button>

                <b-button
                  v-if="userCanEditFeed"
                  type="is-primary"
                  size="is-small"
                  outlined
                  @click="edit(props.row)"
                >
                  Edit
                </b-button>
              </div>
            </b-table-column>

            <template #bottom-left>
              <b-button
                v-if="userCanCreateFeed"
                type="is-primary"
                outlined
                data-testid="new-calendar-feed-button"
                @click="create"
              >
                New Feed
              </b-button>
            </template>

            <template #empty>
              <section class="section no-calendar-feeds">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>No calendar feeds.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <access-denied v-else />
      </loading-indicator>
    </section>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { dateFormats } from '@/objects/date-formats'
  import { failureToast, successToast, plainToast } from '@/helpers/notification-helper'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'
  import EventLabel from '@/components/helpers/event-label'

  import { parseISO, format as formatDate } from 'date-fns'

  export default {
    name: 'calendar-feeds',

    components: {
      LoadingIndicator,
      AccessDenied,
      EventLabel,
    },

    data () {
      return {
        calendarFeeds: [],
        loading: true,
        tableLoading: false,
        accessPermitted: true,
        paginatorPage: 1,
        paginatorPerPage: 8,
        tableFilterName: '',
        tableFilterLabel: '',
        totalItems: 0,
        sortField: 'name',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
      }
    },

    computed: {
      userCanSeeList () {
        return this.accessPermitted && this.$store.getters['user/can']('list', 'calendarFeeds')
      },

      userCanEditFeed () {
        return this.$store.getters['user/can']('edit', 'calendarFeeds')
      },

      userCanViewFeed () {
        return this.$store.getters['user/can']('view', 'calendarFeeds')
      },

      userCanDeleteFeed () {
        return this.$store.getters['user/can']('delete', 'calendarFeeds')
      },

      userCanSyncFeed () {
        return this.$store.getters['user/can']('sync', 'calendarFeeds')
      },

      userCanCreateFeed () {
        return this.$store.getters['user/can']('create', 'calendarFeeds')
      },

      filtersApplied () {
        return this.tableFilterName !== '' || this.tableFilterLabel !== ''
      },

      lastPage () {
        return Math.ceil((this.totalItems) / this.paginatorPerPage)
      },
    },

    async mounted () {
      await this.loadCalendarFeeds()
      this.loading = false

      EventBus.$on('userDidChange', () => {
        this.loading = true
        ;(async () => { await this.loadCalendarFeeds() })()
        this.loading = false
      })
    },

    methods: {
      async loadCalendarFeeds () {
        let returnedCalendarFeeds, itemCount

        if (this.$route.query?.page === undefined) {
          this.paginatorPage = this.$store.getters['pagination/calendarFeedsPage'] || 1
        } else {
          let parsedPage = Number.parseInt(this.$route.query.page)

          if (parsedPage < 1 || Number.isNaN(parsedPage)) {
            parsedPage = 1
            await this.$router.replace({ name: 'calendar-feeds', params: this.$route.params, query: { ...this.$route.query, page: parsedPage } })
          }

          this.paginatorPage = parsedPage
        }
        await this.$store.dispatch('pagination/clearCalendarFeedsPage')

        this.tableLoading = true
        this.accessPermitted = true

        try {
          for (;;) {
            const filters = new URLSearchParams({
              p: this.paginatorPage,
              s: this.sortField,
              o: this.sortOrder,
              n: this.paginatorPerPage,
              fn: this.tableFilterName,
              fl: this.tableFilterLabel,
            }).toString()

            ;({ calendarFeeds: returnedCalendarFeeds, itemCount } = await ApiService.get(`calendar_feeds?${ filters }`))

            this.totalItems = itemCount
            if (this.totalItems === 0 || this.paginatorPage === 1 || this.paginatorPage <= this.lastPage) break

            // page is past the end of the dataset
            this.paginatorPage = this.lastPage
            await this.$router.replace({ name: 'calendar-feeds', params: this.$route.params, query: { ...this.$route.query, page: this.paginatorPage } })
          }
        } catch (error) {
          returnedCalendarFeeds = []
          this.totalItems = 0

          if (error?.response?.status === 401) {
            this.accessPermitted = false
            failureToast('You do not have permission to do this')
          }
        }

        this.calendarFeeds = returnedCalendarFeeds
          .map((row) => {
            const createdAt = row.createdAt ? parseISO(row.createdAt) : null
            const updatedAt = row.updatedAt ? parseISO(row.updatedAt) : null
            const syncedAt = row.syncedAt ? parseISO(row.syncedAt) : null

            return {
              ...row,
              createdAt,
              updatedAt,
              syncedAt,
              readableSyncedAt: syncedAt ? formatDate(syncedAt, dateFormats.dateWithTime) : 'never',
            }
          })
        this.tableLoading = false
      },

      paginatorSortDidChange (field, order) {
        this.sortField = field
        this.sortOrder = order
        this.loadCalendarFeeds()
      },

      paginatorPageDidChange (page) {
        this.paginatorPage = page
        this.$router.replace({ name: 'calendar-feeds', params: this.$route.params, query: { ...this.$route.query, page } })
        this.loadCalendarFeeds()
      },

      tableFilterDidChange (filter) {
        this.tableFilterName = filter.name ?? ''
        this.tableFilterLabel = filter.label ?? ''
        this.loadCalendarFeeds()
      },

      sync (feed) {
        try {
          feed.syncQueued = true
          ApiService.get(`calendar_feeds/${ feed.id }/sync`)
          plainToast('Sync queued')
        } catch (_error) {
          failureToast('Could not sync feed')
        }
      },

      destroy (feed) {
        this.$buefy.dialog.confirm({
          title: 'Delete Feed',
          message: `Are you sure you want to delete the feed “${ feed.name }” and all its events? This cannot be undone.`,
          cancelText: 'Keep',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          icon: 'alert',
          onConfirm: async () => {
            try {
              await ApiService.delete('calendar_feeds', feed.id)
              successToast('Calendar feed deleted')
              this.totalItems -= 1
              if (this.paginatorPage > 1 && this.paginatorPage > this.lastPage) {
                await this.paginatorPageDidChange(this.lastPage)
              } else {
                await this.loadCalendarFeeds()
              }
            } catch (error) {
              const message = error?.response?.status === 401
                ? 'You do not have permission to delete this feed'
                : 'Could not delete feed'

              failureToast(message)
            }
          },
        })
      },

      view (feed) {
        this.$store.dispatch('pagination/setCalendarFeedsPage', { page: this.paginatorPage })
        this.$router.push(`/calendar-feeds/${ feed.id }`)
      },

      edit (feed) {
        this.$store.dispatch('pagination/setCalendarFeedsPage', { page: this.paginatorPage })
        this.$router.push(`/calendar-feeds/${ feed.id }/edit`)
      },

      create () {
        this.$store.dispatch('pagination/setCalendarFeedsPage', { page: this.paginatorPage })
        this.$router.push('/calendar-feeds/new')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .calendar-feeds {
    margin-bottom: 2rem;

    @include on-phone {
      margin-top: 0.75rem;
    }
  }

  ::v-deep {
    table {
      tr {
        th,
        td {
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  .buttons {
    justify-content: flex-end;

    .button {
      @include not-on-phone {
        margin-top: -2px;
        margin-bottom: 0;
      }
    }
  }
</style>
