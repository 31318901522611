<template>
  <div class="event-date">
    <div class="month">
      <date-time :datetime="startsAt">
        {{ formattedMonth }}
      </date-time>
    </div>

    <div class="day">
      <date-time :datetime="startsAt">
        {{ formattedDay }}
      </date-time>
    </div>

    <div v-if="formattedYear" class="year">
      <date-time :datetime="startsAt">
        {{ formattedYear }}
      </date-time>
    </div>

    <div v-if="allDay" class="times">
      all day
    </div>
    <div v-else-if="multiDay" class="times">
      {{ formattedStartTime }}
    </div>
    <div v-else class="times">
      <date-time :datetime="startsAt" show-time>
        {{ formattedStartTime }}
      </date-time> –
      <date-time :datetime="endsAt" show-time>
        {{ formattedEndTime }}
      </date-time>
    </div>
  </div>
</template>

<script>
  import { isSameDay, isThisYear, format as formatDate } from 'date-fns'
  import DateTime from '@/components/helpers/date-time'

  export default {
    name: 'event-date',

    components: {
      DateTime,
    },

    props: {
      startsAt: {
        type: Date,
        required: true,
      },

      endsAt: {
        type: Date,
        required: true,
      },

      allDay: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      multiDay () {
        return !isSameDay(this.startsAt, this.endsAt)
      },

      formattedDay () {
        return formatDate(this.startsAt, 'd')
      },

      formattedMonth () {
        return formatDate(this.startsAt, 'MMMM')
      },

      formattedYear () {
        return isThisYear(this.startsAt) ? '' : formatDate(this.startsAt, 'yyyy')
      },

      formattedStartTime () {
        return formatDate(this.startsAt, 'HH:mm')
      },

      formattedEndTime () {
        return formatDate(this.endsAt, 'HH:mm')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  // Event calendar colours
  $event-list-calendar-month-colour: $body-background-color;
  $event-list-calendar-month-background-colour: #b42a2d;
  $event-list-calendar-date-colour: $body-color;
  $event-list-calendar-date-background-colour: $body-background-color;
  $event-list-calendar-year-colour: #666;
  $event-list-calendar-year-background-colour: $body-background-color;
  $event-list-calendar-times-colour: $body-color;
  $event-list-calendar-times-background-colour: #e1e1e1;

  .event-date {
    width: 4.5rem;
    text-align: center;
  }

  .month {
    background-color: $event-list-calendar-month-background-colour;
    padding: 1px 0;
    color: $event-list-calendar-month-colour;
    font-size: 0.7rem;
    font-weight: $weight-bold;
  }

  .day {
    background-color: $event-list-calendar-date-background-colour;
    padding: 0;
    color: $event-list-calendar-date-colour;
    font-size: 2rem;
  }

  .year {
    margin-top: -0.4rem;
    background-color: $event-list-calendar-year-background-colour;
    padding: 0 0 0.2rem 0;
    color: $event-list-calendar-year-colour;
    font-size: 0.7rem;
  }

  .times {
    background-color: $event-list-calendar-times-background-colour;
    padding: 1px 0;
    color: $event-list-calendar-times-colour;
    font-size: 0.6rem;
    font-weight: bold;
  }
</style>
