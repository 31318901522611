<template>
  <loading-indicator :loading="loading">
    <template v-if="eventValid">
      <section id="page-title">
        <h2>{{ event.summary }}</h2>
      </section>

      <section class="content-body columns">
        <section class="column event-details">
          <dl>
            <dt data-testid="event-label">
              <event-label :colour="event.colour">
                {{ event.label }}
              </event-label>
            </dt>

            <dt>When</dt>
            <dd data-testid="event-when">
              <date-time-range
                :starts-at="event.startsAt"
                :ends-at="event.endsAt"
                :show-times="!event.allDay"
              />
            </dd>

            <template v-if="event.location">
              <dt>Where</dt>
              <dd data-testid="event-where">
                {{ event.location }}
              </dd>
            </template>

            <template v-if="event.description">
              <dt>Details</dt>
              <dd data-testid="event-description">
                {{ event.description }}
              </dd>
            </template>
          </dl>

          <aside class="nav-buttons">
            <safe-link to="/events">
              <b-button
                type="is-primary"
                outlined
              >
                All events
              </b-button>
            </safe-link>
          </aside>
        </section>

        <aside class="column is-two-fifths event-map">
          <draggable-map
            :latitude="event.latitude"
            :longitude="event.longitude"
            :address="event.location"
          />
        </aside>
      </section>
    </template>

    <template v-else>
      <section class="section no-event">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>This event could not be loaded.</p>
        </div>
      </section>
    </template>
  </loading-indicator>
</template>

<script>
  import ApiService from '@/services/api-service'
  import { parseDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast } from '@/helpers/notification-helper'
  import { setTitle } from '@/router'

  import SafeLink from '@/components/helpers/safe-link'
  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import DraggableMap from '@/components/helpers/draggable-map'
  import EventLabel from '@/components/helpers/event-label'
  import DateTimeRange from '@/components/helpers/date-time-range'

  export default {
    name: 'event',

    components: {
      SafeLink,
      LoadingIndicator,
      DraggableMap,
      EventLabel,
      DateTimeRange,
    },

    async beforeRouteUpdate (_to, _from, next) {
      this.loading = true
      this.event = {}
      await this.loadEvent(this.id)
      this.loading = false
      next()
    },

    props: {
      id: {
        type: String,
        default: undefined,
      },
    },

    data () {
      return {
        loading: true,
        event: {},
      }
    },

    computed: {
      eventValid () {
        return this.event && this.event.id
      },
    },

    async mounted () {
      await this.loadEvent(this.id)
      this.loading = false
    },

    methods: {
      async loadEvent (id) {
        let title = undefined

        try {
          const { event } = await ApiService.get(`events/${ id }`)

          title = event.summary
          this.event = parseDateTimes(event)
        } catch (error) {
          if (error?.response?.status !== 404) {
            failureToast('There was a problem getting data from the server. Please try later.')
          }
        } finally {
          setTitle(title)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .event-map {
    @include not-on-phone {
      order: -1;
    }
  }

  .event-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    dt {
      margin-top: 0.5rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }
</style>
