export const dateFormats = {
  date: 'MMM d, yyyy',
  longDate: 'EEE, MMM d, yyyy',
  cardDate: 'EEE, MMM d',
  time: 'HH:mm',
  longDateWithTime: 'EEE, MMM d, yyyy HH:mm',
  dateWithTime: 'MMM d, yyyy HH:mm',
  cardDateWithTime: 'EEE, MMM d HH:mm',
  popover: 'MMM d HH:mm',
  inputDateOnly: 'yyyy-LL-dd',
}
