<template>
  <loading-indicator :loading="loading">
    <section id="page-title">
      <h2>{{ pageHeading }}</h2>
    </section>

    <section class="content-body">
      <access-denied v-if="!(userCanEditIssue && accessPermitted)" />

      <template v-else-if="issueLoaded">
        <form
          accept-charset="UTF-8"
          data-testid="publication-form"
          @keydown.prevent.enter=""
        >
          <input name="utf8" type="hidden" value="✓">

          <b-field
            label="Name"
            :type="fieldNameType"
            :message="fieldNameMessages"
          >
            <b-input
              v-model.trim="$v.issue.name.$model"
              data-testid="issue-form-name"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field
            label="Issue Number"
            :type="fieldIssueNumberType"
            :message="fieldIssueNumberMessage"
          >
            <b-input
              v-model.trim="$v.issue.issueNumber.$model"
              inputmode="decimal"
              data-testid="issue-form-issue-number"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field
            label="PDF download URL"
            :type="fieldPDFUrlType"
            :message="fieldPDFUrlMessage"
          >
            <b-input
              v-model.trim="$v.issue.pdfUrl.$model"
              inputmode="url"
              data-testid="issue-form-pdf-url"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field
            label="Description"
          >
            <b-input
              v-model.trim="issue.description"
              type="textarea"
              data-testid="issue-form-description"
            />
          </b-field>

          <b-field
            label="Publication Date"
            :type="fieldPublicationDateType"
            :message="fieldPublicationDateMessage"
          >
            <v-date-picker
              v-model="$v.issue.publishedAt.$model"
              mode="dateTime"
              is24hr
              :popover="{ placement: 'bottom', visibility: 'focus' }"
              weeks-transition="fade"
              :masks="dateFormats"
              :model-config="{ timeAdjust: '12:00:00' }"
              @keyup.native.enter.once="saveForm"
            >
              <template #default="{ inputValue, inputEvents }">
                <input
                  type="text"
                  class="input publication-date"
                  :class="fieldPublicationDateType"
                  :value="inputValue"
                  inputmode="numeric"
                  data-testid="issue-form-publication-date"
                  v-on="inputEvents"
                >
              </template>
            </v-date-picker>
          </b-field>

          <div class="actions columns">
            <div class="column">
              <b-button
                v-if="mode !== 'create'"
                outlined
                type="is-dark"
                :disabled="regeneratingThumbnails || $v.$invalid"
                :loading="regeneratingThumbnails"
                data-testid="regenerate-thumbnails-button"
                @click="regenerateThumbnails"
              >
                Regenerate Thumbnails
              </b-button>
            </div>
            <div class="action-buttons column">
              <b-button
                outlined
                data-testid="cancel-button"
                @click="confirmDataLoss"
              >
                Cancel
              </b-button>
              <b-button
                type="is-primary"
                :disabled="saving || $v.$invalid"
                :loading="saving"
                data-testid="save-button"
                @click="saveForm"
              >
                {{ saveButtonText }}
              </b-button>
            </div>
          </div>
        </form>
      </template>

      <template v-else>
        <section class="section issue">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>This issue could not be loaded.</p>
          </div>
        </section>
      </template>
    </section>
  </loading-indicator>
</template>

<script>
  import { isEqual } from 'lodash'
  import { and, integer, minValue, required } from 'vuelidate/lib/validators'
  import { urlHttpOrHttps, urlWithoutUsername } from '@/validators/url'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { setTitle } from '@/router'
  import { parseDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast, successToast } from '@/helpers/notification-helper'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'

  export default {
    name: 'issue',

    components: {
      LoadingIndicator,
      AccessDenied,
    },

    async beforeRouteUpdate (_to, _from, next) {
      this.loading = true
      this.publication = {}
      this.issue = {}

      await this.loadIssue(this.id)
      next(() => {
        this.redirectOnShow()
      })
    },

    props: {
      id: {
        type: String,
        default: undefined,
      },

      publicationId: {
        type: String,
        default: undefined,
      },

      mode: {
        type: String,
        default: 'show',
        validator (value) {
          return ['show', 'create', 'edit'].indexOf(value) !== -1
        },
      },
    },

    validations: {
      issue: {
        name: {
          required,
        },

        issueNumber: {
          required,
          sensibleIssueNumber: and(integer, minValue(1)),
          async issueNumberTaken (issueNumber) {
            let taken

            if (issueNumber === '' || issueNumber === undefined) return true
            // eslint-disable-next-line eqeqeq
            if (issueNumber == this.originalIssue.issueNumber) return true
            if (!this?.$v?.issue?.issueNumber) return true
            if (!this.$v.issue.issueNumber.sensibleIssueNumber) return true

            try {
              ({ taken } = await ApiService.get(`publications/${ this.publicationId }/issue_number_taken/${ issueNumber }`))
            } catch (error) {
              console.error(error)
              taken = true
            }

            // validations return true for a pass, false for a fail
            return !taken
          },
        },

        pdfUrl: {
          required,
          urlHttpOrHttps,
          urlWithoutUsername,
        },

        publishedAt: {
          required,
        },
      },
    },

    data () {
      return {
        loading: true,
        saving: false,
        regeneratingThumbnails: false,
        accessPermitted: true,
        fromPage: 1,
        issue: {},
        originalIssue: {},
        pageHeading: '',
        dateFormats: {
          inputDateTime24hr: [
            'YYYY-MM-DD HH:mm:ss',
            'YYYY/MM/DD HH:mm:ss',
            'DD/MM/YYYY HH:mm:ss',
          ],

          data: ['YYYY-MM-DD HH:mm:ss'],
        },
      }
    },

    computed: {
      issueLoaded () {
        return this.issue && (this.mode === 'create' || this.issue.id)
      },

      userCanEditIssue () {
        return this.$store.getters['user/can']('edit', 'issues')
      },

      saveButtonText () {
        return this.mode === 'create' ? 'Create' : 'Save'
      },

      issueChanged () {
        return !isEqual(this.issue, this.originalIssue)
      },

      fieldNameType () {
        return this.$v.issue.name.$error ? 'is-danger' : ''
      },

      fieldNameMessages () {
        if (!this.$v.issue.name.$error) return ''

        return this.$v.issue.name.required ? '' : 'The name is required.'
      },

      fieldIssueNumberType () {
        return this.$v.issue.issueNumber.$error ? 'is-danger' : ''
      },

      fieldIssueNumberTaken () {
        return !this.$v.issue.issueNumber.$pending && this.$v.issue.issueNumber.issueNumberTaken
      },

      fieldIssueNumberMessage () {
        if (!this.$v.issue.issueNumber.$error) return ''

        const messages = []

        if (!this.$v.issue.issueNumber.required) {
          messages.push('The issue number is required.')
        }
        if (!this.$v.issue.issueNumber.sensibleIssueNumber) {
          messages.push('The issue number must be a whole number greater than 0.')
        }
        if (!this.fieldIssueNumberTaken) {
          messages.push('This issue number is being used for another issue of this publication. Please choose another.')
        }

        return messages
      },

      fieldPDFUrlType () {
        return this.$v.issue.pdfUrl.$error ? 'is-danger' : ''
      },

      fieldPDFUrlMessage () {
        if (this.readOnly || !this.$v.issue.pdfUrl.$error) return ''

        const messages = []

        if (!this.$v.issue.pdfUrl.required) {
          messages.push('The URL is required.')
        }
        if (!this.$v.issue.pdfUrl.urlHttpOrHttps) {
          messages.push('The URL must be http or https, and not for a local server.')
        }
        if (!this.$v.issue.pdfUrl.urlWithoutUsername) {
          messages.push('The URL must not have a username or password in it.')
        }

        return messages
      },

      fieldPublicationDateType () {
        return this.$v.issue.publishedAt.$error ? 'is-danger' : ''
      },

      fieldPublicationDateMessage () {
        if (this.readOnly || !this.$v.issue.publishedAt.$error) return ''

        return this.$v.issue.publishedAt.required ? '' : 'The publication date is required.'
      },
    },

    async mounted () {
      this.fromPage = this.$store.getters['pagination/issuesPage'] || 1
      await this.$store.dispatch('pagination/clearIssuesPage')
      await this.loadIssue(this.id)
      this.redirectOnShow()

      EventBus.$on('userDidChange', () => {
        (async () => { await this.loadIssue(this.id) })()
      })
    },

    methods: {
      redirectOnShow () {
        if (this.mode === 'show') {
          window.location.replace(this.issue.pdfUrl)
        }
      },

      async loadIssue (id) {
        this.loading = true
        this.issue = {}
        this.accessPermitted = true

        try {
          const { publication } = await ApiService.get(`publications/${ this.publicationId }`)

          this.publication = parseDateTimes(publication)
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else if (error?.response?.status === 422) {
            this.publication = {}
          } else if (error?.response?.status !== 404) {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          this.publication = {
            id: undefined,
            name: 'Unknown Publication',
          }
        }

        if (this.mode === 'create') {
          this.issue = await this.loadBlankIssue()
          this.pageHeading = `New ${ this.publication.name } Issue`
        } else {
          if (this.mode === 'edit' && !this.$store.getters['user/can']('edit', 'issues')) {
            this.accessPermitted = false
          } else {
            const prefix = this.mode === 'edit' ? `Edit ${ this.publication.name } ` : ''

            this.issue = await this.loadExistingIssue(id)
            this.pageHeading = this.issueLoaded ? `${ prefix }Issue #${ this.issue.issueNumber }: ${ this.issue.name }` : `${ prefix }Issue`
          }
        }

        if (!this.accessPermitted) {
          this.pageHeading = ''
        }
        this.originalIssue = { ...this.issue }
        setTitle(this.pageHeading)

        this.loading = false
      },

      async loadBlankIssue () {
        try {
          const { issue } = await ApiService.get(`publications/${ this.publicationId }/issues/new`)

          return issue
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      async loadExistingIssue (id) {
        try {
          const { issue } = await ApiService.get(`publications/${ this.publicationId }/issues/${ id }`)

          return parseDateTimes(issue)
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else if (error?.response?.status === 422) {
            this.issue = {}
          } else if (error?.response?.status !== 404) {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      confirmDataLoss () {
        if (this.issueChanged) {
          this.$buefy.dialog.confirm({
            title: 'Cancel Editing',
            message: 'Are you sure you want to cancel? Your changes will be lost.',
            cancelText: 'No',
            confirmText: 'Yes',
            type: 'is-danger',
            hasIcon: true,
            icon: 'alert',
            onConfirm: () => this.allIssues(),
          })
        } else {
          this.allIssues()
        }
      },

      async regenerateThumbnails () {
        this.$v.$touch()
        if (this.$v.$invalid) return

        try {
          this.regeneratingThumbnails = true
          // TODO: once pub/sub is implemented (https://git.omgponies.org.uk/western-sussex-camra/frontend/-/issues/31) this should
          //       be set back to false when the job is completed
          await ApiService.get(`publications/${ this.publicationId }/issues/${ this.id }/regenerate_thumbnails`)
        } catch (error) {
          switch (error?.response?.status) {
            case 401:
              failureToast('You do not have permission to do this')
              break
            default:
              console.error(error)
              failureToast('There was a problem communicating with the server; please try later')
              break
          }
        }
      },

      async saveForm () {
        this.$v.$touch()
        if (this.mode === 'show' || this.saving || this.$v.$invalid) return

        try {
          const issue = this.issue

          this.saving = true
          switch (this.mode) {
            case 'create':
              await ApiService.post(`publications/${ this.publicationId }/issues`, { issue })
              break
            case 'edit':
              await ApiService.put(`publications/${ this.publicationId }/issues/${ this.id }`, { issue })
              break
            default:
              console.error(`saveForm called with unsupported mode ${ this.mode }`)

              return
          }

          successToast('Issue saved')

          this.allIssues()
        } catch (error) {
          let formattedErrors

          switch (error?.response?.status) {
            case 422:
              formattedErrors = error?.response?.data?.errors?.join('</li><li>')

              this.$buefy.dialog.alert({
                title: 'Could not save issue',
                message: `The server reported the following errors: <ul class="rails-errors"><li>${ formattedErrors }</li></ul>`,
                type: 'is-danger',
                hasIcon: true,
                icon: 'emoticon-sad',
              })
              break
            case 401:
              failureToast('You do not have permission to do this')
              break
            default:
              console.error(error)
              failureToast('There was a problem communicating with the server; please try later')
              break
          }
        } finally {
          this.saving = false
        }
      },

      allIssues () {
        this.$store.dispatch('pagination/setIssuesPage', { page: this.fromPage })
        this.$router.push(`/publications/${ this.publicationId }/issues`)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  form {
    margin: 0 auto;
    max-width: $form-width;
  }

  .help {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .actions {
    align-items: center;

    .action-buttons {
      text-align: right;

      .button {
        @include not-on-phone {
          &:not(:first-child) {
            margin-left: 0.75rem;
          }
        }

        @include on-phone {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>

<style lang="scss">
  .publication-date {
    max-width: 22rem;
  }

  // Buefy dialogs can't be styled from the calling component, as they are created on the <body>, so this rule must be global
  // Can be replaced by a ::v-global block after moving to Vue 3
  .dialog {
    ul {
      &.rails-errors {
        list-style-type: circle;

        li {
          padding-left: 0;
        }
      }
    }
  }
</style>
