<template>
  <b-message
    type="is-danger"
    has-icon
    icon="head-question-outline"
  >
    <p class="msg">
      We can’t find that page.
    </p>
    <p>
      <safe-link
        id="return-link"
        to="/"
      >
        Go back to the main page
      </safe-link>
    </p>
  </b-message>
</template>

<script>
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'not-found',

    components: {
      SafeLink,
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .msg {
    font-weight: $weight-bold;
  }

  // Restore default <a> behaviour as Buefy overrides this in <b-message>
  ::v-deep {
    #return-link {
      text-decoration: none;
      color: $primary;

      &:hover {
        text-decoration: underline;
        color: $hover-color;
      }
    }
  }
</style>
