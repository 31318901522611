<template>
  <b-button
    type="is-primary"
    :size="size"
    :icon-left="icon"
  >
    <safe-link
      :to="url"
      target="_blank"
      allow-referrer
    >
      <strong>{{ buttonLabel }}</strong>
    </safe-link>
  </b-button>
</template>

<script>
  import SafeLink from '@/components/helpers/safe-link.vue'

  export default {
    name: 'buy-tickets-button',

    components: {
      SafeLink,
    },

    props: {
      url: {
        type: String,
        required: true,
      },

      small: {
        type: Boolean,
        default: false,
      },

      shortText: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      size () {
        return this.small ? 'is-small' : ''
      },

      icon () {
        return this.shortText ? '' : 'ticket-outline'
      },

      buttonLabel () {
        return this.shortText ? 'Buy' : 'Buy Tickets'
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  ::v-deep {
    a {
      color: $white;
    }
  }
</style>
