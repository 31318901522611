<template>
  <front-page-card title="LocAle"
                   :more-link="page"
                   more-text-screenreader="More information about LocAle…"
                   card="locale"
                   :larger="larger"
  >
    <p>Locally-brewed real ales and the pubs that sell them.</p>
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'

  export default {
    name: 'locale-card',

    components: {
      FrontPageCard,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      page: {
        type: String,
        default: undefined,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
