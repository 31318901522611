import { ToastProgrammatic as Toast } from 'buefy'

export const successToast = (message) => {
  Toast.open({
    type: 'is-success',
    position: 'is-top-right',
    message,
  })
}

export const failureToast = (message) => {
  Toast.open({
    type: 'is-danger',
    position: 'is-top-right',
    message,
  })
}

export const plainToast = (message) => {
  Toast.open({
    type: 'is-white',
    position: 'is-top-right',
    message,
  })
}
