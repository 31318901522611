<template>
  <div>
    <section id="page-title">
      <h2>Branch Diary</h2>
    </section>
    <div id="events" class="content-body columns">
      <section class="column events-list">
        <loading-indicator :loading="loading">
          <events-listing
            :events="events"
          />
        </loading-indicator>
      </section>
      <aside class="column is-narrow events-calendar">
        <loading-indicator :loading="loading">
          <events-calendar
            :events="events"
          />
          <div
            v-if="icsFeed"
            class="ics-buttons"
          >
            <safe-link
              :to="googleICSFeed"
              target="_blank"
              @visited="trackGoogleDownload"
            >
              <b-button
                icon-left="google"
                outlined
                expanded
              >
                Add to Google Calendar
              </b-button>
            </safe-link>
            <safe-link
              :to="icsFeed"
              @visited="trackICSDownload"
            >
              <b-button
                icon-left="calendar-plus"
                outlined
                expanded
              >
                Add to iCloud / Outlook
              </b-button>
            </safe-link>
          </div>
        </loading-indicator>
      </aside>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import { parseDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast } from '@/helpers/notification-helper'

  import EventsCalendar from '@/components/events/events-calendar'
  import EventsListing from '@/components/events/events-listing'

  import SafeLink from '@/components/helpers/safe-link'
  import LoadingIndicator from '@/components/helpers/loading-indicator'

  export default {
    name: 'events',

    components: {
      EventsCalendar,
      EventsListing,
      SafeLink,
      LoadingIndicator,
    },

    data () {
      return {
        loading: true,
        events: [],
      }
    },

    computed: {
      icsFeed () {
        const rootURL = new URL(process.env.VUE_APP_API_URL)?.host

        return rootURL ? `webcal://${ rootURL }/calendar.ics` : undefined
      },

      googleICSFeed () {
        const rootURL = new URL(process.env.VUE_APP_API_URL)?.origin

        return rootURL ? `https://calendar.google.com/calendar/render?cid=${ rootURL }/calendar.ics` : undefined
      },
    },

    async mounted () {
      try {
        const { events } = await ApiService.get('events')

        this.events = events.map((item) => {
          item.detailPage = `/events/${ item.id }`

          return parseDateTimes(item)
        })
      } catch (error) {
        failureToast('There was a problem getting data from the server. Please try later.')
      } finally {
        this.loading = false
      }
    },

    methods: {
      trackGoogleDownload () {
        this.$matomo && this.$matomo.trackEvent('Branch Diary', 'Subscribed', 'Google Calendar')
      },

      trackICSDownload () {
        this.$matomo && this.$matomo.trackEvent('Branch Diary', 'Subscribed', 'iCloud / Outlook')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .events-calendar {
    @include not-on-tablet {
      order: -1;
      width: 20rem;
    }

    @include on-phone {
      margin: 0 auto;
    }
  }

  .ics-buttons {
    margin-top: 1rem;

    .button {
      margin-top: 0.5rem;
    }
  }
</style>
