<template>
  <loading-indicator :loading="loading">
    <section id="page-title">
      <h2>{{ pageHeading }}</h2>
      <div
        v-if="readOnly"
        class="title-right"
      >
        <b-button
          v-if="userCanEditPublication"
          type="is-primary"
          size="is-small"
          outlined
          data-testid="page-title-edit-button"
          @click="edit(publication.id)"
        >
          Edit
        </b-button>
      </div>
    </section>

    <section class="content-body">
      <access-denied v-if="!accessPermitted" />

      <template v-else-if="publicationLoaded">
        <form
          accept-charset="UTF-8"
          data-testid="publication-form"
          @keydown.prevent.enter=""
        >
          <input name="utf8" type="hidden" value="✓">

          <b-field
            label="Name"
            :type="fieldNameType"
            :message="fieldNameMessages"
          >
            <b-input
              v-model.trim="$v.publication.name.$model"
              data-testid="publication-form-name"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field
            label="Slug"
            :type="fieldSlugType"
            :message="fieldSlugMessages"
          >
            <b-input
              v-model.trim="$v.publication.slug.$model"
              data-testid="publication-form-slug"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>
          <p class="help">
            Leave this blank to automatically generate a value (usually the best option).<br>
            <strong>Warning:</strong> changing the slug of an existing publication can cause broken links!
          </p>

          <div class="actions columns">
            <div class="action-buttons column">
              <b-button
                outlined
                @click="confirmDataLoss"
              >
                Cancel
              </b-button>
              <b-button
                type="is-primary"
                :disabled="saving || $v.$invalid"
                :loading="saving"
                @click="saveForm"
              >
                {{ saveButtonText }}
              </b-button>
            </div>
          </div>
        </form>
      </template>

      <template v-else>
        <section class="section no-publication">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>This publication could not be loaded.</p>
          </div>
        </section>
      </template>
    </section>
  </loading-indicator>
</template>

<script>
  import { isEqual } from 'lodash'
  import { helpers, required } from 'vuelidate/lib/validators'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { setTitle } from '@/router'
  import { parseDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast, successToast } from '@/helpers/notification-helper'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'

  const alphaNumHyphen = helpers.regex('alphaNum', /^[-0-9a-z]+$/)

  export default {
    name: 'publication',

    components: {
      LoadingIndicator,
      AccessDenied,
    },

    async beforeRouteUpdate (_to, _from, next) {
      this.loading = true
      this.publication = {}

      await this.loadPublication(this.id)
      next()
    },

    props: {
      id: {
        type: String,
        default: undefined,
      },

      mode: {
        type: String,
        default: 'create',
        validator (value) {
          return ['create', 'edit'].indexOf(value) !== -1
        },
      },
    },

    validations: {
      publication: {
        name: {
          required,
        },

        slug: {
          alphaNumHyphen,
          disallowedSlugs (slug) {
            return !([
              'new',
              'edit',
              'index',
              'delete',
              'session',
              'login',
              'logout',
              'users',
              'admin',
              'stylesheets',
              'assets',
              'javascripts',
              'images',
            ].includes(slug))
          },

          slugNotUUID (slug) {
            // source: https://stackoverflow.com/a/13653180/138594
            const regexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

            return !helpers.req(slug) || !regexp.test(slug)
          },

          async slugTaken (slug) {
            let taken

            if (slug === '') return true
            if (slug === this.originalPublication.slug) return true
            if (!this.$v?.publication?.slug) return true
            if (!this.$v.publication.slug.alphaNumHyphen || !this.$v.publication.slug.disallowedSlugs) return true
            try {
              ({ taken } = await ApiService.get(
                this.publication.id
                  ? `publications/slug_taken/${ slug }/${ this.publication.id }`
                  : `publications/slug_taken/${ slug }`))
            } catch (error) {
              console.error(error)
              taken = true
            }

            // validations return true for a pass, false for a fail
            return !taken
          },
        },
      },
    },

    data () {
      return {
        loading: true,
        saving: false,
        accessPermitted: true,
        publication: {},
        originalPublication: {},
        pageHeading: '',
      }
    },

    computed: {
      publicationLoaded () {
        return this.publication && (this.mode === 'create' || this.publication.id)
      },

      readOnly () {
        return this.mode === 'show'
      },

      userCanEditPublication () {
        return this.$store.getters['user/can']('edit', 'publications')
      },

      saveButtonText () {
        return this.mode === 'create' ? 'Create' : 'Save'
      },

      publicationChanged () {
        return !isEqual(this.publication, this.originalPublication)
      },

      fieldNameType () {
        return this.$v.publication.name.$error ? 'is-danger' : ''
      },

      fieldNameMessages () {
        if (!this.$v.publication.name.$error) return ''

        return this.$v.publication.name.required ? '' : 'The name is required.'
      },

      fieldSlugType () {
        return this.$v.publication.slug.$error ? 'is-danger' : ''
      },

      fieldSlugTaken () {
        return !this.$v.publication.slug.$pending && this.$v.publication.slug.slugTaken
      },

      fieldSlugMessages () {
        if (!this.$v.publication.slug.$error) return ''

        const messages = []

        if (!this.$v.publication.slug.slugNotUUID) {
          messages.push('The slug must not look like an ID.')
        }
        if (!this.$v.publication.slug.disallowedSlugs) {
          messages.push('This word cannot be used as a slug.')
        }
        if (!this.$v.publication.slug.alphaNumHyphen) {
          messages.push('The slug can only contain lower-case letters, numbers, and hyphens.')
        }
        if (!this.fieldSlugTaken) {
          messages.push('This slug is being used for another publication. Please choose another.')
        }

        return messages
      },
    },

    async mounted () {
      await this.loadPublication(this.id)

      EventBus.$on('userDidChange', () => {
        (async () => { await this.loadPublication(this.id) })()
      })
    },

    methods: {
      async loadPublication (id) {
        this.loading = true
        this.publication = {}
        this.accessPermitted = true

        if (this.mode === 'create') {
          this.publication = await this.loadBlankPublication()
          this.pageHeading = 'New Publication'
        } else {
          if (this.mode === 'edit' && !this.$store.getters['user/can']('edit', 'publications')) {
            this.accessPermitted = false
          } else {
            const prefix = this.mode === 'edit' ? 'Edit Publication: ' : ''

            this.publication = await this.loadExistingPublication(id)
            this.pageHeading = this.publicationLoaded ? `${ prefix }${ this.publication.name }` : `${ prefix }Publication`
          }
        }

        if (!this.accessPermitted) {
          this.pageHeading = ''
        }
        this.originalPublication = { ...this.publication }
        setTitle(this.pageHeading)

        this.loading = false
      },

      async loadBlankPublication () {
        try {
          const { publication } = await ApiService.get('publications/new')

          return publication
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      async loadExistingPublication (id) {
        try {
          const { publication } = await ApiService.get(`publications/${ id }`)

          return parseDateTimes(publication)
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else if (error?.response?.status === 422) {
            this.publication = {}
          } else if (error?.response?.status !== 404) {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      confirmDataLoss () {
        if (this.publicationChanged) {
          this.$buefy.dialog.confirm({
            title: 'Cancel Editing',
            message: 'Are you sure you want to cancel? Your changes will be lost.',
            cancelText: 'No',
            confirmText: 'Yes',
            type: 'is-danger',
            hasIcon: true,
            icon: 'alert',
            onConfirm: () => this.allPublications(),
          })
        } else {
          this.allPublications()
        }
      },

      async saveForm () {
        this.$v.$touch()
        if (this.mode === 'show' || this.saving || this.$v.$invalid) return

        try {
          const publication = this.publication

          this.saving = true
          switch (this.mode) {
            case 'create':
              await ApiService.post('publications', { publication })
              break
            case 'edit':
              await ApiService.put(`publications/${ this.id }`, { publication })
              break
            default:
              console.error(`saveForm called with unsupported mode ${ this.mode }`)

              return
          }

          successToast('Publication saved')

          this.allPublications()
        } catch (error) {
          let formattedErrors

          switch (error?.response?.status) {
            case 422:
              formattedErrors = error?.response?.data?.errors?.join('</li><li>')

              this.$buefy.dialog.alert({
                title: 'Could not save publication',
                message: `The server reported the following errors: <ul class="rails-errors"><li>${ formattedErrors }</li></ul>`,
                type: 'is-danger',
                hasIcon: true,
                icon: 'emoticon-sad',
              })
              break
            case 401:
              failureToast('You do not have permission to do this')
              break
            default:
              console.error(error)
              failureToast('There was a problem communicating with the server; please try later')
              break
          }
        } finally {
          this.saving = false
        }
      },

      edit (id) {
        this.$router.push(`/publications/${ id }/edit`)
      },

      allPublications () {
        this.$router.push('/publications')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  form {
    margin: 0 auto;
    max-width: $form-width;
  }

  .help {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .actions {
    align-items: center;

    .action-buttons {
      text-align: right;

      .button {
        @include not-on-phone {
          &:not(:first-child) {
            margin-left: 0.75rem;
          }
        }

        @include on-phone {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>

<style lang="scss">
  // Buefy dialogs can't be styled from the calling component, as they are created on the <body>, so this rule must be global
  // Can be replaced by a ::v-global block after moving to Vue 3
  .dialog {
    ul {
      &.rails-errors {
        list-style-type: circle;

        li {
          padding-left: 0;
        }
      }
    }
  }
</style>
