<template>
  <span v-if="noTo">
    <slot />
  </span>

  <router-link
    v-else-if="useRouter"
    :to="to"
    @click="$emit('visited')"
  >
    <slot />
  </router-link>

  <a v-else
     :rel="rel"
     :href="to"
     :target="target"
     @click="$emit('visited')"
  >
    <slot />
  </a>
</template>

<script>
  export default {
    name: 'safe-link',

    props: {
      to: {
        type: String,
        default: undefined,
      },

      target: {
        type: String,
        required: false,
        default: null,
      },

      allowReferrer: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      noTo () {
        return !this.to
      },

      rel () {
        const localRel = null
        const nonLocalRel = this.allowReferrer ? 'noopener' : 'nofollow noopener noreferrer'

        if (this.to) {
          const localDomains = [
            'westernsussexcamra.org.uk',
            'www.westernsussexcamra.org.uk',
            'wscamra-staging.omgponies.org.uk',
          ]
          const url = new URL(this.to, window.location)

          if (url.host === location.host) return localRel

          return localDomains.indexOf(url.host) === -1 ? nonLocalRel : localRel
        }

        return nonLocalRel
      },

      useRouter () {
        return !!this.to?.startsWith('/')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
