const validateUrl = (value, options = {}) => {
  const { http = false, https = false } = options

  if (value === '' || value === null || value === undefined) {
    return true // use the 'required' validator to disallow empty values
  }

  try {
    const url = new URL(value)
    const protocolValid = (http && url.protocol === 'http:') || (https && url.protocol === 'https:')
    let hostnameValid = !url.hostname.endsWith('.') && !!url.hostname.match(/\./)

    if (window.Cypress && (url.hostname === 'localhost' || url.hostname === 'frontend' || url.hostname === 'backend')) {
      hostnameValid = true // HACK: Needed for issue form / when viewing / redirects to issue URL, as cannot be cross-origin in Cypress
    }

    return protocolValid && hostnameValid
  } catch (error) {
    return false
  }
}

export const urlHttpOrHttps = (value) => validateUrl(value, { http: true, https: true })
export const urlHttpsOnly = (value) => validateUrl(value, { http: false, https: true })

export const urlWithoutUsername = (value) => {
  if (value === '' || value === null || value === undefined) {
    return true // use the 'required' validator to disallow empty values
  }

  try {
    const url = new URL(value)

    return url.username === '' && url.password === ''
  } catch (error) {
    return true // don't trigger "no username" errors if the URL is unparseable
  }
}
