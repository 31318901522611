<template>
  <front-page-card title="Yapton Beerex"
                   :more-link="linkDestination"
                   more-text-screenreader="More information about Yapton Beerex…"
                   card="yapton-beerex"
                   :larger="larger"
  >
    <div
      v-if="loading"
      class="loading"
    >
      <div>
        <b-skeleton />
      </div>
      <div>
        <b-skeleton />
      </div>
    </div>
    <div
      v-else-if="detailsAvailable"
      class="details"
      data-testid="yapton-beerex-info"
    >
      <date-time-range :starts-at="startsAt" :ends-at="endsAt" />
      <br v-if="startsAt && location">
      <span v-if="location">{{ location }}</span>
      <div v-if="ticketSalesLive" class="mt-3">
        <buy-tickets-button
          small
          :url="ticketSalesUrl"
          data-testid="yapton-buy-tickets"
        />
      </div>
    </div>
    <p
      v-else
      data-testid="yapton-beerex-info"
    >
      No details available.
    </p>
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'
  import DateTimeRange from '@/components/helpers/date-time-range'
  import BuyTicketsButton from '@/components/helpers/buy-tickets-button.vue'

  export default {
    name: 'yapton-beerex-card',

    components: {
      DateTimeRange,
      FrontPageCard,
      BuyTicketsButton,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      startsAt: {
        type: Date,
        default: undefined,
      },

      endsAt: {
        type: Date,
        default: undefined,
      },

      location: {
        type: String,
        default: undefined,
      },

      page: {
        type: String,
        default: undefined,
      },

      ticketSalesUrl: {
        type: String,
        default: undefined,
      },

      ticketSalesStartsAt: {
        type: Date,
        default: undefined,
      },

      ticketSalesEndsAt: {
        type: Date,
        default: undefined,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      detailsAvailable () {
        return this.location || (this.startsAt && this.endsAt && this.startsAt <= this.endsAt)
      },

      linkDestination () {
        return this.detailsAvailable ? this.page : null
      },

      ticketSalesLive () {
        const now = new Date()

        return this.ticketSalesUrl && this.ticketSalesStartsAt <= now && this.ticketSalesEndsAt >= now
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .loading {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include not-on-phone {
      align-items: center;
    }

    > div {
      &:first-child {
        width: 50%;
      }

      &:nth-child(2) {
        width: 80%;
      }
    }
  }

  .details {
    @include not-on-phone {
      text-align: center;
    }
  }
</style>
