<template>
  <front-page-card title="Branch Diary"
                   :more-link="moreLink"
                   more-text="More events…"
                   more-text-screenreader="More branch events…"
                   card="branch-diary"
                   :larger="larger"
                   :class="{ 'has-events': hasEvents }"
  >
    <ul
      v-if="loading"
      class="loading"
    >
      <li v-for="item in [1, 2, 3]" :key="item">
        <div class="event-date-label">
          <div class="event-date">
            <b-skeleton />
          </div>
        </div>
        <div class="event-summary">
          <b-skeleton />
        </div>
      </li>
    </ul>
    <ul
      v-else-if="events.length"
      data-testid="branch-diary-info"
    >
      <li
        v-for="event in firstThreeEvents"
        :key="event.key"
      >
        <div class="event-date-label">
          <div class="event-date">
            {{ event.formattedStartsAt }}
          </div>
          <div class="event-label">
            <event-label :colour="event.labelColour">
              {{ event.label }}
            </event-label>
          </div>
        </div>
        <div
          v-snip="1"
          class="event-summary"
        >
          <safe-link :to="event.link">
            {{ event.summary }}
          </safe-link>
        </div>
      </li>
    </ul>
    <p
      v-else
      data-testid="branch-diary-no-events"
    >
      No upcoming events.
    </p>
  </front-page-card>
</template>

<script>
  import { dateFormats } from '@/objects/date-formats'
  import { format as formatDate } from 'date-fns'

  import EventLabel from '@/components/helpers/event-label'
  import SafeLink from '@/components/helpers/safe-link'
  import FrontPageCard from '@/components/front-page/front-page-card'

  export default {
    name: 'branch-diary-card',

    components: {
      EventLabel,
      SafeLink,
      FrontPageCard,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      loading: {
        type: Boolean,
        default: false,
      },

      events: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      hasEvents () {
        return !!this.events.length
      },

      moreLink () {
        return this.hasEvents ? '/events' : undefined
      },

      firstThreeEvents () {
        return this
          .events
          .slice(0, 3)
          .map((event) => {
            const formattedDateOfEvent = formatDate(new Date(event.dateOfEvent), dateFormats.cardDate)

            if (event.allDay) {
              event.formattedStartsAt = formattedDateOfEvent
            } else {
              const startsAtTime = formatDate(event.startsAt, dateFormats.time)

              event.formattedStartsAt = `${ formattedDateOfEvent } ${ startsAtTime }`
            }
            event.link = `/events/${ event.id }`

            event.key = `${ event.id }#${ event.dateOfEvent }`

            return event
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .event-date-label {
    display: flex;
    justify-content: space-between;
  }

  .event-date {
    font-weight: $weight-bold;
  }

  ul {
    margin-left: inherit;
    width: 100%;

    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      @include on-phone {
        padding-left: 0;
      }
    }

    &.loading {
      .event-date {
        width: 40%;
      }

      .event-label {
        width: 20%;
      }

      .event-summary {
        width: 70%;
      }
    }
  }

  // combinator use is deprecated in Vue 3, but still seems to be required in Vue 2
  ::v-deep {
    .tag {
      margin-right: 0.25rem;
      padding: 0.2rem 0.35rem;
      height: 1.2rem;
      font-size: 85%;
      font-weight: bold;
    }
  }

  .has-events {
    ::v-deep {
      .card-centre {
        justify-content: flex-start;
      }
    }
  }
</style>
