<template>
  <front-page-card title="National Campaigns"
                   more-link="http://www.camra.org.uk/campaigns/"
                   more-text-screenreader="More information about CAMRA"
                   card="campaigns"
                   :larger="larger"
  >
    CAMRA's current work at a national level.
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'

  export default {
    name: 'national-campaigns-card',

    components: {
      FrontPageCard,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
