<template>
  <div
    v-if="events.length === 0"
    class="content has-text-grey has-text-centered no-events"
  >
    <p>
      <b-icon icon="emoticon-sad" size="is-large" />
    </p>
    <p>No upcoming events.</p>
  </div>

  <div v-else>
    <section
      v-for="event in events"
      :key="event.id"
      class="event"
    >
      <div class="columns is-mobile">
        <div class="column is-narrow">
          <event-date
            :starts-at="event.startsAt"
            :ends-at="event.endsAt"
            :all-day="event.allDay"
          />
        </div>

        <div class="column">
          <div class="event-details">
            <div>
              <div class="columns">
                <aside
                  v-if="event.label"
                  class="event-label column is-narrow"
                >
                  <event-label
                    :colour="event.colour"
                  >
                    {{ event.label }}
                  </event-label>
                </aside>
                <div class="event-name column">
                  <h4 v-snip="1">
                    <safe-link :to="event.detailPage">
                      {{ event.summary }}
                    </safe-link>
                  </h4>
                </div>
              </div>

              <div v-if="event.location" class="event-location">
                {{ event.location }}
              </div>

              <div class="event-description">
                {{ event.description }}
              </div>
            </div>

            <div class="event-link">
              <safe-link :to="event.detailPage">
                <b-button
                  type="is-primary"
                  size="is-small"
                  outlined
                >
                  Details / Map
                </b-button>
              </safe-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import EventDate from '@/components/helpers/event-date'
  import EventLabel from '@/components/helpers/event-label'
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'events-listing',

    components: {
      EventDate,
      EventLabel,
      SafeLink,
    },

    props: {
      events: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .no-events {
    margin-top: 8rem;
  }

  .event {
    margin-bottom: 0.75rem;
    background-color: $white-ter;
    padding: 0.75rem;
  }

  .event-label {
    padding: 0 0.75rem 0 0;
    font-size: $size-4;
  }

  .event-name {
    padding-left: 0.25rem;

    @include on-phone {
      padding-top: 0.25rem;
      padding-left: 0;
    }

    ::v-deep {
      .tag {
        margin-right: 0.25rem;
      }
    }

    h4 {
      line-height: 1.2;
      font-size: 1.1rem;
    }

    a {
      color: $body-color;

      &:hover {
        text-decoration: underline;
        color: $hover-color;
      }
    }
  }

  .event-location {
    margin-bottom: 0.25rem;
    font-size: 0.75rem;

    @include on-phone {
      font-size: 0.85rem;
    }
  }

  .event-details {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .columns {
      margin-bottom: 0;
      margin-left: 0;

      .column {
        padding-bottom: 0;
      }
    }
  }

  .event-link {
    align-self: flex-end;
    margin-top: 0.5rem;
  }
</style>
