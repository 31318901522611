const state = {
  beerFestivalsPage: undefined,
  calendarFeedsPage: undefined,
  issuesPage: undefined,
  pagesPage: undefined,
  publicationsPage: undefined,
}

const getters = {
  beerFestivalsPage: (state) => state.beerFestivalsPage,
  calendarFeedsPage: (state) => state.calendarFeedsPage,
  issuesPage: (state) => state.issuesPage,
  pagesPage: (state) => state.pagesPage,
  publicationsPage: (state) => state.publicationsPage,
}

const actions = {
  setBeerFestivalsPage ({ commit }, payload) {
    commit('BEER_FESTIVALS_PAGE', payload)
  },

  clearBeerFestivalsPage ({ commit }) {
    commit('BEER_FESTIVALS_PAGE', { page: undefined })
  },

  setCalendarFeedsPage ({ commit }, payload) {
    commit('CALENDAR_FEEDS_PAGE', payload)
  },

  clearCalendarFeedsPage ({ commit }) {
    commit('CALENDAR_FEEDS_PAGE', { page: undefined })
  },

  setIssuesPage ({ commit }, payload) {
    commit('ISSUES_PAGE', payload)
  },

  clearIssuesPage ({ commit }) {
    commit('ISSUES_PAGE', { page: undefined })
  },

  setPagesPage ({ commit }, payload) {
    commit('PAGES_PAGE', payload)
  },

  clearPagesPage ({ commit }) {
    commit('PAGES_PAGE', { page: undefined })
  },

  setPublicationsPage ({ commit }, payload) {
    commit('PUBLICATIONS_PAGE', payload)
  },

  clearPublicationsPage ({ commit }) {
    commit('PUBLICATIONS_PAGE', { page: undefined })
  },
}

const mutations = {
  BEER_FESTIVALS_PAGE (state, payload) {
    state.beerFestivalsPage = payload.page
  },
  CALENDAR_FEEDS_PAGE (state, payload) {
    state.calendarFeedsPage = payload.page
  },
  ISSUES_PAGE (state, payload) {
    state.issuesPage = payload.page
  },
  PAGES_PAGE (state, payload) {
    state.pagesPage = payload.page
  },
  PUBLICATIONS_PAGE (state, payload) {
    state.publicationsPage = payload.page
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
