<template>
  <front-page-card :title="displayTitle"
                   :more-link="displayUrl"
                   more-text-screenreader="More information on CAMRA national festivals"
                   card="national-festivals"
                   :larger="larger"
  >
    <div
      v-if="loading"
      class="loading"
    >
      <div>
        <b-skeleton />
      </div>
      <div>
        <b-skeleton />
      </div>
    </div>
    <template v-else>
      <div class="is-hidden-mobile is-block-tablet">
        <ul
          v-if="showBody"
          class="is-hidden-mobile is-block-tablet has-text-centered"
          data-testid="national-festivals-info"
        >
          <li v-if="showDates">
            <date-time-range :starts-at="startsAt" :ends-at="endsAt" />
          </li>
          <li v-if="showLocation">
            {{ location }}
          </li>
        </ul>
        <p
          v-if="!showBody"
          data-testid="national-festivals-info"
        >
          No details available.
        </p>
      </div>

      <p class="is-block-mobile is-hidden-tablet">
        <span v-if="showBody">
          <template v-if="showDates">
            <date-time-range :starts-at="startsAt" :ends-at="endsAt" />
          </template><template v-if="showBody">: </template><template v-if="showLocation">{{ location }}</template>
        </span>
        <span v-else>No details available.</span>
      </p>
    </template>
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'
  import DateTimeRange from '@/components/helpers/date-time-range'

  export default {
    name: 'national-festivals-card',

    components: {
      FrontPageCard,
      DateTimeRange,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      name: {
        type: String,
        default: undefined,
      },

      startsAt: {
        type: Date,
        default: undefined,
      },

      endsAt: {
        type: Date,
        default: undefined,
      },

      location: {
        type: String,
        default: undefined,
      },

      url: {
        type: String,
        default: undefined,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      showDates () {
        return this.startsAt && this.endsAt && this.startsAt <= this.endsAt
      },

      showLocation () {
        return !!this.location
      },

      showBody () {
        return this.name && (this.showDates || this.showLocation)
      },

      displayTitle () {
        return this.name || 'CAMRA National Festivals'
      },

      displayUrl () {
        return this.name ? this.url : ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .loading {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include not-on-phone {
      align-items: center;
    }

    > div {
      &:first-child {
        width: 40%;
      }

      &:nth-child(2) {
        width: 70%;
      }
    }
  }

  ul {
    margin-left: 0;
  }

  li {
    padding-left: 0;
  }
</style>
