<template>
  <front-page-card title="WhatPub"
                   more-text="Search WhatPub"
                   more-link="https://whatpub.com/"
                   card="whatpub"
                   :larger="larger"
  >
    All you need to know about our pubs (and others!)
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'

  export default {
    name: 'whatpub-card',

    components: {
      FrontPageCard,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
