<template>
  <loading-indicator :loading="loading">
    <section id="page-title">
      <h2>Reset Password</h2>
    </section>
    <template v-if="status === 'showPasswordPrompt'">
      <form
        accept-charset="UTF-8"
        data-testid="password-reset-form"
        @keydown.prevent.enter=""
      >
        <input name="utf8" type="hidden" value="✓">

        <b-field
          label="New Password"
          :type="fieldPasswordType"
          :message="fieldPasswordMessages"
        >
          <b-input
            v-model.trim="$v.password.$model"
            type="password"
            password-reveal
            required
            data-testid="password"
            autocomplete="new-password"
            @keyup.native.enter="resetPassword"
          />
        </b-field>

        <b-field
          label="Confirm Password"
          :type="fieldPasswordConfirmType"
          :message="fieldPasswordConfirmMessages"
        >
          <b-input
            v-model.trim="$v.passwordConfirm.$model"
            type="password"
            password-reveal
            required
            data-testid="password-confirm"
            autocomplete="new-password"
            @keyup.native.enter="resetPassword"
          />
        </b-field>

        <div class="action-buttons">
          <b-button
            type="is-primary"
            :disabled="resettingPassword || $v.$invalid"
            :loading="resettingPassword"
            data-testid="reset-password-button"
            @click="resetPassword"
          >
            Reset password
          </b-button>
        </div>
      </form>
    </template>
    <template v-else-if="status === 'passwordChanged'">
      <b-message
        type="is-success"
        has-icon
        icon="account-check"
      >
        Your password has been successfully reset. You can now log in using the “Admin Login” button below.
      </b-message>
    </template>
    <template v-else-if="status === 'accountNotConfirmed'">
      <b-message
        type="is-danger"
        has-icon
        icon="alert-octagon"
      >
        <p>
          The email address on your account has not been confirmed. Please click the link in the confirmation email, then request
          another password reset.
        </p>
        <p>
          <safe-link to="/users/confirm-email/resend">Resend confirmation link</safe-link>
        </p>
      </b-message>
    </template>
    <template v-else-if="status === 'tokenInvalid'">
      <b-message
        type="is-danger"
        has-icon
        icon="alert-octagon"
      >
        The link you have used is invalid or has expired. Please request another password reset using the “Log in” button below.
      </b-message>
    </template>
    <template v-else>
      <b-message
        type="is-danger"
        has-icon
        icon="alert-octagon"
      >
        <template v-if="serverErrors.length">
          <p>
            Your password could not be reset due to the following errors:
          </p>
          <ul>
            <li
              v-for="(error, index) in serverErrors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
        </template>
        <p v-else>
          Your password could not be reset due to an unknown server error. Please try again in a few minutes.
        </p>
      </b-message>
    </template>
  </loading-indicator>
</template>

<script>
  import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
  import { forIn } from 'lodash'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'reset-password',

    components: {
      LoadingIndicator,
      SafeLink,
    },

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    validations: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(128),
      },

      passwordConfirm: {
        sameAsPassword: sameAs('password'),
      },
    },

    data () {
      return {
        loading: true,
        resettingPassword: false,
        // status: showPasswordPrompt -> (passwordChanged | accountNotConfirmed | tokenInvalid | serverErrors)
        status: 'showPasswordPrompt',
        password: '',
        passwordConfirm: '',
        serverErrors: [],
      }
    },

    computed: {
      fieldPasswordType () {
        return this.$v.password.$error ? 'is-danger' : ''
      },

      fieldPasswordMessages () {
        if (!this.$v.password.$error) return ''

        const messages = []

        if (!this.$v.password.required) {
          messages.push('Please enter a password.')
        }
        if (!this.$v.password.minLength || !this.$v.password.maxLength) {
          messages.push('The password must be between 8 and 128 characters long.')
        }

        return messages
      },

      fieldPasswordConfirmType () {
        return this.$v.passwordConfirm.$error ? 'is-danger' : ''
      },

      fieldPasswordConfirmMessages () {
        if (!this.$v.passwordConfirm.$error) return ''

        return this.$v.passwordConfirm.sameAsPassword ? '' : 'The password confirmation must match the password.'
      },
    },

    async mounted () {
      this.loading = false

      EventBus.$on('userDidChange', () => {
        this.$router.push('/')
      })
    },

    methods: {
      async resetPassword () {
        if (this.resettingPassword || this.$v.$invalid) return // don't double-submit
        this.resettingPassword = true

        try {
          await ApiService.put('users/password', {
            user: {
              reset_password_token: this.token,
              password: this.password,
              password_confirmation: this.passwordConfirm,
            },
          })

          this.status = 'passwordChanged'
        } catch (error) {
          if (error?.response?.status === 401) {
            // Corner case: this will also occur if an account is deleted by an admin between the reset being requested and the link being clicked.
            // This is not all that likely to happen.
            this.status = 'accountNotConfirmed'
          } else {
            const errors = error?.response?.data?.errors || {}
            const serverErrors = []

            this.status = 'serverErrors'
            forIn(errors, (messages, field) => {
              messages.forEach((message) => {
                const error = `${ field.split('_').join(' ') } ${ message }`

                if (error === 'reset password token is invalid') {
                  this.status = 'tokenInvalid'
                }
                serverErrors.push(error)
              })
            })
            this.serverErrors = serverErrors.map((s) => `${ s.substr(0,1).toUpperCase() }${ s.slice(1) }`)
          }
        } finally {
          this.resetComplete = true
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  form {
    margin: 0 auto;
    max-width: 30rem;
  }

  .action-buttons {
    margin-top: 1rem;
    text-align: right;

    .button {
      @include not-on-phone {
        &:not(:first-child) {
          margin-left: 0.75rem;
        }
      }

      @include on-phone {
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
</style>
