<template>
  <span v-if="datesValid">
    <span v-if="sameDay">
      <date-time :datetime="startsAt" :show-time="showTimes">{{ startDate }} {{ timeRange }}</date-time>
    </span>
    <span v-else>
      <date-time :datetime="startsAt" :show-time="showTimes">{{ startDate }} {{ startTime }}</date-time> –
      <date-time :datetime="endsAt" :show-time="showTimes">{{ endDate }} {{ endTime }}</date-time>
    </span>
  </span>
</template>

<script>
  // TODO: improve output when showTimes is set, startsAt is a different day to endsAt, and neither is in the current year
  import { isSameDay, isSameYear, isThisYear, format as formatDate } from 'date-fns'
  import DateTime from '@/components/helpers/date-time'

  export default {
    name: 'date-time-range',

    components: {
      DateTime,
    },

    props: {
      startsAt: {
        type: Date,
        default: undefined,
      },

      endsAt: {
        type: Date,
        default: undefined,
      },

      showTimes: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      datesValid () {
        return this.startsAt && this.endsAt && this.startsAt <= this.endsAt
      },

      sameDay () {
        return isSameDay(this.startsAt, this.endsAt)
      },

      startsThisYear () {
        return isThisYear(this.startsAt)
      },

      startDate () {
        if (this.startsThisYear || (!this.sameDay && isSameYear(this.startsAt, this.endsAt))) {
          return formatDate(this.startsAt, 'MMM d')
        } else {
          return formatDate(this.startsAt, 'MMM d, yyyy')
        }
      },

      startTime () {
        return this.showTimes ? formatDate(this.startsAt, 'HH:mm') : ''
      },

      endDate () {
        if (this.startsThisYear) {
          return formatDate(this.endsAt, 'MMM d')
        } else {
          return formatDate(this.endsAt, 'MMM d, yyyy')
        }
      },

      endTime () {
        return this.showTimes ? formatDate(this.endsAt, 'HH:mm') : ''
      },

      timeRange () {
        return this.showTimes ? `${ this.startTime } – ${ this.endTime }` : ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
