<template>
  <b-navbar
    class="site-nav"
    role="menubar"
  >
    <template #brand>
      <template v-if="!loading">
        <b-navbar-item
          tag="router-link"
          to="/"
          aria-label="Home"
          data-testid="navbar-home"
        >
          <b-icon icon="home" />
        </b-navbar-item>
      </template>
    </template>
    <template #start>
      <template v-if="!loading">
        <b-navbar-item
          tag="router-link"
          to="/events"
          role="menuitem"
          data-testid="navbar-diary"
        >
          Branch Diary
        </b-navbar-item>

        <b-navbar-item
          v-if="paths.sussexDrinkerPath"
          tag="router-link"
          :to="paths.sussexDrinkerPath"
          role="menuitem"
          data-testid="navbar-sussex-drinker"
        >
          Sussex Drinker
        </b-navbar-item>

        <b-navbar-dropdown
          v-if="paths.yaptonBeerexPath || paths.yaptonProgrammePath"
          label="Yapton Beerex"
          role="menu"
          data-testid="navbar-yapton-beerex"
        >
          <b-navbar-item
            v-if="paths.yaptonBeerexPath"
            tag="router-link"
            :to="paths.yaptonBeerexPath"
            role="menuitem"
            data-testid="navbar-yapton-beerex-details"
          >
            Details
          </b-navbar-item>
          <b-navbar-item
            v-if="paths.yaptonProgrammePath"
            tag="router-link"
            :to="paths.yaptonProgrammePath"
            role="menuitem"
            data-testid="navbar-yapton-beerex-programmes"
          >
            Programmes
          </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-item
          tag="router-link"
          to="/beer-festivals"
          role="menuitem"
          data-testid="navbar-local-festivals"
        >
          Local Beer Festivals
        </b-navbar-item>

        <b-navbar-item
          v-if="paths.localePath"
          tag="router-link"
          :to="paths.localePath"
          role="menuitem"
          data-testid="navbar-locale"
        >
          LocAle
        </b-navbar-item>

        <b-navbar-item
          v-if="paths.potyPath"
          tag="router-link"
          :to="paths.potyPath"
          role="menuitem"
          data-testid="navbar-poty"
        >
          Pub of the Year
        </b-navbar-item>

        <b-navbar-item
          v-if="paths.contactPath"
          tag="router-link"
          :to="paths.contactPath"
          role="menuitem"
          data-testid="navbar-contact"
        >
          About Us
        </b-navbar-item>
      </template>
    </template>

    <template #end>
      <b-navbar-dropdown
        v-if="userIsLoggedIn && !loading"
        label="Admin"
        role="menu"
        data-testid="navbar-admin-menu"
      >
        <b-navbar-item
          tag="router-link"
          to="/beer-festivals"
          role="menuitem"
          data-testid="navbar-admin-beer-festivals"
        >
          Beer Festivals
        </b-navbar-item>

        <b-navbar-item
          tag="router-link"
          to="/events"
          role="menuitem"
          data-testid="navbar-admin-diary"
        >
          Branch Diary
        </b-navbar-item>

        <b-navbar-item
          v-if="userCanAdminCalendarFeeds"
          tag="router-link"
          to="/calendar-feeds"
          role="menuitem"
          data-testid="navbar-admin-calendar-feeds"
        >
          Calendar Feeds
        </b-navbar-item>

        <b-navbar-item
          v-if="userCanAdminPages"
          tag="router-link"
          to="/pages"
          role="menuitem"
          data-testid="navbar-admin-pages"
        >
          Pages
        </b-navbar-item>

        <b-navbar-item
          v-if="userCanAdminPublications"
          tag="router-link"
          to="/publications"
          role="menuitem"
          data-testid="navbar-admin-publications"
        >
          Publications
        </b-navbar-item>

        <b-navbar-item
          v-if="userCanAdminSettings"
          tag="router-link"
          to="/settings"
          role="menuitem"
          data-testid="navbar-admin-settings"
        >
          Site Settings
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'

  export default {
    name: 'site-navbar',

    async beforeRouteUpdate (_to, _from, next) {
      await this.loadPages()
      next()
    },

    data () {
      return {
        paths: {},
        loading: true,
      }
    },

    computed: {
      userIsLoggedIn () {
        return !this.$store.getters['user/isAnonymous']
      },

      userCanAdminCalendarFeeds () {
        return this.$store.getters['user/can']('list', 'calendarFeeds')
      },

      userCanAdminPages () {
        return this.$store.getters['user/can']('list', 'pages')
      },

      userCanAdminPublications () {
        return this.$store.getters['user/can']('list', 'publications')
      },

      userCanAdminSettings () {
        return this.$store.getters['user/can']('list', 'settings')
      },
    },

    async mounted () {
      EventBus.$on('settingsDidChange', () => {
        this.loadPages()
      })
      await this.loadPages()
    },

    methods: {
      async loadPages () {
        let pageUrls

        this.loading = true

        try {
          ({ pageUrls = {} } = await ApiService.get('site/page_urls'))
        } catch (error) {
          pageUrls = {}
          console.error(error)
        } finally {
          this.paths = pageUrls
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .site-nav {
    position: sticky;
    top: 0;
    border-bottom: 1px solid $grey-lightest;
    font-size: 90%;
    font-weight: $weight-bold;
  }

  ::v-deep {
    .navbar-end {
      .navbar-dropdown {
        right: 0;
        left: auto;
      }
    }
  }
</style>
