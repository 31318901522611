<template>
  <div id="front-page" class="columns is-multiline">
    <div class="column is-one-third">
      <branch-diary-card
        larger
        :events="events"
        :loading="loading"
      />
    </div>

    <div class="column is-one-third show-first-non-mobile">
      <sussex-drinker-card
        larger
        :issue-number="sussexDrinker.issueNumber"
        :issue-name="sussexDrinker.name"
        :pdf-url="sussexDrinker.pdfUrl"
        :thumbnails="sussexDrinker.thumbnails"
        :loading="loading"
      />
    </div>

    <div class="column is-one-third">
      <yapton-beerex-card
        larger
        :location="yaptonBeerex.location"
        :page="yaptonBeerex.page"
        :starts-at="yaptonBeerex.startsAt"
        :ends-at="yaptonBeerex.endsAt"
        :ticket-sales-url="yaptonBeerex.ticketSalesUrl"
        :ticket-sales-starts-at="yaptonBeerex.ticketSalesStartsAt"
        :ticket-sales-ends-at="yaptonBeerex.ticketSalesEndsAt"
        :loading="loading"
      />
    </div>

    <div class="column is-one-third">
      <about-us-card
        :page="contactUs.page"
      />
    </div>

    <div class="column is-one-third">
      <locale-card
        :page="locAle.page"
      />
    </div>

    <div class="column is-one-third">
      <poty-card
        :name="poty.name"
        :location="poty.location"
        :page="poty.page"
        :loading="loading"
      />
    </div>

    <div class="column is-one-third">
      <national-campaigns-card />
    </div>

    <div class="column is-one-third">
      <local-festivals-card
        :festivals="localFestivals"
        :loading="loading"
      />
    </div>

    <div class="column is-one-third">
      <whatpub-card />
    </div>

    <div class="column is-one-third">
      <local-branches-card
        :page="localBranches.page"
      />
    </div>

    <div class="column is-one-third">
      <national-festivals-card
        :name="nationalFestivals.name"
        :url="nationalFestivals.url"
        :starts-at="nationalFestivals.startsAt"
        :ends-at="nationalFestivals.endsAt"
        :location="nationalFestivals.location"
        :loading="loading"
      />
    </div>

    <div class="column is-one-third">
      <links-card
        :page="links.page"
      />
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import { parseDateTimes, parseDates, parseTicketSalesDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast } from '@/helpers/notification-helper'

  import BranchDiaryCard from '@/components/front-page/branch-diary-card'
  import SussexDrinkerCard from '@/components/front-page/sussex-drinker-card'
  import YaptonBeerexCard from '@/components/front-page/yapton-beerex-card'
  import AboutUsCard from '@/components/front-page/about-us-card'
  import LocaleCard from '@/components/front-page/locale-card'
  import PotyCard from '@/components/front-page/poty-card'
  import NationalCampaignsCard from '@/components/front-page/national-campaigns-card'
  import LocalFestivalsCard from '@/components/front-page/local-festivals-card'
  import WhatpubCard from '@/components/front-page/whatpub-card'
  import LocalBranchesCard from '@/components/front-page/local-branches-card'
  import NationalFestivalsCard from '@/components/front-page/national-festivals-card'
  import LinksCard from '@/components/front-page/links-card'

  export default {
    name: 'front-page',

    components: {
      BranchDiaryCard,
      SussexDrinkerCard,
      YaptonBeerexCard,
      AboutUsCard,
      LocaleCard,
      PotyCard,
      NationalCampaignsCard,
      LocalFestivalsCard,
      WhatpubCard,
      LocalBranchesCard,
      NationalFestivalsCard,
      LinksCard,
    },

    data () {
      return {
        loading: true,
        events: [],
        sussexDrinker: {},
        yaptonBeerex: {},
        poty: {},
        localFestivals: [],
        nationalFestivals: {},
        localBranches: {},
        links: {},
        locAle: {},
        contactUs: {},
      }
    },

    async mounted () {
      try {
        const {
          events,
          sussexDrinker,
          yaptonBeerex,
          poty,
          localFestivals,
          nationalFestivals,
          localBranches,
          links,
          locale,
          contactUs,
        } = await ApiService.get('site/front_page')

        this.events = events.map((item) => parseDateTimes(item))

        this.sussexDrinker = sussexDrinker

        this.yaptonBeerex = parseTicketSalesDateTimes(parseDates(yaptonBeerex))

        this.poty = poty

        this.localFestivals = localFestivals.map((item) => parseDates(item))

        this.nationalFestivals = parseDates(nationalFestivals)

        this.localBranches = localBranches

        this.links = links

        this.locAle = locale

        this.contactUs = contactUs
      } catch (error) {
        failureToast('There was a problem getting data from the server. Please try later.')
      } finally {
        this.loading = false
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
  $phone-card-odd-background-colour: #e4e4e4;
  $phone-card-even-background-colour: #eee;

  // combinator use is deprecated in Vue 3, but still seems to be required in Vue 2
  ::v-deep {
    .b-skeleton {
      opacity: 0.3;

      @include on-phone {
        opacity: 0.7;
      }
    }
  }

  #front-page {
    // Override bulma's -0.75rem here, which is too tight on non-mobile and causes overlap on mobile
    margin-top: 0;

    // On non-mobile devices, show the Sussex Drinker card on the left; small screens get the diary first.
    @include not-on-phone {
      .column {
        &.show-first-non-mobile {
          order: -1;
        }
      }
    }

    // On mobile devices, show one card per line, with alternating backgrounds. Hide the borders.
    @include on-phone {
      > .column {
        padding: 0;

        &:nth-of-type(odd) {
          background-color: $phone-card-odd-background-colour;
        }

        &:nth-of-type(even) {
          background-color: $phone-card-even-background-colour;
        }
      }
    }
  }
</style>
