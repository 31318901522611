import axios from 'axios'
import { nullUser } from '@/objects/null-user'

const apiUrlBase = process.env.VUE_APP_API_URL

class AuthService {
  login (user, store) {
    return axios.post(`${ apiUrlBase }/users/login`, {
      user: {
        login: user.login,
        password: user.password,
      },
    }).then((response) => {
      if (response.data.token) {
        // Just store the token; don't give the user a chance to edit their userLevel in localStorage
        localStorage.setItem('token', response.data.token)
        store.dispatch('user/logIn', response.data)
      }

      return response.data
    }).catch((error) => {
      localStorage.setItem('token', null)
      store.dispatch('user/logOut')

      if (error.response.status !== 401) {
        throw error
      }

      // handle unauthorised
      return { token: null, user: nullUser }
    })
  }

  loginFromToken (token, store) {
    axios({
      method: 'get',
      url: `${ apiUrlBase }/users/current`,
      headers: {
        'Authorization': `Bearer ${ token }`,
      },
    }).then((response) => {
      if (response.data.user) {
        localStorage.setItem('token', token)
        store.dispatch('user/logIn', {
          user: response.data.user,
          token,
        })
      }
    }).catch((error) => {
      console.error(error)
      if (error.response.status !== 401) {
        throw error
      }

      // clear the token from localStorage as it's invalid
      localStorage.removeItem('token')
    })
  }

  logout (store) {
    const token = store.state.user.jwtToken

    if (!token) {
      return
    }

    axios.delete(`${ apiUrlBase }/users/logout`, {
      headers: {
        Authorization: `Bearer ${ token }`,
      },
    }).then((_response) => {
      store.dispatch('user/logOut')
      localStorage.removeItem('token')
    }).catch((error) => {
      console.error(error)
      if (error.response.status !== 401) {
        throw error
      }

      // clear stored tokens as it's already unauthorised in the backend
      store.dispatch('user/logOut')
      localStorage.removeItem('token')
    })
  }
}

export default new AuthService()
