<template>
  <loading-indicator :loading="loading">
    <section id="page-title">
      <h2>{{ pageHeading }}</h2>
    </section>

    <section class="content-body">
      <access-denied v-if="!accessPermitted" />

      <template v-else-if="calendarFeedLoaded">
        <form
          accept-charset="UTF-8"
          data-testid="calendar-feed-form"
          @keydown.prevent.enter=""
        >
          <input name="utf8" type="hidden" value="✓">

          <b-field
            label="Name"
            :type="fieldNameType"
            :message="fieldNameMessages"
          >
            <b-input
              v-model.trim="$v.calendarFeed.name.$model"
              :disabled="readOnly"
              data-testid="calendar-feed-form-name"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field
            label="Feed URL"
            :type="fieldUrlType"
            :message="fieldUrlMessages"
          >
            <b-input
              v-model.trim="$v.calendarFeed.url.$model"
              :disabled="readOnly"
              inputmode="url"
              data-testid="calendar-feed-form-url"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <div class="columns">
            <div class="column">
              <b-field
                label="Label"
                :type="fieldLabelType"
                :message="fieldLabelMessages"
              >
                <b-input
                  v-model.trim="$v.calendarFeed.label.$model"
                  :disabled="readOnly"
                  data-testid="calendar-feed-form-label"
                  @keyup.native.enter.once="saveForm"
                />
              </b-field>
            </div>

            <div class="column is-narrow-tablet has-text-right-tablet">
              <b-field label="Colour">
                <v-swatches
                  v-model="calendarFeed.colour"
                  :disabled="readOnly"
                  :swatches="swatchColours"
                  popover-x="left"
                  popover-y="top"
                  row-length="6"
                  shapes="circles"
                  show-checkbox
                  data-testid="calendar-feed-form-colour"
                />
              </b-field>
            </div>
          </div>

          <div class="actions columns">
            <div class="column is-narrow-tablet">
              <b-switch
                v-model="calendarFeed.enabled"
                :disabled="readOnly"
                type="is-success"
                data-testid="calendar-feed-form-enabled"
              >
                Enabled
              </b-switch>
            </div>

            <div class="action-buttons column">
              <template v-if="readOnly">
                <b-button
                  outlined
                  @click="allFeeds"
                >
                  All Calendar Feeds
                </b-button>
              </template>
              <template v-else>
                <b-button
                  outlined
                  @click="confirmDataLoss"
                >
                  Cancel
                </b-button>
                <b-button
                  type="is-primary"
                  :disabled="saving || $v.$invalid"
                  :loading="saving"
                  @click="saveForm"
                >
                  {{ saveButtonText }}
                </b-button>
              </template>
            </div>
          </div>
        </form>
      </template>

      <template v-else>
        <section class="section no-calendar-feed">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>This calendar feed could not be loaded.</p>
          </div>
        </section>
      </template>
    </section>
  </loading-indicator>
</template>

<script>
  import VSwatches from 'vue-swatches'
  import 'vue-swatches/dist/vue-swatches.css'
  import { isEqual } from 'lodash'
  import { required } from 'vuelidate/lib/validators'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { setTitle } from '@/router'
  import { parseDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast, successToast } from '@/helpers/notification-helper'
  import { urlHttpOrHttps, urlWithoutUsername } from '@/validators/url'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'

  export default {
    name: 'calendar-feed',

    components: {
      VSwatches,
      LoadingIndicator,
      AccessDenied,
    },

    async beforeRouteUpdate (_to, _from, next) {
      this.loading = true
      this.calendarFeed = {}

      await this.loadCalendarFeed(this.id)
      next()
    },

    props: {
      id: {
        type: String,
        default: undefined,
      },

      mode: {
        type: String,
        default: 'show',
        validator (value) {
          return ['create', 'edit', 'show'].indexOf(value) !== -1
        },
      },
    },

    validations: {
      calendarFeed: {
        name: {
          required,
        },

        url: {
          required,
          urlHttpOrHttps,
          urlWithoutUsername,
        },

        label: {
          required,
        },
      },
    },

    data () {
      return {
        loading: true,
        saving: false,
        accessPermitted: true,
        calendarFeed: {},
        originalCalendarFeed: {},
        pageHeading: '',
        swatchColours: [
          '#d32f2f',
          '#d666b9',
          '#913668',
          '#7b1fa2',
          '#074a8a',
          '#0880ec',
          '#0097a7',
          '#00796b',
          '#145b08',
          '#388e3c',
          '#689f38',
          '#8a8f24',
          '#da6d00',
          '#e64a19',
          '#7e3a1f',
          '#807f7f',
          '#606060',
          '#3b3b3b',
        ],
      }
    },

    computed: {
      calendarFeedLoaded () {
        return this.calendarFeed && (this.mode === 'create' || this.calendarFeed.id)
      },

      readOnly () {
        return this.mode === 'show'
      },

      saveButtonText () {
        return this.mode === 'create' ? 'Create' : 'Save'
      },

      feedChanged () {
        return !isEqual(this.calendarFeed, this.originalCalendarFeed)
      },

      fieldNameType () {
        if (this.readOnly) return ''

        return this.$v.calendarFeed.name.$error ? 'is-danger' : ''
      },

      fieldNameMessages () {
        if (this.readOnly || !this.$v.calendarFeed.name.$error) return ''

        return this.$v.calendarFeed.name.required ? '' : 'The name is required.'
      },

      fieldUrlType () {
        if (this.readOnly) return ''

        return this.$v.calendarFeed.url.$error ? 'is-danger' : ''
      },

      fieldUrlMessages () {
        if (this.readOnly || !this.$v.calendarFeed.url.$error) return ''

        const messages = []

        if (!this.$v.calendarFeed.url.required) {
          messages.push('The URL is required.')
        }
        if (!this.$v.calendarFeed.url.urlHttpOrHttps) {
          messages.push('The URL must be http or https, and not for a local server.')
        }
        if (!this.$v.calendarFeed.url.urlWithoutUsername) {
          messages.push('The URL must not have a username or password in it.')
        }

        return messages
      },

      fieldLabelType () {
        if (this.readOnly) return ''

        return this.$v.calendarFeed.label.$error ? 'is-danger' : ''
      },

      fieldLabelMessages () {
        if (this.readOnly || !this.$v.calendarFeed.label.$error) return ''

        const messages = []

        if (!this.$v.calendarFeed.label.required) {
          messages.push('The label is required.')
        }
        // TODO: validate uniqueness

        return messages
      },

    },

    async mounted () {
      await this.loadCalendarFeed(this.id)

      EventBus.$on('userDidChange', () => {
        (async () => { await this.loadCalendarFeed(this.id) })()
      })
    },

    methods: {
      async loadCalendarFeed (id) {
        this.loading = true
        this.calendarFeed = {}
        this.accessPermitted = true

        if (this.mode === 'create') {
          this.calendarFeed = await this.loadBlankCalendarFeed()
          this.pageHeading = 'New Calendar Feed'
        } else {
          if (this.mode === 'edit' && !this.$store.getters['user/can']('edit', 'calendarFeeds')) {
            this.accessPermitted = false
          } else {
            const prefix = this.mode === 'edit' ? 'Edit ' : ''

            this.calendarFeed = await this.loadExistingCalendarFeed(id)
            this.pageHeading = this.calendarFeedLoaded ? `${ prefix }Calendar Feed: ${ this.calendarFeed.name }` : `${ prefix }Calendar Feed`
          }
        }

        if (!this.accessPermitted) {
          this.pageHeading = ''
        }
        this.originalCalendarFeed = { ...this.calendarFeed }
        setTitle(this.pageHeading)

        this.loading = false
      },

      async loadBlankCalendarFeed () {
        try {
          const { calendarFeed } = await ApiService.get('calendar_feeds/new')

          calendarFeed.colour = this.swatchColours[0]

          return calendarFeed
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      async loadExistingCalendarFeed (id) {
        try {
          const { calendarFeed } = await ApiService.get(`calendar_feeds/${ id }`)

          return parseDateTimes(calendarFeed)
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else if (error?.response?.status === 422) {
            this.calendarFeed = {}
          } else if (error?.response?.status !== 404) {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      confirmDataLoss () {
        if (this.feedChanged) {
          this.$buefy.dialog.confirm({
            title: 'Cancel Editing',
            message: 'Are you sure you want to cancel? Your changes will be lost.',
            cancelText: 'No',
            confirmText: 'Yes',
            type: 'is-danger',
            hasIcon: true,
            icon: 'alert',
            onConfirm: () => this.allFeeds(),
          })
        } else {
          this.allFeeds()
        }
      },

      allFeeds () {
        this.$router.push('/calendar-feeds')
      },

      async saveForm () {
        this.$v.$touch()
        if (this.mode === 'show' || this.saving || this.$v.$invalid) return

        try {
          const calendarFeed = { calendarFeed: this.calendarFeed }

          this.saving = true
          switch (this.mode) {
            case 'create':
              await ApiService.post('calendar_feeds', calendarFeed)
              break
            case 'edit':
              await ApiService.put(`calendar_feeds/${ this.id }`, calendarFeed)
              break
            default:
              console.error(`saveForm called with unsupported mode ${ this.mode }`)

              return
          }

          successToast('Calendar feed saved')

          await this.$router.push('/calendar-feeds')
        } catch (error) {
          let formattedErrors

          switch (error?.response?.status) {
            case 422:
              formattedErrors = error?.response?.data?.errors?.join('</li><li>')

              this.$buefy.dialog.alert({
                title: 'Could not save calendar feed',
                message: `The server reported the following errors: <ul class="rails-errors"><li>${ formattedErrors }</li></ul>`,
                type: 'is-danger',
                hasIcon: true,
                icon: 'emoticon-sad',
              })
              break
            case 401:
              failureToast('You do not have permission to do this')
              break
            default:
              console.error(error)
              failureToast('There was a problem communicating with the server; please try later')
              break
          }
        } finally {
          this.saving = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  form {
    margin: 0 auto;
    max-width: $form-width;
  }

  label {
    &.switch {
      // fix for switch not appearing centred vs the buttons; it appears space is
      // reserved for the box-shadow on the button's transition state.
      padding-top: 3px;

      // make switch label match other labels
      color: $body-color;
      font-weight: $weight-bold;

      &[disabled] {
        opacity: 1;
      }
    }
  }

  .actions {
    align-items: center;

    .action-buttons {
      text-align: right;

      .button {
        @include not-on-phone {
          &:not(:first-child) {
            margin-left: 0.75rem;
          }
        }

        @include on-phone {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>

<style lang="scss">
  // Buefy dialogs can't be styled from the calling component, as they are created on the <body>, so this rule must be global
  // Can be replaced by a ::v-global block after moving to Vue 3
  .dialog {
    ul {
      &.rails-errors {
        list-style-type: circle;

        li {
          padding-left: 0;
        }
      }
    }
  }
</style>
