<template>
  <div>
    <section id="page-title">
      <h2>Settings</h2>
    </section>
    <section class="content-body">
      <loading-indicator :loading="loading">
        <access-denied v-if="!accessPermitted" />
        <template v-else>
          <form
            accept-charset="UTF-8"
            data-testid="settings-form"
            @keydown.prevent.enter=""
          >
            <div class="site-notice">
              <divider-with-text label="SITE NOTICE" />
              <b-field>
                <div>
                  <label class="label">
                    Content
                  </label>
                  <div
                    ref="contentEditor"
                    class="control html-editor"
                    data-testid="site-notice-content"
                  >
                    <editor-menu-bar
                      v-slot="{ commands, isActive, getMarkAttrs }"
                      :editor="editor"
                    >
                      <div class="menubar">
                        <button
                          type="button"
                          @click.prevent="commands.undo"
                        >
                          <b-icon icon="undo" />
                        </button>

                        <button
                          type="button"
                          @click.prevent="commands.redo"
                        >
                          <b-icon icon="redo" />
                        </button>

                        <div class="spacer" />

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                          @click.prevent="commands.heading({ level: 3 })"
                        >
                          <b-icon icon="format-header-3" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.heading({ level: 4 }) }"
                          @click.prevent="commands.heading({ level: 4 })"
                        >
                          <b-icon icon="format-header-4" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.heading({ level: 5 }) }"
                          @click.prevent="commands.heading({ level: 5 })"
                        >
                          <b-icon icon="format-header-5" />
                        </button>

                        <div class="spacer" />

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.blockquote() }"
                          @click.prevent="commands.blockquote"
                        >
                          <b-icon icon="format-quote-open" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.bullet_list() }"
                          @click.prevent="commands.bullet_list"
                        >
                          <b-icon icon="format-list-bulleted" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.ordered_list() }"
                          @click.prevent="commands.ordered_list"
                        >
                          <b-icon icon="format-list-numbered" />
                        </button>

                        <div class="spacer" />

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.horizontal_rule() }"
                          @click.prevent="commands.horizontal_rule"
                        >
                          <b-icon icon="drag-horizontal-variant" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.hard_break() }"
                          @click.prevent="commands.hard_break"
                        >
                          <b-icon icon="keyboard-return" />
                        </button>

                        <div class="spacer" />

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.bold() }"
                          @click.prevent="commands.bold"
                        >
                          <b-icon icon="format-bold" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.italic() }"
                          @click.prevent="commands.italic"
                        >
                          <b-icon icon="format-italic" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.strike() }"
                          @click.prevent="commands.strike"
                        >
                          <b-icon icon="format-strikethrough" />
                        </button>

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.subscript() }"
                          @click.prevent="commands.subscript"
                        >
                          <b-icon icon="format-subscript" />
                        </button>

                        <div class="spacer" />

                        <button
                          type="button"
                          :class="{ 'is-active': isActive.link() }"
                          @click.prevent="showLinkMenu(getMarkAttrs('link'), commands.link)"
                        >
                          <b-icon icon="link-variant" />
                        </button>
                      </div>
                    </editor-menu-bar>
                    <editor-content :editor="editor" />
                  </div>
                </div>
              </b-field>
              <div class="editor-actions">
                <div class="site-notice-switch">
                  <b-switch
                    v-model="settings.site_notice_display_toggle"
                    type="is-success"
                    data-testid="site-notice-display-toggle"
                    @input="updateSiteNoticeDisplayToggle($event)"
                  >
                    Display on every page
                  </b-switch>
                </div>
                <div class="site-notice-action-buttons">
                  <b-button
                    type="is-primary"
                    :disabled="savingSiteNotice"
                    :loading="savingSiteNotice"
                    data-testid="site-notice-save-content-button"
                    @click="saveSiteNotice"
                  >
                    Save Content
                  </b-button>
                </div>
              </div>
            </div>

            <div class="yapton-beerex">
              <divider-with-text label="YAPTON BEEREX" />
              <b-field label="Festival">
                <tree-select
                  :value="settings.yapton_beer_festival_id"
                  :options="beerFestivals"
                  placeholder="Select the Yapton Beerex festival"
                  instance-id="yapton-beer-festival"
                  data-testid="yapton-beer-festival-dropdown"
                  @input="updateSetting('yapton_beer_festival_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.branch">
                      {{ node.raw.name }} <span class="hint">({{ node.raw.branch }})</span>
                    </span>
                    <span v-else>
                      {{ node.raw.name }}
                    </span>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.branch">
                      {{ node.raw.name }} <span class="hint">({{ node.raw.branch }})</span>
                    </span>
                    <span v-else>
                      {{ node.raw.name }}
                    </span>
                  </div>
                </tree-select>
              </b-field>

              <b-field label="Page">
                <tree-select
                  :value="settings.yapton_page_id"
                  :options="pages"
                  placeholder="Select a Yapton Beerex page"
                  instance-id="yapton-page"
                  data-testid="yapton-page-dropdown"
                  @input="updateSetting('yapton_page_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </div>
                </tree-select>
              </b-field>

              <b-field label="Publication">
                <tree-select
                  :value="settings.yapton_publication_id"
                  :options="publications"
                  placeholder="Select the Yapton Beerex programme"
                  instance-id="yapton-publication"
                  data-testid="yapton-publication-dropdown"
                  @input="updateSetting('yapton_publication_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.issuesCount === null">
                      {{ node.raw.name }}
                    </span>
                    <span v-else>
                      {{ node.raw.name }}
                      <span class="hint">({{ node.raw.issuesCount }} issue<template v-if="node.raw.issuesCount !== 1">s</template>)</span>
                    </span>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.issuesCount === null">
                      {{ node.raw.name }}
                    </span>
                    <span v-else>
                      {{ node.raw.name }}
                      <span class="hint">({{ node.raw.issuesCount }} issue<template v-if="node.raw.issuesCount !== 1">s</template>)</span>
                    </span>
                  </div>
                </tree-select>
              </b-field>
            </div>

            <div class="poty">
              <divider-with-text label="PUB OF THE YEAR" />
              <b-field label="Page">
                <tree-select
                  :value="settings.poty_page_id"
                  :options="pages"
                  placeholder="Select a Pub of the Year page"
                  instance-id="poty-page"
                  data-testid="poty-page-dropdown"
                  @input="updateSetting('poty_page_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </div>
                </tree-select>
              </b-field>
            </div>

            <div class="gbbf">
              <divider-with-text label="GREAT BRITISH BEER FESTIVAL" />
              <div class="has-text-centered">
                <b-button
                  type="is-info"
                  data-testid="gbbf-alt-toggle"
                  @click="toggleGBBFAlt"
                >
                  Display Other
                </b-button>
              </div>

              <div class="columns">
                <div
                  class="gbbf-settings column is-6-tablet"
                  :class="{ selected: !settings.gbbf_alt_toggle }"
                  data-testid="gbbf-main-section"
                >
                  <b-field label="Name">
                    <b-input
                      v-model="settings.gbbf_name"
                      type="text"
                      data-testid="gbbf-name"
                      @blur="updateSetting('gbbf_name', $event.target.value)"
                    />
                  </b-field>

                  <b-field label="Dates">
                    <v-date-picker
                      v-model="settings.gbbf_dates"
                      is-range
                      :popover="{ placement: 'bottom', visibility: 'focus' }"
                      weeks-transition="fade"
                      :masks="dateFormats"
                      :model-config="{ timeAdjust: '12:00:00' }"
                      @input="updateSetting('gbbf_dates', $event)"
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <div class="columns">
                          <div class="column is-half">
                            <input
                              type="text"
                              class="input"
                              :value="inputValue.start"
                              inputmode="numeric"
                              data-testid="gbbf-date-start"
                              v-on="inputEvents.start"
                            >
                          </div>
                          <div class="column is-half">
                            <input
                              type="text"
                              class="input"
                              :value="inputValue.end"
                              inputmode="numeric"
                              data-testid="gbbf-date-end"
                              v-on="inputEvents.end"
                            >
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </b-field>

                  <b-field label="Location">
                    <b-input
                      v-model="settings.gbbf_location"
                      type="text"
                      data-testid="gbbf-location"
                      @blur="updateSetting('gbbf_location', $event.target.value)"
                    />
                  </b-field>

                  <b-field
                    label="URL"
                    :type="fieldGbbfUrlType"
                    :message="fieldGbbfUrlMessages"
                  >
                    <b-input
                      v-model="$v.settings.gbbf_url.$model"
                      type="text"
                      inputmode="url"
                      data-testid="gbbf-url"
                      @blur="!$v.settings.gbbf_url.$error && updateSetting('gbbf_url', $event.target.value)"
                    />
                  </b-field>
                  <p class="hint">
                    {{ textGbbf }}
                  </p>
                </div>
                <div
                  class="gbbf-alt-settings column is-6-tablet"
                  :class="{ selected: settings.gbbf_alt_toggle }"
                  data-testid="gbbf-alt-section"
                >
                  <b-field label="Name">
                    <b-input
                      v-model="settings.gbbf_alt_name"
                      type="text"
                      data-testid="gbbf-alt-name"
                      @blur="updateSetting('gbbf_alt_name', $event.target.value)"
                    />
                  </b-field>

                  <b-field label="Dates">
                    <v-date-picker
                      v-model="settings.gbbf_alt_dates"
                      is-range
                      :popover="{ placement: 'bottom', visibility: 'focus' }"
                      weeks-transition="fade"
                      :masks="dateFormats"
                      :input-props="{ class: 'input', name: 'gbbf_alt_dates', required: true, autocomplete: 'off' }"
                      :model-config="{ timeAdjust: '12:00:00' }"
                      @input="updateSetting('gbbf_alt_dates', $event)"
                    >
                      <template #default="{ inputValue, inputEvents }">
                        <div class="columns">
                          <div class="column is-half">
                            <input
                              type="text"
                              class="input"
                              :value="inputValue.start"
                              inputmode="numeric"
                              data-testid="gbbf-alt-date-start"
                              v-on="inputEvents.start"
                            >
                          </div>
                          <div class="column is-half">
                            <input
                              type="text"
                              class="input"
                              :value="inputValue.end"
                              inputmode="numeric"
                              data-testid="gbbf-alt-date-end"
                              v-on="inputEvents.end"
                            >
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </b-field>

                  <b-field label="Location">
                    <b-input
                      v-model="settings.gbbf_alt_location"
                      type="text"
                      data-testid="gbbf-alt-location"
                      @blur="updateSetting('gbbf_alt_location', $event.target.value)"
                    />
                  </b-field>

                  <b-field
                    label="URL"
                    :type="fieldGbbfAltUrlType"
                    :message="fieldGbbfAltUrlMessages"
                  >
                    <b-input
                      v-model="$v.settings.gbbf_alt_url.$model"
                      type="text"
                      inputmode="url"
                      data-testid="gbbf-alt-url"
                      @blur="!$v.settings.gbbf_alt_url.$error && updateSetting('gbbf_alt_url', $event.target.value)"
                    />
                  </b-field>
                  <p class="hint">
                    {{ textGbbfAlt }}
                  </p>
                </div>
              </div>
            </div>

            <div class="sussex-drinker">
              <divider-with-text label="SUSSEX DRINKER" />
              <b-field label="Publication">
                <tree-select
                  :value="settings.sussex_drinker_publication_id"
                  :options="publications"
                  placeholder="Select the Sussex Drinker publication"
                  instance-id="sussex-drinker-publication"
                  data-testid="sussex-drinker-publication-dropdown"
                  @input="updateSetting('sussex_drinker_publication_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.issuesCount === null">
                      {{ node.raw.name }}
                    </span>
                    <span v-else>
                      {{ node.raw.name }}
                      <span class="hint">({{ node.raw.issuesCount }} issue<template v-if="node.raw.issuesCount !== 1">s</template>)</span>
                    </span>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.issuesCount === null">
                      {{ node.raw.name }}
                    </span>
                    <span v-else>
                      {{ node.raw.name }}
                      <span class="hint">({{ node.raw.issuesCount }} issue<template v-if="node.raw.issuesCount !== 1">s</template>)</span>
                    </span>
                  </div>
                </tree-select>
              </b-field>
            </div>

            <div class="local-branches">
              <divider-with-text label="LOCAL BRANCHES" />
              <b-field label="Page">
                <tree-select
                  :value="settings.local_branches_page_id"
                  :options="pages"
                  placeholder="Select a Local Branches page"
                  instance-id="local-branches-page"
                  data-testid="local-branches-page-dropdown"
                  @input="updateSetting('local_branches_page_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.title === null || node.raw.published">{{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </div>
                </tree-select>
              </b-field>
            </div>

            <div class="about-us">
              <divider-with-text label="CONTACT US / ABOUT US" />
              <b-field label="Page">
                <tree-select
                  :value="settings.contact_page_id"
                  :options="pages"
                  placeholder="Select a Contact Us page"
                  instance-id="contact-page"
                  data-testid="contact-page-dropdown"
                  @input="updateSetting('contact_page_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </div>
                </tree-select>
              </b-field>
            </div>

            <div class="locale">
              <divider-with-text label="LOCALE" />
              <b-field label="Page">
                <tree-select
                  :value="settings.locale_page_id"
                  :options="pages"
                  placeholder="Select a LocAle page"
                  instance-id="locale-page"
                  data-testid="locale-page-dropdown"
                  @input="updateSetting('locale_page_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </div>
                </tree-select>
              </b-field>
            </div>

            <div class="links">
              <divider-with-text label="USEFUL LINKS" />
              <b-field label="Page">
                <tree-select
                  :value="settings.links_page_id"
                  :options="pages"
                  placeholder="Select a links page"
                  instance-id="links-page"
                  data-testid="links-page-dropdown"
                  @input="updateSetting('links_page_id', $event)"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, labelClassName }"
                    :class="labelClassName"
                  >
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </label>
                  <div slot="value-label" slot-scope="{ node }">
                    <span v-if="node.raw.title === null || node.raw.published">
                      {{ node.raw.title }}
                    </span>
                    <template v-else>
                      <span class="has-text-grey-light">{{ node.raw.title }}</span>
                      <span class="has-text-danger ml-2"><em>Unpublished</em></span>
                    </template>
                  </div>
                </tree-select>
              </b-field>
            </div>
          </form>
        </template>
      </loading-indicator>
    </section>
  </div>
</template>

<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Heading,
    Bold,
    Italic,
    Strike,
    Link,
    Blockquote,
    OrderedList,
    BulletList,
    ListItem,
    HardBreak,
    HorizontalRule,
    History,
  } from 'tiptap-extensions'
  import { Subscript } from 'tiptap-extension-subscript'

  import { pick } from 'lodash'
  import { parseISO } from 'date-fns'
  import TreeSelect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  import { failureToast } from '@/helpers/notification-helper'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { urlHttpOrHttps, urlWithoutUsername } from '@/validators/url'

  import AccessDenied from '@/components/helpers/access-denied'
  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import DividerWithText from '@/components/helpers/divider-with-text'

  const settingDefaults = {
    'contact_page_id': null,
    'locale_page_id': null,
    'poty_page_id': null,
    'local_branches_page_id': null,
    'links_page_id': null,
    'sussex_drinker_publication_id': null,
    'yapton_page_id': null,
    'yapton_publication_id': null,
    'yapton_beer_festival_id': null,
    'gbbf_dates': null,
    'gbbf_alt_dates': null,
    'gbbf_name': '',
    'gbbf_alt_name': '',
    'gbbf_location': '',
    'gbbf_alt_location': '',
    'gbbf_url': '',
    'gbbf_alt_url': '',
    'gbbf_alt_toggle': false,
    'site_notice_display_toggle': false,
    'site_notice_content': '',
  }

  export default {
    name: 'settings',

    components: {
      EditorMenuBar,
      EditorContent,
      TreeSelect,
      AccessDenied,
      DividerWithText,
      LoadingIndicator,
    },

    validations: {
      settings: {
        gbbf_url: {
          urlHttpOrHttps,
          urlWithoutUsername,
        },

        gbbf_alt_url: {
          urlHttpOrHttps,
          urlWithoutUsername,
        },
      },
    },

    data () {
      return {
        settings: {},
        pages: [],
        publications: [],
        beerFestivals: [],
        accessPermitted: true,
        loading: true,
        savingSiteNotice: false,
        dateFormats: {
          input: [
            'YYYY-MM-DD',
            'YYYY/MM/DD',
            'DD/MM/YYYY',
          ],

          data: ['YYYY-MM-DD'],
        },

        editor: new Editor(this.editorOptions),

        /* eslint-disable vue/no-unused-properties */
        editorOptions: {
          content: '',

          extensions: [
            new Heading({
              levels: [3, 4, 5],
            }),
            new Bold(),
            new Italic(),
            new Strike(),
            new Subscript(),
            new Link({ openOnClick: false }),
            new Blockquote(),
            new OrderedList(),
            new BulletList(),
            new ListItem(),
            new HardBreak(),
            new HorizontalRule(),
            new History(),
          ],

          onUpdate: ({ getHTML }) => {
            this.settings.site_notice_content = getHTML()
          },
        },
        /* eslint-enable vue/no-unused-properties */
      }
    },

    computed: {
      textGbbf () {
        return this.settings.gbbf_alt_toggle
          ? 'Not displayed on front page.'
          : 'Displayed on front page.'
      },

      textGbbfAlt () {
        return this.settings.gbbf_alt_toggle
          ? 'Displayed on front page.'
          : 'Not displayed on front page.'
      },

      fieldGbbfUrlType () {
        return this.$v.settings.gbbf_url.$error ? 'is-danger' : ''
      },

      fieldGbbfAltUrlType () {
        return this.$v.settings.gbbf_alt_url.$error ? 'is-danger' : ''
      },

      fieldGbbfUrlMessages () {
        const messages = []

        if (!this.$v.settings.gbbf_url.urlHttpOrHttps) {
          messages.push('The URL must be http or https, and not for a local server.')
        }
        if (!this.$v.settings.gbbf_url.urlWithoutUsername) {
          messages.push('The URL must not have a username or password in it.')
        }

        return messages
      },

      fieldGbbfAltUrlMessages () {
        const messages = []

        if (!this.$v.settings.gbbf_alt_url.urlHttpOrHttps) {
          messages.push('The URL must be http or https, and not for a local server.')
        }
        if (!this.$v.settings.gbbf_alt_url.urlWithoutUsername) {
          messages.push('The URL must not have a username or password in it.')
        }

        return messages
      },
    },

    async mounted () {
      await this.loadSettings()

      EventBus.$on('userDidChange', () => {
        (async () => { await this.loadSettings() })()
      })
    },

    methods: {
      async loadSettings () {
        let settings, pages, publications, beerFestivals

        this.loading = true
        this.accessPermitted = true

        // Settings
        try {
          ({ settings } = await ApiService.get('settings'))
          settings = {
            ...settingDefaults,
            ...settings,
          }
          if (settings.gbbf_dates) {
            settings.gbbf_dates.start = parseISO(settings.gbbf_dates.start)
            settings.gbbf_dates.end = parseISO(settings.gbbf_dates.end)
          }
          if (settings.gbbf_alt_dates) {
            settings.gbbf_alt_dates.start = parseISO(settings.gbbf_alt_dates.start)
            settings.gbbf_alt_dates.end = parseISO(settings.gbbf_alt_dates.end)
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          }
          settings = {
            ...settingDefaults,
          }
        } finally {
          this.settings = settings
        }

        // vue-treeselect uses the `label` key for display; even if we're using a custom slot, this key is used for search-by-typing,
        // so we need to populate it with something that makes sense.

        // Pages
        try {
          ({ pages } = await ApiService.get('pages'))

          pages = pages.map((page) => ({
            ...pick(page, ['id', 'title', 'published']),
            label: page.title,
          }))

          this.pages = [
            ...pages.filter((page) => page.published),
            ...pages.filter((page) => !page.published),
          ]
        } catch (error) {
          this.pages = []

          if (error?.response?.status === 401) {
            this.accessPermitted = false
          }
        }

        // Publications
        try {
          ({ publications } = await ApiService.get('publications'))

          this.publications = publications.map((publication) => ({
            ...pick(publication, ['id', 'name', 'issuesCount']),
            label: publication.name,
          }))
        } catch (error) {
          this.publications = []

          if (error?.response?.status === 401) {
            this.accessPermitted = false
          }
        }

        // BeerFestivals
        try {
          ({ beerFestivals } = await ApiService.get('beer_festivals'))

          this.beerFestivals = beerFestivals.map((festival) => ({
            ...pick(festival, ['id', 'name', 'branch']),
            label: `${ festival.name } ${ festival.branch }`,
          }))
        } catch (error) {
          this.beerFestivals = []

          if (error?.response?.status === 401) {
            this.accessPermitted = false
          }
        }

        this.editor.destroy()
        this.editor = new Editor(this.editorOptions)
        this.editor.setContent(this.settings.site_notice_content)

        this.loading = false
      },

      async updateSetting (setting, value) {
        this.settings[setting] = value
        await ApiService.put(`settings/${ setting }`, {
          setting: {
            value,
          },
        })
        EventBus.$emit('settingsDidChange')
        // TODO: above line only works for current user; implement https://git.omgponies.org.uk/western-sussex-camra/frontend/-/issues/31
        //       and emit event to everyone instead
      },

      async updateSiteNoticeDisplayToggle (content) {
        await this.updateSetting('site_notice_display_toggle', content)
        EventBus.$emit('siteNoticeDidChange')
      },

      showLinkMenu (attrs, command) {
        this.$buefy.dialog.prompt({
          trapFocus: true,
          ariaRole: 'dialog',
          ariaModal: true,
          title: 'Set Link',
          message: 'Enter the address of the link. Leave blank to remove the link from the selected text.',
          confirmText: 'Save',
          cancelText: 'Cancel',
          inputAttrs: {
            type: 'text',
            placeholder: 'https://',
            value: attrs.href,
            required: false,
          },
          onConfirm (value) {
            if (value === '') {
              command({ href: null })
            } else if (new RegExp('^https?://').test(value)) {
              command({ href: value })
            } else if (value.indexOf('://') === -1) {
              command({ href: `https://${ value }` })
            } else {
              failureToast('Invalid link address; use only http:// or https://')
            }
          },
        })
      },

      async saveSiteNotice () {
        this.savingSiteNotice = true
        await this.updateSetting('site_notice_content', this.settings.site_notice_content)
        EventBus.$emit('siteNoticeDidChange')
        // TODO: above line only works for current user; implement https://git.omgponies.org.uk/western-sussex-camra/frontend/-/issues/31
        //       and emit event to everyone instead
        this.savingSiteNotice = false
      },

      async toggleGBBFAlt () {
        this.settings.gbbf_alt_toggle = !this.settings.gbbf_alt_toggle
        await this.updateSetting('gbbf_alt_toggle', this.settings.gbbf_alt_toggle)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @use 'sass:color';

  @import '@/assets/bulma-variables.scss';

  form {
    margin: 0 auto;
    max-width: $form-width;
  }

  .field {
    margin-right: auto;
    margin-left: auto;
  }

  .hint {
    color: $grey;
    font-size: 85%;
    font-style: italic;
  }

  ::v-deep {
    .vue-treeselect__control {
      border-radius: $radius;
    }

    .vue-treeselect--focused {
      &:not(.vue-treeselect--open) {
        .vue-treeselect__control {
          border-color: $primary;
          box-shadow: 0 0 0 3px color.change($primary, $alpha: 0.1);
        }
      }
    }
  }

  .gbbf {
    .hint {
      font-weight: $weight-bold;
      font-style: normal;
    }

    .columns {
      margin-top: 0;
    }

    .column {
      margin-top: 0.5rem;
      padding: 0.25rem 0.5rem;
    }

    .gbbf-settings,
    .gbbf-alt-settings {
      &:not(.selected) {
        background-image: url('../assets/img/hatch.svg');
        background-repeat: repeat;
      }

      .columns {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }
    }
  }

  .editor-actions {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .site-notice-action-buttons {
      text-align: right;

      @include on-phone {
        order: -1; // display above toggle, as the toggle is instant
      }

      .button {
        top: -3px; // fix vertical centring relative to switch

        @include not-on-phone {
          &:not(:first-child) {
            margin-left: 0.75rem;
          }
        }

        @include on-phone {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .html-editor {
    label {
      margin-bottom: 0.5rem;
    }

    .menubar {
      line-height: 2;

      .spacer {
        display: inline-block;
        width: 0.5rem;
      }

      button {
        margin-right: 0.1rem;
        border: 1px solid $grey;
        border-radius: $radius;

        &.is-active {
          background-color: $grey-light;
        }
      }
    }

    &::v-deep {
      .ProseMirror {
        border: 1px solid $light;
        border-radius: $radius;
        box-shadow: inset 0 0.0625rem 0.125rem rgba(10, 10, 10, 0.05);
        background-color: #fff;
        padding: 0.5rem 0.75rem;
        color: $dark;

        &:focus {
          outline: none;
          border-color: $primary;
          box-shadow: 0 0 0 0.125rem change-color($primary, $alpha: 0.25);
        }

        &[contenteditable='false'] {
          border-color: #f5f5f5;
          box-shadow: none;
          background-color: #f5f5f5;
          cursor: not-allowed;
          color: #7a7a7a;
        }

        ul {
          list-style-type: circle;
        }
      }
    }

    &.is-danger {
      ::v-deep {
        .ProseMirror {
          border-color: $danger;
        }
      }
    }
  }
</style>
