<template>
  <front-page-card
    title="Useful Links"
    :more-link="page"
    more-text-screenreader="Useful links"
    card="links"
    :larger="larger"
  >
    <p>Trading Standards and other useful links.</p>
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'

  export default {
    name: 'links-card',

    components: {
      FrontPageCard,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      page: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
