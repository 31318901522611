<template>
  <div>
    <section id="page-title">
      <h2>Publications</h2>
    </section>
    <section class="content-body">
      <loading-indicator :loading="loading">
        <div v-if="userCanSeeList" class="publications">
          <b-table
            :data="publications"
            :loading="tableLoading"
            striped
            mobile-cards
            :show-header="publications.length > 0 || filtersApplied"
            backend-filtering
            :debounce-search="500"
            backend-sorting
            :default-sort="[sortField, sortOrder]"
            :default-sort-direction="defaultSortOrder"
            paginated
            backend-pagination
            :current-page="paginatorPage"
            :total="totalItems"
            :per-page="paginatorPerPage"
            @sort="paginatorSortDidChange"
            @page-change="paginatorPageDidChange"
            @filters-change="tableFilterDidChange"
          >
            <b-table-column
              field="name"
              label="Name"
              searchable
              sortable
            >
              <template #default="props">
                {{ props.row.name }}
              </template>

              <template #searchable="props">
                <b-input
                  v-model="props.filters.name"
                  placeholder="Search…"
                  icon="magnify"
                  size="is-small"
                />
              </template>
            </b-table-column>

            <b-table-column
              field="slug"
              label="Slug"
              searchable
              sortable
            >
              <template #default="props">
                {{ props.row.slug }}
              </template>

              <template #searchable="props">
                <b-input
                  v-model="props.filters.slug"
                  placeholder="Search…"
                  icon="magnify"
                  size="is-small"
                />
              </template>
            </b-table-column>

            <b-table-column
              field="issuesCount"
              label="Issues"
              sortable
            >
              <template #default="props">
                {{ props.row.issuesCount }}
              </template>
            </b-table-column>

            <b-table-column
              v-slot="props"
              custom-key="buttons"
              width="17.5rem"
            >
              <div class="buttons">
                <b-button
                  v-if="userCanCreateIssue"
                  type="is-dark"
                  size="is-small"
                  outlined
                  @click="createIssue(props.row)"
                >
                  Add Issue
                </b-button>

                <b-tooltip
                  label="You cannot delete publications that have issues"
                  type="is-light"
                  :triggers="props.row.issuesCount > 0 ? ['hover'] : []"
                >
                  <b-button
                    v-if="userCanDeletePublication"
                    type="is-danger"
                    size="is-small"
                    outlined
                    :disabled="props.row.issuesCount > 0"
                    @click="destroy(props.row)"
                  >
                    Delete
                  </b-button>
                </b-tooltip>

                <b-button
                  v-if="userCanViewPublication"
                  type="is-dark"
                  size="is-small"
                  outlined
                  @click="view(props.row)"
                >
                  View
                </b-button>

                <b-button
                  v-if="userCanEditPublication"
                  type="is-primary"
                  size="is-small"
                  outlined
                  @click="edit(props.row)"
                >
                  Edit
                </b-button>
              </div>
            </b-table-column>

            <template #bottom-left>
              <b-button
                v-if="userCanCreatePublication"
                type="is-primary"
                outlined
                data-testid="new-publication-button"
                @click="create"
              >
                New Publication
              </b-button>
            </template>

            <template #empty>
              <section class="section no-publications">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>No publications.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <access-denied v-else />
      </loading-indicator>
    </section>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { failureToast, successToast } from '@/helpers/notification-helper'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'
  import { parseISO } from 'date-fns'

  export default {
    name: 'publications',

    components: {
      LoadingIndicator,
      AccessDenied,
    },

    data () {
      return {
        publications: [],
        loading: true,
        tableLoading: true,
        accessPermitted: true,
        paginatorPage: 1,
        paginatorPerPage: 8,
        tableFilterName: '',
        tableFilterSlug: '',
        totalItems: 0,
        sortField: 'name',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
      }
    },

    computed: {
      userCanSeeList () {
        return this.accessPermitted && this.$store.getters['user/can']('list', 'publications')
      },

      userCanEditPublication () {
        return this.$store.getters['user/can']('edit', 'publications')
      },

      userCanViewPublication () {
        return this.$store.getters['user/can']('view', 'publications')
      },

      userCanDeletePublication () {
        return this.$store.getters['user/can']('delete', 'publications')
      },

      userCanCreatePublication () {
        return this.$store.getters['user/can']('create', 'publications')
      },

      userCanCreateIssue () {
        return this.$store.getters['user/can']('create', 'issues')
      },

      filtersApplied () {
        return this.tableFilterName !== '' || this.tableFilterSlug !== ''
      },

      lastPage () {
        return Math.ceil((this.totalItems) / this.paginatorPerPage)
      },
    },

    async mounted () {
      await this.loadPublications()
      this.loading = false

      EventBus.$on('userDidChange', () => {
        this.loading = true
        ;(async () => {
          await this.loadPublications()
        })()
        this.loading = false
      })
    },

    methods: {
      async loadPublications () {
        let returnedPublications, itemCount

        if (this.$route.query?.page === undefined) {
          this.paginatorPage = this.$store.getters['pagination/publicationsPage'] || 1
        } else {
          let parsedPage = Number.parseInt(this.$route.query.page)

          if (parsedPage < 1 || Number.isNaN(parsedPage)) {
            parsedPage = 1
            await this.$router.replace({ name: 'publications', params: this.$route.params, query: { ...this.$route.query, page: parsedPage } })
          }

          this.paginatorPage = parsedPage
        }
        await this.$store.dispatch('pagination/clearPublicationsPage')

        this.tableLoading = true
        this.accessPermitted = true

        try {
          for (;;) {
            const filters = new URLSearchParams({
              p: this.paginatorPage,
              s: this.sortField,
              o: this.sortOrder,
              n: this.paginatorPerPage,
              fn: this.tableFilterName,
              fs: this.tableFilterSlug,
            }).toString()

            ;({ publications: returnedPublications, itemCount } = await ApiService.get(`publications?${ filters }`))

            this.totalItems = itemCount
            if (this.totalItems === 0 || this.paginatorPage === 1 || this.paginatorPage <= this.lastPage) break

            // page is past the end of the dataset
            this.paginatorPage = this.lastPage
            await this.$router.replace({ name: 'publications', params: this.$route.params, query: { ...this.$route.query, page: this.paginatorPage } })
          }
        } catch (error) {
          returnedPublications = []
          this.totalItems = 0

          if (error?.response?.status === 401) {
            this.accessPermitted = false
            failureToast('You do not have permission to do this')
          }
        }

        this.publications = returnedPublications
          .map((row) => {
            const createdAt = row.createdAt ? parseISO(row.createdAt) : null
            const updatedAt = row.updatedAt ? parseISO(row.updatedAt) : null

            return {
              ...row,
              createdAt,
              updatedAt,
            }
          })
        this.tableLoading = false
      },

      paginatorSortDidChange (field, order) {
        this.sortField = field
        this.sortOrder = order
        this.loadPublications()
      },

      paginatorPageDidChange (page) {
        this.paginatorPage = page
        this.$router.replace({ name: 'publications', params: this.$route.params, query: { ...this.$route.query, page } })
        this.loadPublications()
      },

      tableFilterDidChange (filter) {
        this.tableFilterName = filter.name ?? ''
        this.tableFilterSlug = filter.slug ?? ''
        this.loadPublications()
      },

      destroy (publication) {
        this.$buefy.dialog.confirm({
          title: 'Delete Publication',
          message: `Are you sure you want to delete the publication “${ publication.name }”? This cannot be undone.`,
          cancelText: 'Keep',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          icon: 'alert',
          onConfirm: async () => {
            try {
              await ApiService.delete('publications', publication.id)
              successToast('Publication deleted')
              this.totalItems -= 1
              if (this.paginatorPage > 1 && this.paginatorPage > this.lastPage) {
                await this.paginatorPageDidChange(this.lastPage)
              } else {
                await this.loadPublications()
              }
            } catch (error) {
              const message = error?.response?.status === 401
                ? 'You do not have permission to delete this publication'
                : 'Could not delete publication'

              failureToast(message)
            }
          },
        })
      },

      view (publication) {
        this.$store.dispatch('pagination/setPublicationsPage', { page: this.paginatorPage })
        this.$router.push(`/publications/${ publication.slug }`)
      },

      edit (publication) {
        this.$store.dispatch('pagination/setPublicationsPage', { page: this.paginatorPage })
        this.$router.push(`/publications/${ publication.id }/edit`)
      },

      create () {
        this.$store.dispatch('pagination/setPublicationsPage', { page: this.paginatorPage })
        this.$router.push('/publications/new')
      },

      createIssue (publication) {
        this.$router.push(`/publications/${ publication.id }/issues/new`)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .publications {
    margin-bottom: 2rem;

    @include on-phone {
      margin-top: 0.75rem;
    }
  }

  ::v-deep {
    table {
      tr {
        th,
        td {
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  .buttons {
    justify-content: flex-end;

    // Putting a tooltip on a button loses the button's right margin as it's now last-child in its container
    .b-tooltip {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    .button {
      @include not-on-phone {
        margin-top: -3px;
        margin-bottom: 0;
      }
    }
  }
</style>
