<template>
  <div>
    <section id="page-title">
      <h2>Pages</h2>
    </section>
    <loading-indicator :loading="loading">
      <div v-if="userCanSeeList" class="pages">
        <b-table
          :data="pages"
          :loading="tableLoading"
          striped
          mobile-cards
          :show-header="pages.length > 0 || filtersApplied"
          backend-filtering
          :debounce-search="500"
          backend-sorting
          :default-sort="[sortField, sortOrder]"
          :default-sort-direction="defaultSortOrder"
          paginated
          backend-pagination
          :current-page="paginatorPage"
          :total="totalItems"
          :per-page="paginatorPerPage"
          @sort="paginatorSortDidChange"
          @page-change="paginatorPageDidChange"
          @filters-change="tableFilterDidChange"
        >
          <b-table-column
            field="title"
            label="Title"
            searchable
            sortable
          >
            <template #default="props">
              {{ props.row.title }}
            </template>

            <template #searchable="props">
              <b-input
                v-model="props.filters.title"
                placeholder="Search…"
                icon="magnify"
                size="is-small"
              />
            </template>
          </b-table-column>

          <b-table-column
            field="slug"
            label="Slug"
            searchable
            sortable
          >
            <template #default="props">
              {{ props.row.slug }}
            </template>

            <template #searchable="props">
              <b-input
                v-model="props.filters.slug"
                placeholder="Search…"
                icon="magnify"
                size="is-small"
              />
            </template>
          </b-table-column>

          <b-table-column
            field="published"
            label="Published"
            sortable
          >
            <template #default="props">
              <b-tag
                v-if="props.row.published"
                type="is-success"
              >
                Published
              </b-tag>
              <b-tag
                v-else
                type="is-light"
              >
                Unpublished
              </b-tag>
            </template>
          </b-table-column>

          <b-table-column
            field="updatedAt"
            label="Last Updated"
            sortable
          >
            <template #default="props">
              {{ props.row.readableUpdatedAt }}
            </template>
          </b-table-column>

          <b-table-column
            v-slot="props"
            custom-key="buttons"
            width="12.5rem"
          >
            <div class="buttons">
              <b-button
                v-if="userCanDeletePage"
                type="is-danger"
                size="is-small"
                outlined
                @click="destroy(props.row)"
              >
                Delete
              </b-button>

              <b-button
                v-if="userCanViewPage"
                type="is-dark"
                size="is-small"
                outlined
                @click="view(props.row)"
              >
                View
              </b-button>

              <b-button
                v-if="userCanEditPage"
                type="is-primary"
                size="is-small"
                outlined
                @click="edit(props.row)"
              >
                Edit
              </b-button>
            </div>
          </b-table-column>

          <template #bottom-left>
            <b-button
              v-if="userCanCreatePage"
              type="is-primary"
              outlined
              data-testid="new-page-button"
              @click="create"
            >
              New Page
            </b-button>
          </template>

          <template #empty>
            <section class="section no-calendar-feeds">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>No pages.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <access-denied v-else />
    </loading-indicator>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { failureToast, successToast } from '@/helpers/notification-helper'

  import AccessDenied from '@/components/helpers/access-denied'
  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import { format as formatDate, parseISO } from 'date-fns'
  import { dateFormats } from '@/objects/date-formats'

  export default {
    name: 'pages',

    components: {
      LoadingIndicator,
      AccessDenied,
    },

    data () {
      return {
        pages: [],
        loading: true,
        tableLoading: false,
        accessPermitted: true,
        paginatorPage: 1,
        paginatorPerPage: 8,
        tableFilterTitle: '',
        tableFilterSlug: '',
        totalItems: 0,
        sortField: 'title',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
      }
    },

    computed: {
      userCanSeeList () {
        return this.accessPermitted && this.$store.getters['user/canView']
      },

      userCanEditPage () {
        return this.$store.getters['user/canContribute']
      },

      userCanViewPage () {
        return this.$store.getters['user/canView'] // published pages can be viewed by all, but non-Viewers can't see the list…
      },

      userCanDeletePage () {
        return this.$store.getters['user/isAdmin'] // lower levels can unpublish it
      },

      userCanCreatePage () {
        return this.$store.getters['user/canEdit']
      },

      filtersApplied () {
        return this.tableFilterTitle !== '' || this.tableFilterSlug !== ''
      },

      lastPage () {
        return Math.ceil((this.totalItems) / this.paginatorPerPage)
      },
    },

    async mounted () {
      await this.loadPages()
      this.loading = false

      EventBus.$on('userDidChange', () => {
        this.loading = true
        ;(async () => {
          await this.loadPages()
        })()
        this.loading = false
      })
    },

    methods: {
      async loadPages () {
        let returnedPages, itemCount

        if (this.$route.query?.page === undefined) {
          this.paginatorPage = this.$store.getters['pagination/pagesPage'] || 1
        } else {
          let parsedPage = Number.parseInt(this.$route.query.page)

          if (parsedPage < 1 || Number.isNaN(parsedPage)) {
            parsedPage = 1
            await this.$router.replace({ name: 'pages', params: this.$route.params, query: { ...this.$route.query, page: parsedPage } })
          }

          this.paginatorPage = parsedPage
        }
        await this.$store.dispatch('pagination/clearPagesPage')

        this.tableLoading = true
        this.accessPermitted = true

        try {
          for (;;) {
            const filters = new URLSearchParams({
              p: this.paginatorPage,
              s: this.sortField,
              o: this.sortOrder,
              n: this.paginatorPerPage,
              ft: this.tableFilterTitle,
              fs: this.tableFilterSlug,
            }).toString()

            ;({ pages: returnedPages, itemCount } = await ApiService.get(`pages?${ filters }`))

            this.totalItems = itemCount
            if (this.totalItems === 0 || this.paginatorPage === 1 || this.paginatorPage <= this.lastPage) break

            // page is past the end of the dataset
            this.paginatorPage = this.lastPage
            await this.$router.replace({ name: 'pages', params: this.$route.params, query: { ...this.$route.query, page: this.paginatorPage } })
          }
        } catch (error) {
          returnedPages = []
          this.totalItems = 0

          if (error?.response?.status === 401) {
            this.accessPermitted = false
            failureToast('You do not have permission to do this')
          }
        }

        this.pages = returnedPages
          .map((row) => {
            const createdAt = row.createdAt ? parseISO(row.createdAt) : null
            const updatedAt = row.updatedAt ? parseISO(row.updatedAt) : null

            return {
              ...row,
              createdAt,
              updatedAt,
              readableUpdatedAt: updatedAt ? formatDate(updatedAt, dateFormats.dateWithTime) : 'never',
            }
          })
        this.tableLoading = false
      },

      paginatorSortDidChange (field, order) {
        this.sortField = field
        this.sortOrder = order
        this.loadPages()
      },

      paginatorPageDidChange (page) {
        this.paginatorPage = page
        this.$router.replace({ name: 'pages', params: this.$route.params, query: { ...this.$route.query, page } })
        this.loadPages()
      },

      tableFilterDidChange (filter) {
        this.tableFilterTitle = filter.title ?? ''
        this.tableFilterSlug = filter.slug ?? ''
        this.loadPages()
      },

      destroy (page) {
        this.$buefy.dialog.confirm({
          title: 'Delete Page',
          message: `Are you sure you want to delete the page “${ page.title }”? This cannot be undone.`,
          cancelText: 'Keep',
          confirmText: 'Delete',
          type: 'is-danger',
          hasIcon: true,
          icon: 'alert',
          onConfirm: async () => {
            try {
              await ApiService.delete('pages', page.id)
              successToast('Page deleted')
              this.totalItems -= 1
              if (this.paginatorPage > 1 && this.paginatorPage > this.lastPage) {
                await this.paginatorPageDidChange(this.lastPage)
              } else {
                await this.loadPages()
              }
            } catch (error) {
              const message = error?.response?.status === 401
                ? 'You do not have permission to delete this page'
                : 'Could not delete page'

              failureToast(message)
            }
          },
        })
      },

      view (page) {
        this.$store.dispatch('pagination/setPagesPage', { page: this.paginatorPage })
        this.$router.push(`/pages/${ page.slug }`)
      },

      edit (page) {
        this.$store.dispatch('pagination/setPagesPage', { page: this.paginatorPage })
        this.$router.push(`/pages/${ page.id }/edit`)
      },

      create () {
        this.$store.dispatch('pagination/setPagesPage', { page: this.paginatorPage })
        this.$router.push('/pages/new')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .pages {
    margin-bottom: 2rem;

    @include on-phone {
      margin-top: 0.75rem;
    }
  }

  ::v-deep {
    table {
      tr {
        th,
        td {
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  .buttons {
    justify-content: flex-end;

    .button {
      @include not-on-phone {
        margin-top: -2px;
        margin-bottom: 0;
      }
    }
  }
</style>
