import EventBus from '@/services/event-bus-service'

const userLevels = {
  'anonymous': 0,
  'viewer': 1,
  'contributor': 2,
  'editor': 3,
  'admin': 4,
}

const requiredUserLevels = {
  'beerFestivals': {
    'list': userLevels.anonymous,
    'view': userLevels.anonymous,
    'edit': userLevels.contributor,
    'create': userLevels.editor,
    'delete': userLevels.admin,
  },
  'calendarFeeds': {
    'list': userLevels.contributor,
    'view': userLevels.editor,
    'edit': userLevels.admin,
    'create': userLevels.admin,
    'delete': userLevels.admin,
    'sync': userLevels.contributor,
  },
  'issues': {
    'list': userLevels.anonymous,
    'view': userLevels.anonymous,
    'edit': userLevels.contributor,
    'create': userLevels.contributor,
    'delete': userLevels.editor,
  },
  'pages': {
    'list': userLevels.viewer,
    'view': userLevels.viewer, // other users can still view published pages, but caller must check this
    'edit': userLevels.contributor,
    'create': userLevels.editor,
    'delete': userLevels.admin, // contributor and above can simply unpublish
  },
  'publications': {
    'list': userLevels.viewer,
    'view': userLevels.anonymous,
    'edit': userLevels.admin,
    'create': userLevels.admin,
    'delete': userLevels.admin,
  },
  'settings': {
    'list': userLevels.editor,
    'edit': userLevels.editor,
  },
}

import { nullUser } from '@/objects/null-user'

const userLevel = (name) => userLevels[name] || userLevels['anonymous']

const state = {
  ...nullUser,
}

const getters = {
  isAnonymous: (state) => userLevel(state.userLevel) === userLevels.anonymous,
  isViewer: (state) => userLevel(state.userLevel) === userLevels.viewer,
  isContributor: (state) => userLevel(state.userLevel) === userLevels.contributor,
  isEditor: (state) => userLevel(state.userLevel) === userLevels.editor,
  isAdmin: (state) => userLevel(state.userLevel) === userLevels.admin,
  // no check for anonymous or better as you can just not check the role
  canView: (state) => userLevel(state.userLevel) >= userLevels.viewer,
  canContribute: (state) => userLevel(state.userLevel) >= userLevels.contributor,
  canEdit: (state) => userLevel(state.userLevel) >= userLevels.editor,
  // no check for admin or better as this is the same as isAdmin()
  authorizationHeader: (state) => (state.jwtToken ? { Authorization: `Bearer ${ state.jwtToken }` } : {}),
  can: (state) => (action, target) => {
    const requiredUserLevel = requiredUserLevels[target][action]

    if (requiredUserLevel === undefined) {
      console.error(`Invalid permissions check for ${ target }/${ action }`)

      return false
    }

    return (userLevel(state.userLevel) >= requiredUserLevel)
  },
}

const actions = {
  logIn ({ commit }, payload) {
    commit('LOG_IN', payload)
    EventBus.$emit('userDidChange')
  },

  logOut ({ commit }) {
    commit('LOG_OUT')
    EventBus.$emit('userDidChange')
  },

  updateToken ({ commit }, payload) {
    commit('UPDATE_TOKEN', payload)
  },
}

const mutations = {
  LOG_IN (state, payload) {
    state.id = payload.user.id
    state.username = payload.user.username
    state.email = payload.user.email
    state.userLevel = payload.user.userLevel
    state.jwtToken = payload.token
  },

  UPDATE_TOKEN (state, payload) {
    state.jwtToken = payload.token
  },

  LOG_OUT (state) {
    state.id = nullUser.id
    state.username = nullUser.username
    state.email = nullUser.email
    state.userLevel = nullUser.userLevel
    state.jwtToken = nullUser.jwtToken
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
