<template>
  <div v-if="loading" class="loading-indicator-container">
    <div class="loading-indicator" role="status" aria-label="Loading">
      <div />
      <div />
    </div>
    <div class="no-animation" role="status">
      loading…
    </div>
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'loading-indicator',

    props: {
      loading: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .loading-indicator-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
  }

  .loading-indicator {
    display: inline-block;
    position: relative;
    width: 6rem;
    height: 6rem;

    @media only screen and (prefers-reduced-motion: reduce) {
      display: none;
    }

    div {
      border: 4px solid $loading-indicator-color;
      position: absolute;
      border-radius: 50%;
      animation: loading-indicator-ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;

      &:nth-of-type(2) {
        opacity: 0.5;
        animation-delay: -800ms;
      }
    }
  }

  .no-animation {
    display: none;
    color: $grey;
    font-size: 0.8rem;

    @media only screen and (prefers-reduced-motion: reduce) {
      display: block;
    }
  }

  @keyframes loading-indicator-ripple {
    0% {
      top: 42%;
      left: 42%;
      opacity: 1;
      width: 6%;
      height: 6%;
    }

    100% {
      top: 0;
      left: 0;
      opacity: 0;
      width: 90%;
      height: 90%;
    }
  }
</style>
