<template>
  <header>
    <router-link to="/">
      <img
        id="logo"
        src="@/assets/img/camra-logo.svg"
        alt="Campaign for Real Ale"
        aria-label="The Campaign for Real Ale"
      >
    </router-link>
    <h1>
      <router-link to="/">
        Western Sussex CAMRA
      </router-link>
    </h1>
    <p class="subhead has-text-centered is-size-6">
      Supporting real ale in Western Sussex. Mostly by drinking it.
    </p>
    <p v-if="environment === 'staging'" class="staging-warning">
      ⚠️&nbsp;<strong>Staging site —&nbsp;not for public use</strong>&nbsp;⚠️
    </p>
    <p class="social-links text-right is-hidden-mobile">
      <safe-link
        to="https://www.facebook.com/westernsussexcamra/"
        title="Facebook"
        target="_blank"
      >
        <b-icon icon="facebook" size="is-large" />
      </safe-link>
      <safe-link
        to="https://twitter.com/WSussexCAMRA"
        title="Twitter"
        target="_blank"
      >
        <b-icon icon="twitter" size="is-large" />
      </safe-link>
    </p>
    <p class="social-links text-right is-hidden-normally is-block-mobile">
      <safe-link
        to="https://www.facebook.com/westernsussexcamra/"
        title="Facebook"
        target="_blank"
      >
        <b-icon icon="facebook" size="is-medium" />
      </safe-link>
      <safe-link
        to="https://twitter.com/WSussexCAMRA"
        title="Twitter"
        target="_blank"
      >
        <b-icon icon="twitter" size="is-medium" />
      </safe-link>
    </p>
  </header>
</template>

<script>
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'site-header',

    components: { SafeLink },

    computed: {
      environment () {
        return process.env.VUE_APP_SITE_ENV
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  header {
    position: relative;
    margin-bottom: 0;
    background: $primary;
    padding: 20px;
    height: 160px;

    @include on-phone {
      padding: 10px;
      height: 92px;
    }

    @include on-print {
      border-bottom: 0.5pt solid $grey-darker;
      background-color: #fff;
    }

    a {
      text-decoration: none;
      color: $body-color;

      &:hover {
        text-decoration: none;
        color: $hover-color;
      }
    }

    #logo {
      position: absolute;
      width: 75px;
      height: 120px;

      &.logo-anniversary {
        width: 120px;
      }

      @include on-tablet-or-phone {
        width: auto;
        height: 72px;

        &.logo-anniversary {
          width: 72px;
        }
      }
    }

    h1 {
      margin-top: 0.75rem;
      text-align: center;
      font-weight: $weight-normal;

      a { // stylelint-disable-line no-descending-specificity -- would need to move a:hover to the end while keeping a at the start; that's MORE confusing
        color: $grey-lightest;
      }

      @include on-phone {
        margin-top: 0;
        text-align: right;
        font-size: $size-1 * 0.555;
      }

      @include on-tablet {
        margin-top: 0.75rem;
        font-size: $size-1 * 0.666;
      }
    }

    .subhead {
      margin-bottom: 0;
      padding-left: 0.1rem; // Optical centring; the full stop makes it appear too far to the left.
      text-align: center;
      color: $grey-lightest;
      font-weight: $weight-bold;

      @include on-phone {
        display: none;
      }

      @include on-tablet {
        font-size: $size-6 * 0.8;
      }
    }

    .staging-warning {
      text-align: center;

      @include on-phone {
        font-size: 0.65rem;
      }
    }

    > div {
      display: inline-block;
    }

    .social-links {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      margin: 0;

      a {
        opacity: 0.8;
        margin-left: 0.25rem;
        color: $grey-lightest;
      }

      @include on-tablet-or-phone {
        right: 0.5rem;
        bottom: 0.5rem;

        a {
          margin-left: 0.5rem;
        }
      }

      @include on-print {
        display: none;
      }
    }
  }
</style>
