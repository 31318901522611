<template>
  <front-page-card title="About Us / Contact Us"
                   :more-link="page"
                   more-text-screenreader="More information about Western Sussex CAMRA…"
                   card="about-us"
                   :larger="larger"
  >
    <p>Our coverage area, contact details, and how you can get involved.</p>
  </front-page-card>
</template>

<script>
  import FrontPageCard from '@/components/front-page/front-page-card'

  export default {
    name: 'about-us-card',

    components: {
      FrontPageCard,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      page: {
        type: String,
        default: undefined,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
