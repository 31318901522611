<template>
  <loading-indicator :loading="loading">
    <section id="page-title">
      <h2>Confirm Email Address</h2>
    </section>
    <template v-if="status === 'confirming'">
      <p>Please wait…</p>
    </template>
    <template v-else-if="status === 'successful'">
      <b-message
        type="is-success"
        has-icon
        icon="account-check"
      >
        Your email address has been successfully confirmed. You can now log in using the “Admin Login” button below.
      </b-message>
    </template>
    <template v-else-if="status === 'tokenInvalid'">
      <b-message
        type="is-danger"
        has-icon
        icon="alert-octagon"
      >
        The link you have used is invalid or has expired. Please contact an administrator.
      </b-message>
    </template>
    <template v-else>
      <b-message
        type="is-danger"
        has-icon
        icon="alert-octagon"
      >
        <template v-if="serverErrors.length">
          <p>
            Your email address could not be confirmed due to the following errors:
          </p>
          <ul>
            <li
              v-for="(error, index) in serverErrors"
              :key="index"
            >
              {{ error }}
            </li>
          </ul>
        </template>
        <p v-else>
          Your email address could not be confirmed due to an unknown server error. Please try again in a few minutes.
        </p>
      </b-message>
    </template>
  </loading-indicator>
</template>

<script>
  import { forIn } from 'lodash'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'

  import LoadingIndicator from '@/components/helpers/loading-indicator'

  export default {
    name: 'confirm-email',

    components: {
      LoadingIndicator,
    },

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    data () {
      return {
        loading: true,
        // status: confirming -> (successful | tokenInvalid | serverErrors)
        status: 'confirming',
        serverErrors: [],
      }
    },

    async mounted () {
      await this.confirmEmail()
      this.loading = false

      EventBus.$on('userDidChange', () => {
        this.$router.push('/')
      })
    },

    methods: {
      async confirmEmail () {
        try {
          await ApiService.get(`users/confirmation?confirmation_token=${ this.token }`)

          this.status = 'successful'
        } catch (error) {
          if (error?.response?.status === 422) {
            const errors = error?.response?.data?.errors || {}
            const serverErrors = []

            this.status = 'serverErrors'
            forIn(errors, (messages, field) => {
              messages.forEach((message) => {
                const error = `${ field.split('_').join(' ') } ${ message }`

                if (error === 'confirmation token is invalid') {
                  this.status = 'tokenInvalid'
                }
                serverErrors.push(error)
              })
            })
            this.serverErrors = serverErrors.map((s) => `${ s.substr(0,1).toUpperCase() }${ s.slice(1) }`)
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';
</style>
