<template>
  <b-message
    type="is-danger"
    has-icon
    icon="alert-octagon"
  >
    {{ noAccessMessage }}
  </b-message>
</template>

<script>
  export default {
    name: 'access-denied',

    computed: {
      noAccessMessage () {
        return this.$store.getters['user/isAnonymous']
          ? 'You need to log in to see this content.'
          : 'This is not available at your user level. Please contact an admin if you think you should have access.'
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  * {
    font-weight: $weight-bold;
  }
</style>
