<template>
  <loading-indicator :loading="loading">
    <section id="page-title">
      <h2>{{ pageHeading }}</h2>
      <div
        v-if="readOnly"
        class="title-right"
      >
        <b-button
          size="is-small"
          outlined
          data-testid="page-title-all-pages-button"
          @click="allFestivals"
        >
          All Festivals
        </b-button>
        <b-button
          v-if="userCanEditFestival"
          type="is-primary"
          size="is-small"
          outlined
          data-testid="page-title-edit-button"
          @click="edit(beerFestival.id)"
        >
          Edit
        </b-button>
      </div>
    </section>

    <section class="content-body">
      <access-denied v-if="!accessPermitted" />

      <template v-else-if="beerFestivalLoaded && readOnly">
        <div class="columns">
          <dl class="column festival-details">
            <dt>When</dt>
            <dd data-testid="beer-festival-when">
              <date-time-range
                :starts-at="beerFestival.startsAt"
                :ends-at="beerFestival.endsAt"
              />
            </dd>

            <template v-if="beerFestival.address">
              <dt>Where</dt>
              <dd data-testid="beer-festival-where">
                {{ beerFestival.address }}
              </dd>
            </template>

            <template v-if="beerFestival.branch">
              <dt>Who</dt>
              <dd data-testid="beer-festival-who">
                {{ beerFestival.branch }}
              </dd>
            </template>

            <template v-if="beerFestival.url">
              <dt>Website</dt>
              <dd data-testid="beer-festival-url">
                <safe-link :to="beerFestival.url">
                  {{ beerFestival.url }}
                </safe-link>
              </dd>
            </template>

            <template v-if="ticketSalesLive">
              <dt />
              <dd class="mt-4">
                <buy-tickets-button
                  :url="beerFestival.ticketSalesUrl"
                  data-testid="buy-tickets"
                />
              </dd>
            </template>
          </dl>

          <div class="column is-two-fifths festival-map">
            <draggable-map
              :latitude="beerFestival.latitude"
              :longitude="beerFestival.longitude"
              :address="beerFestival.location"
            />
          </div>
        </div>
      </template>

      <template v-else-if="beerFestivalLoaded && !readOnly">
        <form
          accept-charset="UTF-8"
          data-testid="beer-festival-form"
          @keydown.prevent.enter=""
        >
          <input name="utf8" type="hidden" value="✓">

          <b-field
            label="Name"
            :type="fieldNameType"
            :message="fieldNameMessages"
          >
            <b-input
              v-model.trim="$v.beerFestival.name.$model"
              data-testid="beer-festival-form-name"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field label="CAMRA Branch">
            <b-input
              v-model.trim="beerFestival.branch"
              data-testid="beer-festival-form-branch"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field label="Dates">
            <v-date-picker
              v-model="beerFestival.dates"
              is-range
              :popover="{ placement: 'bottom', visibility: 'focus' }"
              weeks-transition="fade"
              :masks="dateFormats"
              :model-config="{ timeAdjust: '12:00:00' }"
              @keyup.native.enter.once="saveForm"
            >
              <template #default="{ inputValue, inputEvents }">
                <div class="columns">
                  <div class="column is-half">
                    <input
                      type="text"
                      class="input"
                      :value="inputValue.start"
                      inputmode="numeric"
                      data-testid="beer-festival-form-start-date"
                      v-on="inputEvents.start"
                    >
                  </div>
                  <div class="column is-half">
                    <input
                      type="text"
                      class="input"
                      :value="inputValue.end"
                      inputmode="numeric"
                      data-testid="beer-festival-form-end-date"
                      v-on="inputEvents.end"
                    >
                  </div>
                </div>
              </template>
            </v-date-picker>
          </b-field>

          <b-field label="Address">
            <b-input
              v-model.trim="beerFestival.address"
              data-testid="beer-festival-form-address"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field
            label="Website"
            :type="fieldUrlType"
            :message="fieldUrlMessages"
          >
            <b-input
              v-model.trim="$v.beerFestival.url.$model"
              data-testid="beer-festival-form-url"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field
            label="Ticket Sales Website"
            :type="fieldTicketSalesUrlType"
            :message="fieldTicketSalesUrlMessages"
          >
            <b-input
              v-model.trim="$v.beerFestival.ticketSalesUrl.$model"
              data-testid="beer-festival-form-ticket-sales-url"
              @keyup.native.enter.once="saveForm"
            />
          </b-field>

          <b-field label="Ticket Sales Dates">
            <v-date-picker
              v-model="beerFestival.ticketSalesDates"
              mode="dateTime"
              is24hr
              is-range
              :popover="{ placement: 'bottom', visibility: 'focus' }"
              weeks-transition="fade"
              :masks="dateTimeFormats"
              @keyup.native.enter.once="saveForm"
            >
              <template #default="{ inputValue, inputEvents }">
                <div class="columns">
                  <div class="column is-half">
                    <input
                      type="text"
                      class="input"
                      :value="inputValue.start"
                      inputmode="numeric"
                      data-testid="beer-festival-form-ticket-sales-starts-at"
                      v-on="inputEvents.start"
                    >
                  </div>
                  <div class="column is-half">
                    <input
                      type="text"
                      class="input"
                      :value="inputValue.end"
                      inputmode="numeric"
                      data-testid="beer-festival-form-ticket-sales-ends-at"
                      v-on="inputEvents.end"
                    >
                  </div>
                </div>
              </template>
            </v-date-picker>
          </b-field>
          <div class="actions columns">
            <div class="action-buttons column">
              <b-button
                outlined
                @click="confirmDataLoss"
              >
                Cancel
              </b-button>
              <b-button
                type="is-primary"
                :disabled="saving || $v.$invalid"
                :loading="saving"
                @click="saveForm"
              >
                {{ saveButtonText }}
              </b-button>
            </div>
          </div>
        </form>
      </template>

      <template v-else>
        <section class="section no-beer-festival">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>This beer festival could not be loaded.</p>
          </div>
        </section>
      </template>
    </section>
  </loading-indicator>
</template>

<script>
  import { isEqualWith } from 'lodash'
  import { required } from 'vuelidate/lib/validators'

  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'
  import { setTitle } from '@/router'
  import { parseDateTimes, parseTicketSalesDateTimes } from '@/helpers/dates-times-helper'
  import { failureToast, successToast } from '@/helpers/notification-helper'
  import { urlHttpOrHttps, urlHttpsOnly, urlWithoutUsername } from '@/validators/url'

  import LoadingIndicator from '@/components/helpers/loading-indicator'
  import AccessDenied from '@/components/helpers/access-denied'
  import DraggableMap from '@/components/helpers/draggable-map'
  import DateTimeRange from '@/components/helpers/date-time-range'
  import SafeLink from '@/components/helpers/safe-link'
  import BuyTicketsButton from '@/components/helpers/buy-tickets-button.vue'

  export default {
    name: 'beer-festival',

    components: {
      SafeLink,
      LoadingIndicator,
      AccessDenied,
      DraggableMap,
      DateTimeRange,
      BuyTicketsButton,
    },

    async beforeRouteUpdate (_to, _from, next) {
      this.loading = true
      this.beerFestival = {}

      await this.loadBeerFestival(this.id)
      next()
    },

    props: {
      id: {
        type: String,
        default: undefined,
      },

      mode: {
        type: String,
        default: 'show',
        validator (value) {
          return ['create', 'edit', 'show'].indexOf(value) !== -1
        },
      },
    },

    validations: {
      beerFestival: {
        name: {
          required,
        },

        url: {
          urlHttpOrHttps,
          urlWithoutUsername,
        },

        ticketSalesUrl: {
          urlHttpsOnly,
          urlWithoutUsername,
        },
      },
    },

    data () {
      return {
        loading: true,
        saving: false,
        accessPermitted: true,
        beerFestival: {},
        originalBeerFestival: {},
        pageHeading: '',
        dateFormats: {
          input: [
            'YYYY-MM-DD',
            'YYYY/MM/DD',
            'DD/MM/YYYY',
          ],

          data: ['YYYY-MM-DD'],
        },

        dateTimeFormats: {
          input: [
            'YYYY-MM-DD HH:mm',
            'YYYY/MM/DD HH:mm',
            'DD/MM/YYYY HH:mm',
          ],

          // There is nothing in the v-datepicker documentation to explain why you need to use `L` rather than `data` when formatting datetimes.
          L: ['YYYY-MM-DD'],
        },
      }
    },

    computed: {
      beerFestivalLoaded () {
        return this.beerFestival && (this.mode === 'create' || this.beerFestival.id)
      },

      userCanEditFestival () {
        return this.$store.getters['user/can']('edit', 'beerFestivals')
      },

      readOnly () {
        return this.mode === 'show'
      },

      saveButtonText () {
        return this.mode === 'create' ? 'Create' : 'Save'
      },

      ticketSalesLive () {
        const now = new Date()

        return this.beerFestival.ticketSalesUrl && this.beerFestival.ticketSalesStartsAt <= now && this.beerFestival.ticketSalesEndsAt >= now
      },

      festivalChanged () {
        // Vue (or possibly v-calendar) seems to be making some objects into observers, and is doing so after this.originalBeerFestival is saved; this causes
        // the Cancel button to show a prompt even when the form is unchanged. As beerFestival.dates and beerFestival.ticketSalesDates are convenience objects
        // that are put there for v-calendar, we can ignore them when checking equality, as startsAt/endsAt/ticketSalesStartsAt/ticketSalesEndsAt are the
        // objects that get persisted.
        const ignoreDateObjects = (a, _b) => {
          if (a instanceof Object &&
            Object.prototype.hasOwnProperty.call(a, 'start') &&
            Object.prototype.hasOwnProperty.call(a, 'end')) return true

          return undefined
        }

        return !isEqualWith(this.beerFestival, this.originalBeerFestival, ignoreDateObjects)
      },

      fieldNameType () {
        if (this.readOnly) return ''

        return this.$v.beerFestival.name.$error ? 'is-danger' : ''
      },

      fieldNameMessages () {
        if (this.readOnly || !this.$v.beerFestival.name.$error) return ''

        return this.$v.beerFestival.name.required ? '' : 'The name is required.'
      },

      fieldUrlType () {
        if (this.readOnly) return ''

        return this.$v.beerFestival.url.$error ? 'is-danger' : ''
      },

      fieldUrlMessages () {
        if (this.readOnly || !this.$v.beerFestival.url.$error) return ''

        const messages = []

        if (!this.$v.beerFestival.url.urlHttpOrHttps) {
          messages.push('The URL must be http or https, and not for a local server.')
        }
        if (!this.$v.beerFestival.url.urlWithoutUsername) {
          messages.push('The URL must not have a username or password in it.')
        }

        return messages
      },

      fieldTicketSalesUrlType () {
        if (this.readOnly) return ''

        return this.$v.beerFestival.ticketSalesUrl.$error ? 'is-danger' : ''
      },

      fieldTicketSalesUrlMessages () {
        if (this.readOnly || !this.$v.beerFestival.ticketSalesUrl.$error) return ''

        const messages = []

        if (!this.$v.beerFestival.ticketSalesUrl.urlHttpsOnly) {
          messages.push('The ticket sales URL must be https, and not for a local server.')
        }
        if (!this.$v.beerFestival.ticketSalesUrl.urlWithoutUsername) {
          messages.push('The ticket sales URL must not have a username or password in it.')
        }

        return messages
      },
    },

    async mounted () {
      await this.loadBeerFestival(this.id)

      EventBus.$on('userDidChange', () => {
        (async () => { await this.loadBeerFestival(this.id) })()
      })
    },

    methods: {
      async loadBeerFestival (id) {
        this.loading = true
        this.beerFestival = {}
        this.accessPermitted = true

        if (this.mode === 'create') {
          this.beerFestival = await this.loadBlankBeerFestival()
          this.pageHeading = 'New Beer Festival'
        } else {
          if (this.mode === 'edit' && !this.$store.getters['user/can']('edit', 'beerFestivals')) {
            this.accessPermitted = false
          } else {
            const prefix = this.mode === 'edit' ? 'Edit ' : ''

            this.beerFestival = await this.loadExistingBeerFestival(id)
            this.pageHeading = this.beerFestivalLoaded ? `${ prefix }Beer Festival: ${ this.beerFestival.name }` : `${ prefix }Beer Festival`
          }
        }

        this.beerFestival.dates = {
          start: this.beerFestival?.startsAt,
          end: this.beerFestival?.endsAt,
        }

        this.beerFestival.ticketSalesDates = {
          start: this.beerFestival?.ticketSalesStartsAt,
          end: this.beerFestival?.ticketSalesEndsAt,
        }

        if (!this.accessPermitted) {
          this.pageHeading = ''
        }
        this.originalBeerFestival = { ...this.beerFestival }
        setTitle(this.pageHeading)

        this.loading = false
      },

      async loadBlankBeerFestival () {
        try {
          const { beerFestival } = await ApiService.get('beer_festivals/new')

          return beerFestival
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      async loadExistingBeerFestival (id) {
        try {
          const { beerFestival } = await ApiService.get(`beer_festivals/${ id }`)

          return parseTicketSalesDateTimes(parseDateTimes(beerFestival))
        } catch (error) {
          if (error?.response?.status === 401) {
            this.accessPermitted = false
          } else if (error?.response?.status === 422) {
            this.beerFestival = {}
          } else if (error?.response?.status !== 404) {
            failureToast('There was a problem getting data from the server. Please try later.')
          }

          return {}
        }
      },

      confirmDataLoss () {
        if (this.festivalChanged) {
          this.$buefy.dialog.confirm({
            title: 'Cancel Editing',
            message: 'Are you sure you want to cancel? Your changes will be lost.',
            cancelText: 'No',
            confirmText: 'Yes',
            type: 'is-danger',
            hasIcon: true,
            icon: 'alert',
            onConfirm: () => this.allFestivals(),
          })
        } else {
          this.allFestivals()
        }
      },

      edit (id) {
        this.$router.push(`/beer-festivals/${ id }/edit`)
      },

      allFestivals () {
        this.$router.push('/beer-festivals')
      },

      async saveForm () {
        // Fix datepicker focus bugs
        const activeElement = document.activeElement

        activeElement?.blur()

        this.$v.$touch()
        if (this.mode === 'show' || this.saving || this.$v.$invalid) return

        try {
          const beerFestival = this.beerFestival

          // Set up dates from date picker
          beerFestival.startsAt = beerFestival?.dates?.start
          beerFestival.endsAt = beerFestival?.dates?.end
          beerFestival.ticketSalesStartsAt = beerFestival?.ticketSalesDates?.start
          beerFestival.ticketSalesEndsAt = beerFestival?.ticketSalesDates?.end

          this.saving = true
          switch (this.mode) {
            case 'create':
              await ApiService.post('beer_festivals', { beerFestival })
              break
            case 'edit':
              await ApiService.put(`beer_festivals/${ this.id }`, { beerFestival })
              break
            default:
              console.error(`saveForm called with unsupported mode ${ this.mode }`)

              return
          }

          successToast('Beer festival saved')

          this.allFestivals()
        } catch (error) {
          let formattedErrors

          activeElement.focus()

          switch (error?.response?.status) {
            case 422:
              formattedErrors = error?.response?.data?.errors?.join('</li><li>')

              this.$buefy.dialog.alert({
                title: 'Could not save beer festival',
                message: `The server reported the following errors: <ul class="rails-errors"><li>${ formattedErrors }</li></ul>`,
                type: 'is-danger',
                hasIcon: true,
                icon: 'emoticon-sad',
              })
              break
            case 401:
              failureToast('You do not have permission to do this')
              break
            default:
              console.error(error)
              failureToast('There was a problem communicating with the server; please try later')
              break
          }
        } finally {
          this.saving = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  .festival-map {
    @include not-on-phone {
      order: -1;
    }
  }

  .festival-details {
    dt {
      margin-top: 0.5rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  form {
    margin: 0 auto;
    max-width: $form-width;
  }

  .html-editor {
    label {
      margin-bottom: 0.5rem;
    }

    .menubar {
      line-height: 2;

      .spacer {
        display: inline-block;
        width: 0.5rem;
      }

      button {
        margin-right: 0.1rem;
        border: 1px solid $grey;
        border-radius: $radius;

        &.is-active {
          background-color: $grey-light;
        }
      }
    }

    ::v-deep {
      .ProseMirror {
        border: 1px solid $light;
        border-radius: $radius;
        box-shadow: inset 0 0.0625rem 0.125rem rgba(10, 10, 10, 0.05);
        background-color: #fff;
        padding: 0.5rem 0.75rem;
        color: $dark;

        &:focus {
          outline: none;
          border-color: $primary;
          box-shadow: 0 0 0 0.125rem change-color($primary, $alpha: 0.25);
        }

        &[contenteditable='false'] {
          border-color: #f5f5f5;
          box-shadow: none;
          background-color: #f5f5f5;
          cursor: not-allowed;
          color: #7a7a7a;
        }

        ul {
          list-style-type: circle;
        }
      }
    }

    &.is-danger {
      ::v-deep {
        .ProseMirror {
          border-color: $danger;
        }
      }
    }
  }

  #page-title {
    .title-left,
    .title-right {
      @include not-on-phone {
        position: absolute;
        top: 0.5rem;
      }
    }

    .title-left {
      left: 0;
    }

    .title-right {
      right: 0;

      button {
        margin-left: 0.5rem;
        height: 1.8rem;

        @include on-print {
          display: none;
        }
      }
    }
  }

  .help {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .actions {
    align-items: center;

    .action-buttons {
      text-align: right;

      .button {
        @include not-on-phone {
          &:not(:first-child) {
            margin-left: 0.75rem;
          }
        }

        @include on-phone {
          display: flex;
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>

<style lang="scss">
  // Buefy dialogs can't be styled from the calling component, as they are created on the <body>, so this rule must be global
  // Can be replaced by a ::v-global block after moving to Vue 3
  .dialog {
    ul {
      &.rails-errors {
        list-style-type: circle;

        li {
          padding-left: 0;
        }
      }
    }
  }
</style>
