import axios from 'axios'
import axiosRetry from 'axios-retry'

import store from '@/store'

const apiUrlBase = process.env.VUE_APP_API_URL

class ApiService {
  _standardHeaders () {
    const token = localStorage.getItem('token')
    const baseHeaders = {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    }

    return token
      ? {
        ...baseHeaders,
        'Authorization': `Bearer ${ token }`,
      }
      : baseHeaders
  }

  async _axios ({ method, url, headers, data, retries }) {
    axiosRetry(axios, {
      retries: retries || 0,
      retryDelay: axiosRetry.exponentialDelay,
    })

    headers = {
      ...headers,
      ...this._standardHeaders(),
    }

    const response = await axios({
      method,
      url,
      headers,
      data,
    })

    this._updateToken(response.headers?.authorization)

    return response
  }

  _updateToken (authorizationHeader) {
    if (authorizationHeader) {
      const token = authorizationHeader.replace(/^Bearer\s+/i, '')

      localStorage.setItem('token', token)
      store.dispatch('user/updateToken', token)
    }
  }

  async get (endpoint, headers) {
    const response = await this._axios({
      method: 'get',
      url: `${ apiUrlBase }/${ endpoint }`,
      retries: 5,
      headers,
    })

    return response?.data || []
  }

  async post (endpoint, data, headers) {
    const response = await this._axios({
      method: 'post',
      url: `${ apiUrlBase }/${ endpoint }`,
      retries: 1,
      headers,
      data,
    })

    return response?.data || []
  }

  async put (endpoint, data, headers) {
    const response = await this._axios({
      method: 'put',
      url: `${ apiUrlBase }/${ endpoint }`,
      retries: 1,
      headers,
      data,
    })

    return response?.data || []
  }

  async delete (endpoint, id, headers) {
    const response = await this._axios({
      method: 'delete',
      url: `${ apiUrlBase }/${ endpoint }/${ id }`,
      retries: 3,
      headers,
    })

    return response?.data || []
  }

  url (endpoint) {
    return `${ apiUrlBase }/${ endpoint.replace(/^\//, '') }`
  }
}

export default new ApiService()
