<template>
  <front-page-card
    title="Sussex Drinker"
    :more-link="publicationPath"
    more-text="More issues…"
    more-text-screenreader="More Sussex Drinker issues…"
    card="sussex-drinker"
    :larger="larger"
  >
    <p
      v-if="loading"
    >
      <b-skeleton width="90px" height="130px" />
    </p>
    <template v-else-if="imgSrc && pdfUrl">
      <p data-testid="sussex-drinker-info">
        <safe-link :to="pdfUrl" allow-referrer>
          <figure class="image is-drinker-thumbnail">
            <img
              :src="imgSrc"
              :srcset="imgSrcset"
              :alt="imgAlt"
            >
          </figure>
        </safe-link>
      </p>
      <p class="download-hint">
        tap to read online
      </p>
    </template>
    <p
      v-else
      data-testid="sussex-drinker-info"
    >
      No issues available.
    </p>
  </front-page-card>
</template>

<script>
  import { compact } from 'lodash'

  import FrontPageCard from '@/components/front-page/front-page-card'
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'sussex-drinker-card',

    components: {
      FrontPageCard,
      SafeLink,
    },

    props: {
      larger: {
        type: Boolean,
        default: false,
      },

      issueNumber: {
        type: Number,
        default: undefined,
      },

      issueName: {
        type: String,
        default: undefined,
      },

      pdfUrl: {
        type: String,
        default: undefined,
      },

      thumbnails: {
        type: Object,
        default: () => ({}),
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      publicationPath () {
        return this.imgSrc && this.pdfUrl ? '/publications/sussex-drinker' : undefined
      },

      imgSrc () {
        const x1 = this.thumbnails?.small?.x1

        return `${ process.env.VUE_APP_API_URL }/thumbnails/${ x1 ?? 'missing' }`
      },

      imgSrcset () {
        const x2 = this.thumbnails?.small?.x2
        const x3 = this.thumbnails?.small?.x3
        const parts = [
          x2 ? `${ process.env.VUE_APP_API_URL }/thumbnails/${ x2 } 2x` : null,
          x3 ? `${ process.env.VUE_APP_API_URL }/thumbnails/${ x3 } 3x` : null,
        ]

        return compact(parts).join(', ')
      },

      imgAlt () {
        const parts = [
          'Sussex Drinker',
          this.issueNumber ? `Issue ${ this.issueNumber }` : null,
          this.issueName ? this.issueName : null,
        ]

        return compact(parts).join(', ')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  // combinator use is deprecated in Vue 3, but still seems to be required in Vue 2
  ::v-deep {
    @include on-phone {
      .card-centre {
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .is-drinker-thumbnail {
    width: 90px;
    height: 130px;
    max-height: 130px;

    img {
      border: 1px solid $grey;
    }
  }

  .download-hint {
    margin-top: -0.5rem;
    color: $grey-dark;
    font-size: 0.8rem;

    @include not-on-phone {
      display: none;
    }
  }
</style>
