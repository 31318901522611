<template>
  <div v-if="siteNotice.display" class="site-notice" data-testid="site-notice">
    <div class="card">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="card-content"
        v-html="siteNotice.content"
      />
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api-service'
  import EventBus from '@/services/event-bus-service'

  export default {
    name: 'site-notice',

    data () {
      return {
        siteNotice: {
          display: false,
          content: '',
        },
      }
    },

    async mounted () {
      EventBus.$on('siteNoticeDidChange', () => {
        this.loadSiteNotice()
      })
      this.loadSiteNotice()
    },

    methods: {
      async loadSiteNotice () {
        try {
          const { siteNotice } = await ApiService.get('site/notice')

          this.siteNotice = siteNotice
        } catch (error) {
          this.siteNotice.display = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @use 'sass:color';

  @import '@/assets/bulma-variables.scss';

  .site-notice {
    margin-bottom: 1rem;

    @include on-phone {
      margin-top: 1rem;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;
    }
  }

  .card-content {
    background-color: color.scale($primary, $lightness: 95%, $saturation: -10%); // stylelint-disable-line unit-allowed-list -- % is OK for manipulations
    font-size: 1.1rem;
  }
</style>
